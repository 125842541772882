import React from "react";
import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  Typography,
  IconButton,
  Modal,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import vajra from "../images/vajra.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { logOut } from "../redux/data/action";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  background: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "200px",
  textAlign: "center",
}));

const NavbarMain = ({ handleToggle, toggle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();

  const token = localStorage.getItem("userToken");

  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));
    localStorage.clear();
    sessionStorage.clear();
    navigate("/signin");
    localStorage.setItem("logoutEvent", Date.now().toString());
    window.location.reload();
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <AppBar
      position="sticky"
      sx={{
        top: 0,
        zIndex: 999,
        bgcolor: "rgba(27, 37, 53, 1)",
        height: "60px",
      }}
    >
      <Toolbar>
        <Stack
          sx={{
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "row",
            px: {
              xs: "0px",
              sm: "0px",
              md: "70px",
            },
            position: "relative",
            top: "5px",
          }}
        >
          <Link to="https://vajra.software/" style={{ textDecoration: "none" }}>
            <Box
              sx={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
              onClick={() => navigate("/")}
            >
              <Typography
                variant="h6"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={vajra}
                  alt="vajra logo"
                  style={{ maxWidth: "50%", maxHeight: "50%" }}
                />
              </Typography>
            </Box>
          </Link>

          <Box sx={{ flexDirection: "row", display: "flex", gap: "20px" }}>
            <Link
              to="https://vajra.software/"
              style={{
                textDecoration: "none",
                color: "white",
                position: "relative",
                top: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
              >
                <HomeIcon onClick={handleToggle} toggle={toggle} />
                Home
              </Box>
            </Link>

            {token && (
              <Box
                onClick={handleOpenModal}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
              >
                <LogoutIcon />
                Log Out
              </Box>
            )}
          </Box>
        </Stack>
      </Toolbar>

      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <ModalBox sx={{ height: "310px" }}>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}
          >
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
            }}
          >
            <IconButton>
              <LogoutIcon size={60} />
            </IconButton>

            <Typography variant="h6" style={{ fontWeight: 700 }}>
              Log out!
            </Typography>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Are you sure you want to <br /> Log out this Session?
            </Typography>
            <Stack sx={{ width: "90%" }}>
              <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
            </Stack>
            <Button
              size="small"
              variant="contained"
              onClick={handleLogout}
              sx={{
                borderRadius: "35px",
                width: "100px",
                textTransform: "none",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}
            >
              Log Out
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </AppBar>
  );
};

export default NavbarMain;
