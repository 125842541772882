import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EastIcon from "@mui/icons-material/East";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "@mui/material";
import { useRef } from "react";

import {
  IconButton,
  Stack,
  Typography,
  styled,
  Tooltip,
  Checkbox,
  Button,
  Divider,
} from "@mui/material";
import { useState } from "react";
import {
  bulkAssetDelete,
  deleteAsset,
  retrieveAllAssets,
  getNewAssets,
} from "../redux/nbmData/action";
import { useTheme } from "@emotion/react";

import { useEffect } from "react";
import TableSort from "../components/TableSort";
import { Autocomplete, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import BulkDeleteDeleteModal from "../components/BulkDeleteModal";
import BulkUploadAssetPopUp from "./BulkUploadAssetPopUp";
import SortSearchFilter from "../components/SortSearchFilter";

import dummyImage from "../images/download.png";
import vajraImage from "../images/vajraNewLogo.jpg";
import { jsPDF } from "jspdf";
import { setNavigationSource } from "../redux/data/action";
import Calculate from "@mui/icons-material/Calculate";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const AllAsset = () => {
  const [allCounts, setAllCounts] = useState({});
  const dataCount = useSelector((store) => store.data.allAssetsNew);
  const allAssets = useSelector((store) => store.nbmData.allAssets);
  const deletedStatus = useSelector((store) => store.data.bulkAssetDelete);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const deleted = useSelector((store) => store.data.deleteAsset);

  const [filteredData, setFilteredData] = useState([]);

  const [toggle, setToggle] = useState(false);

  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);

  const [filterValue, setFilterValue] = useState(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const assetLogo = useSelector((store) => store?.nbmData?.logoUrl?.logo_url);

  const handleSelectAllClick = () => {
    const allAssetIds = data
      ?.filter((item) => item.check_in_status !== "checked_out") // Exclude checked-out assets
      .map((item) => item.assetid);

    if (selectAll) {
      setSelectedAssetIds([]); // Clear selection
    } else {
      setSelectedAssetIds(allAssetIds); // Select only available assets
    }

    setSelectAll(!selectAll); // Toggle select all state
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedAssetIds, assetId];
    } else {
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
    }

    setSelectedAssetIds(newSelected);
  };

  const handleButtonClick = () => {
    navigate("/depreciation"); // Navigate to Add Asset page
  };

  const handleAddAssetClick = () => {
    if (ids?.subs_plan === "Basic" && data?.length > 50) {
      navigate("/"); // Redirect to homepage
    } else {
      navigate("/add-asset"); // Navigate to Add Asset page
    }
  };

  useEffect(() => {
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  }, [filterValue]);

  const text = "asset";

  const handleOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids,
      userrole: userAccess?.role,
    };

    dispatch(retrieveAllAssets(data, token));
    setToggle(false);
  }, [dispatch, deleted, toggle, deletedStatus]);

  // useEffect(() => {
  //   if (allAssets && allAssets?.length > 0) {
  //     setData(allAssets);
  //   }
  // }, [allAssets, deleted, deletedStatus]);

  const handleBulkDelete = () => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetids: selectedAssetIds,
      branchid: userBranch.id,
      username: ids.username,
      userrole: userAccess?.role,
      depart_ids:userAccess.departmentids,
    };

    // Dispatch the bulk delete action
    dispatch(bulkAssetDelete(data));

    // Update the filteredData state after deletion
    setFilteredData((prevData) =>
      prevData.filter((item) => !selectedAssetIds.includes(item.assetid))
    );

    // Reset the toggle and close modal
    setToggle(true);
    setOpenBulkDeleteModal(false);
  };

  const handleEdit = (id) => {
    navigate(`/edit-asset/${id}`);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids,
      userrole: userAccess?.role,
    };

    dispatch(getNewAssets(data));
  }, []);

  useEffect(() => {
    if (Object.keys(dataCount)?.length > 0) {
      setData(dataCount?.assets);
      let data = {
        department: dataCount?.department_count || 0,
        checkout: dataCount?.checkout_count || 0,
        movements: dataCount?.movement_count || 0,
      };
      setAllCounts(data);
    }
  }, [dataCount]);

  const email = "www.vajra.software";
  const contact = "contact@vajra.software";

  const MiniQRset = styled(Box)(({ theme }) => ({
    width: "60px",
    height: "60px",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const handleDownloadQrCode = (item) => {
    const qrImage = item?.qr_img;

    const link = document.createElement("a");
    link.href = qrImage;
    link.download = `${item?.name || "asset-details"}.png`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const goToDepartment = () => {
    dispatch(setNavigationSource("assets"));
    navigate("/department-list");
  };

  const uniqueDepartments = [...new Set(data.map((item) => item.department))];
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const handleDepartmentChange = (newValue) => {
    setSelectedDepartment(newValue);

    if (newValue) {
      const filtered = data.filter((item) => item.department === newValue);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };
  const isGuest = userAccess && userAccess.role === "Guest";

  const handleDeleteList = () => {
    navigate("/deleted-assetlist");
  };

    const itemsPerPage = 10;
  
    const [currentPage, setCurrentPage] = useState(1);
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
    const tableRef = useRef(null);
  
    const handlePageChange = (event, newPage) => {
      setCurrentPage(newPage);
  
      if (tableRef.current) {
        tableRef.current.scrollTop = 0;
      }
    };

  return (
    <Box
    display="flex"
    flexDirection="column"
    alignItems="stretch"
    gap="10px"
    height="90vh"
    padding="16px"
    marginTop="-15px"
  >
    <Box display="flex" justifyContent="end">
      <Button
        onClick={handleButtonClick}
        variant="contained"
        sx={{
          borderRadius: "22px",
          background: "rgba(70, 144, 255, 1)",
        }}
      >
        Depreciation <Calculate sx={{ marginLeft: "10px" }} />
      </Button>
    </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="stretch"
        gap="10px"
        sx={{ display: { xs: "none", sm: "none", lg: "flex" } }}
      >
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {allCounts?.department}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Departments</Label>
              <IconButton onClick={goToDepartment}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {allCounts?.checkout}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Check Outs</Label>
              <IconButton onClick={() => navigate("/check-in")}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {allCounts?.movements}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Asset movements</Label>
              <IconButton onClick={() => navigate("/asset-history-list")}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Assets.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          {selectedAssetIds?.length > 0 && (
            <IconButton
              onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
              sx={{
                bgcolor: "#1B2535",
                width: "27px ",
                height: "27px",
                "&:hover": {
                  backgroundColor: "#1B2535",
                  color: "white",
                },
              }}
            >
              <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
            </IconButton>
          )}
          <Button
            onClick={handleAddAssetClick}
            sx={{
              bgcolor: "#1B2535",
              width: "auto ",
              height: "27px",
              textDecoration: "none",
              borderRadius: "20px",
              borderRight: "20px",
              color: "white",
              "&:hover": {
                backgroundColor: "#1B2535",
                color: "white",
              },
            }}
          >
            Add Asset <AddIcon style={{ color: "white", fontSize: "20px" }} />
          </Button>
          <Button
            onClick={handleDeleteList}
            sx={{
              bgcolor: "#1B2535",
              width: "120px ",
              height: "27px",
              textAlign: "center",
              textDecoration: "none",
              borderRight: "20px",
              color: "white",
              "&:hover": {
                backgroundColor: "#1B2535",
                color: "white",
              },

              borderRadius: "20px",
              fontSize: {
                xs: "0.75rem",
                sm: "8px",
                md: "0.75rem",
                lg: "0.75rem",
                xl: "0.75rem",
              },
            }}
          >
            Deleted Asset{" "}
            <DeleteIcon
              style={{
                color: "white",
                fontSize: {
                  xs: "0.75rem",
                  sm: "8px",
                  md: "0.75rem",
                  lg: "0.75rem",
                  xl: "0.75rem",
                },
              }}
            />
          </Button>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            width="15%"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="10px"
            gap={"5px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Autocomplete
              options={uniqueDepartments}
              value={selectedDepartment}
              onChange={(event, newValue) => {
                handleDepartmentChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Department"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingRight: 0,
                    },
                  }}
                  sx={{
                    bgcolor: "transparent",
                    "& .MuiInputBase-root": {
                      background: "transparent",
                      border: "none",
                    },
                    "&:before, &:after": {
                      border: "none",
                    },
                  }}
                />
              )}
              freeSolo
              sx={{
                width: "100%",
                "& .MuiAutocomplete-clearIndicator": {
                  backgroundColor: "white",
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                },
              }}
            />
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="department"
              Filter2="cmcamc"
              FilterName1="Department"
              FilterName2="AMC/CMC Status"
              sortDate=""
              sortPrice="price"
              dueDate=""
              name="name"
              csvName="All Assets"
            />
          </Box>

          <Box
            sx={{
              flex: 1,
              width: "100%",
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-around"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}
            >
              {selectedAssetIds?.length > 0 && (
                <IconButton
                  onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "27px ",
                    height: "27px",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                  }}
                >
                  <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
                </IconButton>
              )}
              <Button
                onClick={handleAddAssetClick}
                sx={{
                  bgcolor: "#1B2535",
                  width: "100px ",
                  height: "27px",
                  textDecoration: "none",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                  borderRadius: "20px",
                }}
              >
                Add Asset{" "}
                <AddIcon style={{ color: "white", fontSize: "20px" }} />
              </Button>
              <Button
                onClick={handleDeleteList}
                sx={{
                  bgcolor: "#1B2535",
                  width: "120px ",
                  height: "27px",
                  textAlign: "center",
                  textDecoration: "none",
                  borderRight: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },

                  borderRadius: "20px",
                  fontSize: {
                    xs: "0.75rem",
                    sm: "8px",
                    md: "0.75rem",
                    lg: "0.75rem",
                    xl: "0.75rem",
                  },
                }}
              >
                Deleted Asset{" "}
                <DeleteIcon
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "4px",
                      sm: "8px",
                      md: "0.75rem",
                      lg: "1rem",
                      xl: "1rem",
                    },
                  }}
                />
              </Button>
              {/* <IconButton
              onClick={() => navigate("/add-asset")}
              sx={{
                bgcolor: "#1B2535",
                width: "27px ",
                height: "27px",
                "&:hover": {
                  backgroundColor: "#1B2535",
                  color: "white",
                },
              }}>
              <AddIcon style={{ color: "white", fontSize: "20px" }} />
            </IconButton> */}

              {ids?.subs_plan !== "Basic" && <BulkUploadAssetPopUp />}
            </Stack>
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          {/* Table */}
          <TableContainer
            component={Paper}
            style={{
              height: "calc(80vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
            ref={tableRef}
          >
            {currentData.length > 0 ? (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableHeading>
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selectedAssetIds.length > 0 &&
                          selectedAssetIds.length <
                            filteredData?.filter(
                              (item) => item.check_in_status !== "checked_out"
                            ).length
                        }
                        checked={
                          selectedAssetIds.length ===
                          filteredData?.filter(
                            (item) => item.check_in_status !== "checked_out"
                          ).length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all assets",
                        }}
                      />
                    </TableHeading>
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>
                    <TableHeading>Serial Code</TableHeading>
                    <TableHeading>Brand</TableHeading>
                    <TableHeading>Department</TableHeading>
                    <TableHeading sx={{ textAlign: "center" }}>
                      Status
                    </TableHeading>
                    <TableHeading>Warranty expiry</TableHeading>
                    <TableHeading>Price</TableHeading>

                    {/* <TableHeading>AMC/CMC</TableHeading> */}
                    <TableHeading>QR Code</TableHeading>
                    <TableHeading></TableHeading>
                  </TableRow>
                </TableHead>

                <TableBody style={{ overflowY: "scroll" }}>
                  {currentData.map((item, index) => (
                    <TableRow key={item.assetid}>
                      <TableCells>
                        <Checkbox
                          checked={selectedAssetIds.includes(item.assetid)}
                          disabled={item.check_in_status === "checked_out"}
                          onChange={() => handleSelectRowClick(item.assetid)}
                          onClick={(event) => {
                            if (item.check_in_status === "checked_out") {
                              event.stopPropagation();
                              showModal(
                                `The asset ${item.assetid} is not available in your hospital.`
                              );
                            }
                          }}
                        />
                      </TableCells>
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.name}>{item.name}</Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.serialno}>{item.serialno}</Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.brand}>{item.brand}</Tooltip>
                      </TableCells>
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.department}>
                          {item.department}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                          sx={{
                            // textAlign: "center",
                            color: "rgba(70, 144, 255, 1)",
                          }}
                        >
                          {item?.status !== "Working" ? (
                            <Box
                              size="small"
                              sx={{
                                borderRadius: " 0px 8px 0px 8px",
                                textTransform: "none",
                                width: "90px",
                                height: "25px",
                                bgcolor: "#74ACFF",
                                color:"white",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                              }}
                              variant="contained"
                            >
                              {item.status}
                            </Box>
                          ) : (
                            <>{item.status}</>
                          )}
                        </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        {item.warranty_expdate === "Invalid date"
                          ? ""
                          : item.warranty_expdate}
                      </TableCells>
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.price}>{item.price}</Tooltip>
                      </TableCells>

                      {/* <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          {item.cmcamc}
                        </TableCells> */}

                      {/* <TableCell>
                          <MiniQRset
                            onClick={handleDownloadQrCode}
                            as="img"
                            src={item?.qr_img}
                          ></MiniQRset>
                        </TableCell> */}
                      <TableCells
                        sx={{
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          width: "20px",
                        }}
                      >
                        <MiniQRset
                          onClick={() => handleDownloadQrCode(item)} // Pass the item data
                          as="img"
                          src={item?.qr_img}
                        />
                      </TableCells>
                      <TableCells
                        sx={{
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          width: "20px",
                        }}
                      >
                        <EditIcon
                          disabled={userAccess?.editprod !== "Yes"}
                          onClick={() =>
                            navigate(`/edit-asset/${item.assetid}`)
                          }
                        />
                      </TableCells>
                    </TableRow>
                  ))}
                  {/* {console.log(filteredData)} */}
                </TableBody>
              </Table>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                }}
              >
                No assets found.
              </Typography>
            )}
          </TableContainer>
           <Stack
                        sx={{
                          padding: "20px",
                          bgcolor: "white",
                          gap: "20px",
                        }}
                      >
                        <Divider />
                        <Stack
                          sx={{
                            bgcolor: "white",
                            alignItems: "center",
          
                            justifyContent: "end",
                            flexDirection: "row",
                          }}
                        >
                          <Pagination
                            count={Math.ceil(filteredData.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                          ></Pagination>
                          {/* <Button
                            onClick={handleButtonClick}
                            variant="contained"
                            sx={{
                              borderRadius: "22px",
                              background: "rgba(70, 144, 255, 1)",
                            }}
                          >
                            Depreciation <Calculate sx={{ marginLeft: "10px" }} />
                          </Button> */}
                        </Stack>
                      </Stack>
        </Box>
      </Box>
      <BulkDeleteDeleteModal
        open={openBulkDeleteModal}
        onClose={handleCloseBulkDeleteModal}
        onDelete={handleBulkDelete}
        text={
          selectedAssetIds?.length > 1
            ? `Are you sure you want to delete the selected assets?`
            : `Are you sure you want to delete the selected asset?`
        }
        count={selectedAssetIds?.length}
      />
    </Box>
  );
};

export default AllAsset;
