import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

import { Stack, Typography, styled, Tooltip, Divider } from "@mui/material";
import { useState } from "react";
import { getNotRequired } from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import { Pagination } from "@mui/material";
import { useRef } from "react";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const NotCalibratedPage = () => {
  const notRequired = useSelector((store) => store.data.calibrateNotRequired);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(getNotRequired(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (notRequired && notRequired?.length > 0) {
      setData(notRequired);
    }
  }, [notRequired]);
  const navigate = useNavigate();

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      //   justifyContent="space-between"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px"
    >
      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "none", lg: "none" } }}
        >
          <Typography variant="h6">
            Not Required
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Typography>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              display: { xs: "none", sm: "flex", lg: "flex" },
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}
            >{` ${data?.length}`}</Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={""}
              Filter1="department"
              Filter2=""
              FilterName1="Department"
              FilterName2=""
              sortDate=""
              dueDate=""
              name="name"
              sortPrice=""
              csvName="Not Calibrated"
            />
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            {currentData.length > 0 ? (
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    style={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 237, 237, 1)",
                      },
                    }}
                  >
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>
                    <TableHeading>Serial No.</TableHeading>
                    <TableHeading>Department</TableHeading>
                  </TableRow>
                </TableHead>
                {/* Table Body with Scrollbar */}
                <TableBody style={{ overflowY: "scroll" }}>
                  {currentData.map((item, index) => (
                    <TableRow
                      key={item?.servid}
                      sx={{
                        "&:hover": {
                          bgcolor: "#EEF5FF",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCells>
                        <Tooltip
                          onClick={() =>
                            navigate(`/asset-detail/${item.assetid}`)
                          }
                          title={item.name}
                        >
                          {item.name}
                        </Tooltip>
                      </TableCells>

                      <TableCells>
                        <Tooltip
                          onClick={() =>
                            navigate(`/asset-detail/${item.assetid}`)
                          }
                          title={item.assetcode}
                        >
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>
                      <TableCells>
                        {" "}
                        <Tooltip
                          onClick={() =>
                            navigate(`/asset-detail/${item.assetid}`)
                          }
                          title={item.serialno}
                        >
                          {item.serialno}
                        </Tooltip>
                      </TableCells>

                      <TableCells>
                        <Tooltip
                          onClick={() =>
                            navigate(`/asset-detail/${item.assetid}`)
                          }
                          title={item.department}
                        >
                          {item.department}
                        </Tooltip>
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                No Calibrate Not Required data found.
              </Typography>
            )}
          </TableContainer>
          <Divider />
          <Stack
            sx={{
              bgcolor: "white",
              padding: "20px",
              justifyContent: "right",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default NotCalibratedPage;
