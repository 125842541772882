import React, { useState, useEffect } from "react";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Cookies from "js-cookie";

const NotificationModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check cookie to determine if the modal should be shown
    const hasSeenModal = Cookies.get("hasSeenNotificationModal");
    if (!hasSeenModal) {
      setIsModalOpen(true); // Show modal if user hasn't seen it
    }
  }, []);

  const handleAllow = () => {
    // Request notification permission
    Notification.requestPermission().then(() => {
      closeAndSave();
    });
  };

  const handleBlock = () => {
    closeAndSave();
  };

  const handleClose = () => {
    closeAndSave();
  };

  const closeAndSave = () => {
    setIsModalOpen(false); // Close modal
    Cookies.set("hasSeenNotificationModal", "true", { expires: 365 }); // Set cookie for 1 year
  };

  return (
    <>
      {isModalOpen && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <button onClick={handleClose} style={styles.closeIcon}>
              &times;
            </button>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <NotificationsActiveIcon style={{ height: "100px", width: "100px", color: "rgba(27, 37, 53, 1)" }} />
            </div>
            <p style={{ fontWeight: "bold" }}>
              Allow Browser Notification settings to get your notifications?
            </p>
            {/* <div style={styles.buttonGroup}>
              <button onClick={handleAllow} style={styles.allowBtn}>
                Allow
              </button>
              <button onClick={handleBlock} style={styles.blockBtn}>
                Block
              </button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

// Inline styles for simplicity
const styles = {
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    textAlign: "center",
    width: "300px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "20px",
  },
  allowBtn: {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  blockBtn: {
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default NotificationModal;
