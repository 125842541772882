import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { Pagination } from "@mui/material";
import { useRef } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Stack,
  Typography,
  styled,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { deleteDepart, getDepartmentData } from "../redux/nbmData/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import AddDepartmentPage from "./AddDepartmentPage";
import EditDepartment from "./EditDepartment";
import DeleteModal from "../components/DeleteModal";
import BulkDeleteDepartment from "../components/BulkDeleteDepartment";
import DialogBox from "../components/DialogBox";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Confirmation from "../components/Confirmation";
import { useLocation } from "react-router-dom";
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const OuterSet = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  // alignItems: "stretch",
  gap: "10px",
  height: "85vh",
  width: "100%",
  bgcolor: "white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",

    // flexDirection:"column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DepartmentList = () => {
  const allDepartment = useSelector((store) => store.data.department);
  const DepartmentUpload = useSelector((store) => store.data.departmentName);
  // console.log("DepartmentUpload", DepartmentUpload);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);

  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [getId, setGetId] = useState("");

  const response = useSelector((store) => store?.data?.deleteDepart);

  const fromPage = useSelector((store) => store?.data?.fromPage);

  const [showEdit, setShowEdit] = useState(false);
  const [specificData, setSpecificData] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const text = "department";

  const [showMovementBox, setShowMovementBox] = useState(false);
  const [addMobileDep, setAddMobileDep] = useState(false);

  const handleAddMovementClick = () => {
    if (userData?.subs_plan === "Basic" && data?.length > 5) {
      navigate("/"); // Redirect to homepage
    }
    setShowMovementBox(true);
    setShowAddButton(false);
  };

  const handleAddMobileClick = () => {
    if (userData?.subs_plan === "Basic" && data?.length > 5) {
      navigate("/"); // Redirect to homepage
    }
    setAddMobileDep(!addMobileDep);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (item) => {
    setShowEdit(true);
    setAnchorEl(null);
    setSpecificData(item);
  };

  const handleDelete = (item) => {
    localStorage.setItem("bulkChange", getId.departname);
    navigate(`/department-asset-delete-details/${getId?.departid}`);
    // console.log("getId?.departid",getId)
  };

  const handleDeleteSingle = () => {
    let data = {
      userid: userData?.userid,
      departid: getId.departid,
      hospid: userData?.hospid,
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };
    // console.log("())()()()()()()()()()()()", data);
    dispatch(deleteDepart(data, token));
    setToggle(true);
    handleCloseDelete();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (allDepartment?.length !== undefined && allDepartment?.length > 0) {
      setData(allDepartment);
    }
  }, [allDepartment, response]);

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(getDepartmentData(data));
    setToggle(false);
  }, [dispatch, response, toggle, showEdit, DepartmentUpload]);
  const handleDeleteById = (getId) => {
    // console.log("departiddepartid------------>>>>", getId);
    handleClose();

    setToggle(true);

    if (getId?.totalassets === "0") {
      handleOpenDelete();
      setGetId(getId);
    }
    if (getId?.totalassets !== "0") {
      setGetId(getId);
      handleOpenDeleteModal();
    }
  };

  const handleShowEdit = () => {
    setShowEdit(false);
  };

  // console.log("filter", filteredData);
  const handleCloseDepart = () => {
    setShowMovementBox(false);
    setShowAddButton(true);
    setAddMobileDep(false);
  };

  const [showAddButton, setShowAddButton] = useState(false);

  useEffect(() => {
    if (fromPage === "dashboard") {
      setShowMovementBox(true);
      setShowAddButton(false);
    } else if (fromPage === "assets") {
      setShowMovementBox(false);
      setShowAddButton(true);
    } else {
      setShowMovementBox(false);
      setShowAddButton(true);
    }
  }, [fromPage]);

  const isGuest = userAccess && userAccess.role === "Guest";

  const handleDeleteList = () => {
    navigate("/DepartmentDeletedList");
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "10px",
        alignItems: "center",
      }}
    >
      {showEdit ? (
        <EditDepartment data={specificData} showEdit={handleShowEdit} />
      ) : (
        <OuterSet>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            style={{ width: "100%" }}
          >
            <Stack
              justifyContent="space-between"
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
              }}
            >
              <Label variant="h6">
                Department.
                <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
              </Label>
              <Button
                onClick={handleAddMobileClick}
                sx={{
                  bgcolor: "#1B2535",
                  width: "auto ",
                  height: "27px",
                  textDecoration: "none",
                  borderRadius: "20px",
                  borderRight: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                }}
              >
                Add Department{" "}
                <AddIcon style={{ color: "white", fontSize: "20px" }} />
              </Button>
              <Button
                onClick={handleDeleteList}
                sx={{
                  bgcolor: "#1B2535",
                  width: "10px",
                  height: "27px",
                  textAlign: "center",
                  textDecoration: "none",
                  borderRight: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },

                  borderRadius: "20px",
                  fontSize: {
                    xs: "0.75rem",
                    sm: "8px",
                    md: "0.75rem",
                    lg: "0.75rem",
                    xl: "0.75rem",
                  },
                }}
              >
                <DeleteIcon
                  style={{
                    color: "white",
                    fontSize: {
                      xs: "0.75rem",
                      sm: "8px",
                      md: "0.75rem",
                      lg: "0.75rem",
                      xl: "0.75rem",
                    },
                  }}
                />
              </Button>
            </Stack>

            <Box display="flex" justifyContent="end">
              <Button
                onClick={handleAddMovementClick}
                variant="contained"
                sx={{
                  borderRadius: "22px",
                  background: "rgba(70, 144, 255, 1)",
                }}
              >
                Add Department <AddIcon />
              </Button>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="stretch"
              gap="10px"
            >
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-between"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  display: { xs: "none", sm: "none", lg: "flex" },
                }}
              >
                <Typography>{`Total : ${data?.length}`}</Typography>
              </Stack>
              <Box flex="2">
                <SortSearchFilter
                  data={data}
                  setData={setData}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  heading={"My Orders"}
                  Filter1="departincharge"
                  Filter2=""
                  sortPrice=""
                  sortDate="createdate"
                  name="departname"
                />
              </Box>
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-between"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  display: { xs: "none", sm: "none", lg: "flex" },
                }}
              >
                <Button
                  onClick={handleDeleteList}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "170px",
                    height: "30px",
                    textAlign: "center",
                    textDecoration: "none",
                    borderRight: "20px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                    borderRadius: "5px",
                    fontSize: {
                      xs: "0.75rem",
                      sm: "8px",
                      md: "0.75rem",
                      lg: "0.75rem",
                      xl: "0.75rem",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DeleteIcon
                    sx={{
                      color: "white",
                      fontSize: {
                        xs: "0.75rem",
                        sm: "8px",
                        md: "0.75rem",
                        lg: "1rem",
                        xl: "1.3rem",
                      },
                      mr: 1,
                    }}
                  />
                  Department Deleted
                </Button>
              </Stack>
            </Box>

            {addMobileDep && (
              <AddDepartmentPage handleCloseDepart={handleCloseDepart} />
            )}
            <Stack
              sx={{
                width: "100%",
                height: { xs: "70%", sm: "70%", lg: "100%" },

                bgcolor: "white",
              }}
              flexDirection={"column"}
            >
              <TableContainer
                component={Paper}
                sx={{
                  height: "100%",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  width: "100%",
                }}
              >
                {filteredData.length > 0 ? (
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableHeading>Department</TableHeading>
                        <TableHeading>Total Assets</TableHeading>
                        <TableHeading>Created Date</TableHeading>
                        <TableHeading>Incharge</TableHeading>
                        <TableHeading>Down Time</TableHeading>

                        <TableHeading></TableHeading>
                        <TableHeading></TableHeading>
                      </TableRow>
                    </TableHead>

                    <TableBody style={{ overflowY: "scroll" }}>
                      {filteredData.map((item, index) => (
                        <TableRow>
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            {item.totalassets > 0 ? (
                              <Link
                                to={`/department-asset-details/${item?.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.departname}
                              </Link>
                            ) : (
                              item.departname
                            )}
                          </TableCells>
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            {item.totalassets > 0 ? (
                              <Link
                                to={`/department-asset-details/${item?.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.totalassets}
                              </Link>
                            ) : (
                              item.totalassets
                            )}
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            {item.totalassets > 0 ? (
                              <Link
                                to={`/department-asset-details/${item?.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.createdate}
                              </Link>
                            ) : (
                              item.createdate
                            )}
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            {item.totalassets > 0 ? (
                              <Link
                                to={`/department-asset-details/${item?.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.departincharge}
                              </Link>
                            ) : (
                              item.departincharge
                            )}
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            {item.totalassets > 0 ? (
                              <Link
                                to={`/department-asset-details/${item?.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.tot_downtime}
                              </Link>
                            ) : (
                              item.tot_downtime
                            )}
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={Link}
                            to={`/editDepart/${item?.departid}`}
                          >
                            <BorderColorIcon
                              style={{ cursor: "pointer" }}
                              size={20}
                              disabled={userAccess?.editdepart !== "Yes"}
                              // onClick={() => handleEdit(item)}
                              value="edit"
                            />
                          </TableCells>
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              disabled={userAccess?.deldepart !== "Yes"}
                              value="delete"
                              onClick={() => {
                                if (!isGuest) {
                                  // Only allow click if the user is not a guest
                                  handleDeleteById(item);
                                }
                              }}
                            />
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    No department found.
                  </Typography>
                )}
              </TableContainer>

              {showAddButton && (
                <Stack
                  sx={{
                    width: "100%",
                    padding: { xs: "0px", sm: "20px", md: "20px", lg: "20px" },
                    bgcolor: "white",
                    gap: "20px",
                    display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  }}
                >
                  <Divider />

                  <Stack
                    sx={{
                      bgcolor: "white",
                      alignItems: "center",
                      justifyContent: "end",
                      flexDirection: "row",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(filteredData.length / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    ></Pagination>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
          {showMovementBox && (
            <AddDepartmentPage handleCloseDepart={handleCloseDepart} />
          )}

          <Confirmation
            handleCloseModal={handleCloseDelete}
            open={openDelete}
            handleDone={handleDeleteSingle}
            text={"Are you sure you want to delete ?"}
          />

          <BulkDeleteDepartment
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            onDelete={handleDelete}
            text={text}
            id={getId}
          />

          <DialogBox
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        </OuterSet>
      )}
    </Box>
  );
};
export default DepartmentList;
