import React, { useRef } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Pagination } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  IconButton,
  Modal,
} from "@mui/material";
import { useState } from "react";
import {
  AssetHistoryRetrieve,
  movementApproveData,
  movementDeleteData,
} from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

import AssetMovement from "./AssetMovement";
import Confirmation from "../components/Confirmation";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const MainFlex = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  gap: "10px",
  height: "85vh",
  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AllAssetHistory = () => {
  const assetHistoryData = useSelector(
    (store) => store?.data?.assetHistoryRetrieveData
  );
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [filteredData, setFilteredData] = useState([]);

  const [showMovementBox, setShowMovementBox] = useState(false);
  const response = useSelector((store) => store.data.assetHistoryData);
  const [openDelete, setOpenDelete] = useState(false);

  // Open the confirmation modal and set the selected item
  const handleOpenDelete = (item) => {
    setSelectedItem(item);
    setOpenDelete(true);
  };

  // Close the confirmation modal
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedItem(null);
  };

  // Function to handle the delete action
  const handleDeleteSingle = () => {
    if (!selectedItem) return;

    const data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
      username: userIds?.username,
      assetid: selectedItem.assetid,
      send_date: selectedItem.senddate,
    };

    dispatch(movementDeleteData(data, token))
      .then(() => {
        let data = {
          hospid: ids?.hospid,
          userid: ids?.userid,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };
        dispatch(AssetHistoryRetrieve(data, token));
      })
      .finally(() => {
        setOpenDelete(false); // Close the modal after dispatch
      });
  };

  const handleAddMovementClick = () => {
    setShowMovementBox(true);
  };

  const handlePendingClick = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleConfirmApproval = () => {
    if (selectedItem) {
      const payload = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch?.id,
        userrole: userAccess?.role,
        depart_ids: userAccess?.departmentids,
        username: userIds?.username,
        assetid: selectedItem.assetid,
        send_date: selectedItem.senddate,
      };

      dispatch(movementApproveData(payload))
        .then(() => {
          let data = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            branchid: userBranch?.id,
            userrole: userAccess?.role,
            depart_ids: userAccess.departmentids,
          };
          dispatch(AssetHistoryRetrieve(data, token));
        })
        .finally(() => {
          setOpenModal(false);
          setSelectedItem(null);
        });
    }
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(AssetHistoryRetrieve(data, token));
  }, [dispatch, response]); // Ensure the table updates whenever `response` changes

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(AssetHistoryRetrieve(data, token));
  }, [dispatch, response]);

  useEffect(() => {
    if (assetHistoryData && assetHistoryData?.length > 0) {
      setData(assetHistoryData);
    }
  }, [assetHistoryData]);

  const handleCloseDepart = () => {
    setShowMovementBox(false);
  };
  const buttonRef = useRef(null);
  const handleAddCircleIconClick = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: "smooth" }); // Scrolls to the button
    }
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <Stack sx={{ padding: "10px", marginTop: "-15px" }}>
      <MainFlex>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          style={{ width: "100%" }}
        >
          <Stack
            justifyContent="space-between"
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
          >
            <Label variant="h6">
              Assets history.
              <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
            </Label>
            <IconButton>
              <AddCircleIcon
                style={{ color: "#4690FF" }}
                onClick={handleAddCircleIconClick}
              />
            </IconButton>
          </Stack>
          <Box display="flex" justifyContent="end">
            <Button
              onClick={handleAddMovementClick}
              variant="contained"
              sx={{
                borderRadius: "22px",
                background: "rgba(70, 144, 255, 1)",
                // marginRight: "20px",
              }}
            >
              Add movements <AddIcon />
            </Button>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px"
          >
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                display: { xs: "none", sm: "none", lg: "flex" },
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}
            >
              <Typography>{`Total : ${data?.length}`}</Typography>
            </Stack>
            <Box flex="2">
              <SortSearchFilter
                data={data}
                setData={setData}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                Filter1="p_depart"
                Filter2="to_depart"
                FilterName1="Prev Depart"
                FilterName2="Current Depart"
                sortDate="date"
                sortPrice=""
                dueDate=""
                name="assetname"
                csvName="Assets Movements"
              />
            </Box>
          </Box>
          <Stack
            style={{
              width: "100%",
              height: "100%",

              bgcolor: "white",
            }}
            overflow="auto"
            flexDirection={"column"}
          >
            {/* Table */}
            <TableContainer
              component={Paper}
              style={{
                height: "calc(90vh - 200px)",

                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}
              ref={tableRef}
            >
              {currentData.length > 0 ? (
                <Table stickyHeader>
                  {/* Table Header */}
                  <TableHead>
                    <TableRow>
                      <TableHeading>Asset Name</TableHeading>
                      <TableHeading>Asset Code</TableHeading>
                      <TableHeading>Prev Depart</TableHeading>
                      <TableHeading>Curr Depart</TableHeading>

                      <TableHeading>Date</TableHeading>
                      <TableHeading>Time </TableHeading>
                      <TableHeading>Sender</TableHeading>
                      <TableHeading>Ack’ment</TableHeading>
                      <TableHeading>Receiver</TableHeading>
                      <TableHeading></TableHeading>
                    </TableRow>
                  </TableHead>
                  {/* Table Body with Scrollbar */}
                  <TableBody style={{ overflowY: "scroll" }}>
                    {currentData.map((item, index) => (
                      <TableRow>
                        <TableCells
                          sx={{
                            textOverflow: "clip",
                            whiteSpace: "normal",
                            textDecoration: "none",
                          }}
                        >
                          <Tooltip title={item.assetname}>
                            {item.assetname}
                          </Tooltip>
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        >
                          {item?.assetcode}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        >
                          {item?.p_depart}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        >
                          {item?.to_depart}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {item?.senddate}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {item?.sendtime}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {item?.sender}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {item?.status === "NA" &&
                          ((userAccess.departmentids.includes(
                            item?.to_departid
                          ) &&
                            userAccess.departments.includes(item?.to_depart)) ||
                            ["Owner", "Super Admin", "Branch"].includes(
                              userAccess.role
                            )) ? (
                            <Button
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                height: "30px",
                                padding: "4px",
                                width: {
                                  xs: "85px",
                                  sm: "65px",
                                  md: "70px",
                                  lg: "100px",
                                },
                                bgcolor: "rgba(255, 107, 0, 1)",
                                border: "1px solid rgba(255, 107, 0, 1)",
                                color: "white",
                                "&:hover": {
                                  bgcolor: "rgba(255, 107, 0, 1)",
                                  border: "1px solid rgba(255, 107, 0, 1)",
                                },
                              }}
                              onClick={() => handlePendingClick(item)}
                            >
                              Pending
                            </Button>
                          ) : (
                            <span style={{ color: "#4690ff" }}>
                              {item?.status === "NA" ? "Pending" : item?.status}
                            </span>
                          )}

                          {/* {item?.status === "NA" ? (
                            <Button
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                height: "30px",
                                padding: "4px",
                                width: {
                                  xs: "85px",
                                  sm: "65px",
                                  md: "70px",
                                  lg: "100px",
                                },
                                bgcolor: "rgba(255, 107, 0, 1)",
                                border: "1px solid rgba(255, 107, 0, 1)",
                                color: "white",
                                "&:hover": {
                                  bgcolor: "rgba(255, 107, 0, 1)",
                                  border: "1px solid rgba(255, 107, 0, 1)",
                                },
                              }}
                              onClick={() => handlePendingClick(item)}
                            >
                              Pending
                            </Button>
                          ) : (
                            <span style={{ color: "#4690ff" }}>
                              {item?.status}
                            </span>
                          )} */}
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {item.receiver}
                        </TableCells>
                        {item?.status === "NA" && (
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            onClick={() => handleOpenDelete(item)}
                            style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                          >
                            <DeleteIcon />
                          </TableCells>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                >
                  No movement data found.
                </Typography>
              )}
            </TableContainer>

            {!showMovementBox && (
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  bgcolor: "white",
                  gap: "20px",
                  // border:'1px solid red'
                }}
              >
                <Divider />
                <Stack
                  ref={buttonRef}
                  sx={{
                    bgcolor: "white",
                    alignItems: "flex-end",
                    padding: "10px",
                    justifyContent: "column",
                    //  border:'1px solid red'
                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredData.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  ></Pagination>
                  {/* <Button
                    onClick={handleAddMovementClick}
                    variant="contained"
                    sx={{
                      borderRadius: "22px",
                      background: "rgba(70, 144, 255, 1)",
                      // marginRight: "20px",
                    }}
                  >
                    Add movements <AddIcon />
                  </Button> */}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: { xs: "300px", md: "400px" },
            }}
          >
            {/* Close Icon */}
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "grey.500",
              }}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" mb={2} sx={{ textAlign: "center" }}>
              Are you sure you want to Approve the asset?
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmApproval}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseModal}
              >
                No
              </Button>
            </Box>
          </Box>
        </Modal>

        {showMovementBox && (
          <AssetMovement handleCloseDepart={handleCloseDepart} />
        )}

        <Confirmation
          handleCloseModal={handleCloseDelete}
          open={openDelete}
          handleDone={handleDeleteSingle}
          text={"Are you sure you want to delete ?"}
        />
      </MainFlex>
    </Stack>
  );
};

export default AllAssetHistory;
