import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";

import {
  Box,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  createTask,
  getTask,
  getUserData,
  retrieveAllAssets,
  userRetrieveByBranch,
} from "../redux/nbmData/action";
import { createNbmTask, getNbmTask } from "../redux/nbmData/action";

import moment from "moment";
import TaskModal from "../components/TaskModal";
import TaskEmpty from "../components/TaskEmpty";
import { useTheme } from "@emotion/react";

const ElementRows = styled(TableCell)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  // alignItems: "center",
  flexDirection: "column",
  // justifyContent: "center",
 

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    minHeight:'100vh',
    padding:'10px'
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "90%",
  height: "50px",
  justifyContent: "end",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    gap: "5px",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MainRow = styled(TableRow)(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextFieldInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({

  gap: "20px",
  width: "100%",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: "10px",
//   backgroundColor:'white',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "left",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const UserSet = styled(Box)(({ theme }) => ({
  width: "100%",
padding: "10px",
background:'white',


  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {

    width: "97%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TopSplitContainer = styled(Box)(({ theme }) => ({
  gap: "20px",

  backgroundColor:'white',
  display: "flex",
  padding:'10px',

  width:'100%',
  justifyContent: "end",

  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "97%",
    gap: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const UserTopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  width: "100%",
  backgroundColor:'white',
  alignItems: "center",
  paddingRight: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Boxes = styled(Box)(({ theme }) => ({
  gap: "20px",
  // width: "90%",

  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  padding: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gap: "10px",
    flexDirection: "row",
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBox = styled(Box)(({ theme }) => ({
  paddingTop: "20px",
  width: "85%",

  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  paddingBottom: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  textAlign: "left",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: "30px",

  textTransform: "none",
  textAlign: "center",
  whiteSpace: "noWrap",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    size: "small",
    padding: "2px 10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  width: "100%",
  borderRadius: "0px",
  background: "white",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const TableCells = styled(TableCell)(({ theme }) => ({
    maxWidth: "11ch",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textDecoration: "none",
    borderBottom: "none",
    cursor: "pointer",
    padding: "15px",
    fontWeight: 500,
  }));
  const TableHeading = styled(TableCell)(({ theme }) => ({
    color: "rgba(27, 37, 53, 1)",
    fontWeight: 700,
    padding: "10px",
  }));




function TaskListPage() {
  const cDate = new Date();
  const currentMonth = (cDate.getMonth() + 1).toString().padStart(2, "0");

  const currentYear = cDate.getFullYear();
  const currentDate = moment().format("DD-MM-YYYY");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const allAssets = useSelector((store) => store.data.allAssets);
  const userData = useSelector((store) => store.data.userRetrieveByBranch);
  const update = useSelector((store) => store.data.createdTask);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const allTask = useSelector((store) => store.nbmData.getTask);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [selected, setSelected] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState(null);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [toggle, setToggle] = React.useState(null);
  const isMatch2 = useMediaQuery(theme.breakpoints.down("sm"));
  const [filterChanged, setFilterChanged] = useState(false);

  const handleMonth = (event) => {
    let month = event.target.value;

    setMonth(month);
  };

  const handleYear = (event) => {
    let year = event.target.value;
    setYear(year);
  };

  const [rows, setRows] = useState([{ task: "", description: "" }]);

  const tsk = rows?.some((item) => {
    return item?.task !== "";
  });

  const dsc = rows?.some((item) => {
    return item?.description !== "";
  });

  const handleAddRow = () => {
    setRows([...rows, { task: "", description: "" }]);
  };

  const isDataValid = rows.every(
    (item) => item.task.label !== "" && item.description !== ""
  );

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSubmit = () => {
    let data = {
      hospid: userIds?.hospid,
      hospname: userIds?.hospname,
      userid: userIds?.userid,
      username: userIds?.username,
      taskname: rows?.map((item) => item?.task.label),
      desc: rows?.map((item) => item?.description),
      branchid: userBranch.id,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    setToggle(true);
    dispatch(createNbmTask(data));
  };

  const handleTaskChange = (value, index) => {
    if (value !== null) {
      const updatedRows = [...rows];
      updatedRows[index].task = value;
      setRows(updatedRows);
      setSelected(value);
    }
  };

  const handleDescriptionChange = (event, index) => {
    const updatedRows = [...rows];
    updatedRows[index].description = event.target.value;
    setRows(updatedRows);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = allTask;
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      depart_ids: userAccess.departmentids,
      branchid: userBranch.id,
      userrole:userAccess?.role,
    };

    dispatch(retrieveAllAssets(data));
  }, [dispatch]);

  useEffect(() => {
    if (allAssets && allAssets?.length > 0) {
      setData(allAssets);
    }
  }, [allAssets]);

  const handleCloseModal = () => {
    setToggle(null);
    setRows([{ task: "", description: "" }]);
  };

  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(userRetrieveByBranch(data));
  }, [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUser = (value, name) => {
    setSelectedId(value);
    setName(name);
    handleClose();
  };

  const handleApply = () => {
    if (userAccess?.role === "Guest" || userAccess?.role === "Admin" || userAccess?.role==="Branch_Admin") {
      let data = {
        userid: userIds?.userid,
        taskmonth: month,
        taskyear: year,
        branchid: userBranch.id,
        userrole:userAccess?.role,
        depart_ids: userAccess?.departmentids,
      };
      dispatch(getNbmTask(data));
    }
    if (
      userAccess?.role === "Owner" ||
      userAccess?.role === "Super_Admin" 
    ) {
      let data = {
        userid: selectedId,
        taskmonth: month,
        taskyear: year,
        branchid: userBranch.id,
        userrole:userAccess?.role,
        depart_ids: userAccess?.departmentids,
      };
      dispatch(getNbmTask(data));
    }
  };

  const newData = allTask?.reduce((result, item) => {
    if (item?.taskname?.length === 1 && item?.taskdesc?.length === 1) {
      result.push({
        taskdate: item.taskdate,
        tasktime: item.tasktime,
        taskname: item?.taskname[0],
        taskdesc: item?.taskdesc[0],
      });
    } else {
      item?.taskname?.forEach((taskname, index) => {
        result.push({
          taskdate: item.taskdate,
          tasktime: item.tasktime,
          taskname: taskname,
          taskdesc: item?.taskdesc[index],
        });
      });
    }
    return result;
  }, []);


  console.log("newData~~~~~~~~~~~~~~~~~~~~~>",allTask)

  useEffect(() => {
    if (
      userAccess?.role !== "Owner" ||
      userAccess?.role !== "Super_Admin" 
    ) {
      let data = {
        userid: userIds.userid,
        taskmonth: month,
        taskyear: year,
        branchid: userBranch.id,
        userrole:userAccess?.role,
        depart_ids: userAccess?.departmentids,
      };
      dispatch(getNbmTask(data));
    }
  }, [dispatch, update, toggle]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };




console.log("allAssets=======================>",allAssets)


  return (
    <Wrapper>
      
      {userAccess?.role === "Owner" || userAccess?.role === "Super_Admin" ? (
        <TopContainer>
        
            <Box sx={{  padding: "10px", bgcolor:'white', }}>
              <Button
                variant="h6"
                color="black"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                disabled={userData?.length === 0}
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  borderRadius: "36px",
                  minWidth: "150px",
                  height: "40px",
                  textTransform: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "white",
                  cursor: "pointer",
                }}>
                {name ? name : "User"}
                {!anchorEl ? <ExpandMore /> : <ExpandLess />}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  style: {
                    marginTop: "20px",
                  },
                }}>
                {userData?.map((item) => (
                  <MenuItem
                    onClick={() => handleUser(item?.userid, item?.username)}
                    button>
                    {" "}
                    {item?.username}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
      

          <TopSplitContainer>
            <FormControl size="small">
              <Select
                sx={{
                  bgcolor: "white",
                  color: "black",
                  borderRadius: "30px",
                  "& fieldset": { border: "none" },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: isMatch2 ? "12px" : "",
                    padding: isMatch2 ? "2.5px 6px" : "",
                  },
                }}
                displayEmpty
                value={month}
                onChange={handleMonth}>
                {/* <MenuItem value="">All Month</MenuItem> */}

                <MenuItem value="01">January</MenuItem>
                <MenuItem value="02">February</MenuItem>

                <MenuItem value="03">March</MenuItem>

                <MenuItem value="04">April</MenuItem>

                <MenuItem value="05">May</MenuItem>

                <MenuItem value="06">June</MenuItem>

                <MenuItem value="07">July</MenuItem>

                <MenuItem value="08">August</MenuItem>

                <MenuItem value="09">September</MenuItem>

                <MenuItem value="10">October</MenuItem>

                <MenuItem value="11">November</MenuItem>

                <MenuItem value="12">December</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small">
              <Select
                sx={{
                    bgcolor: "white",
                    color: "black",
                  borderRadius: "30px",
                  "& fieldset": { border: "none" },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: isMatch2 ? "12px" : "",
                    padding: isMatch2 ? "2.5px 6px" : "",
                  },
                }}
                displayEmpty
                value={year}
                onChange={handleYear}>
                {/* <MenuItem value="">All Year</MenuItem> */}
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2024">2024</MenuItem>
                <MenuItem value="2025">2025</MenuItem>
              </Select>
            </FormControl>

            <Button
              disabled={!selectedId || !selectedDate}
              variant="contained"
              onClick={handleApply}
              sx={{
                // bgcolor: "#FF731D",
               
                fontSize: isMatch2 ? "12px" : "",
                padding: isMatch2 ? "2.5px 6px" : "",
                borderRadius: "30px",
                textTransform: "none",
              }}>
              Apply
            </Button>
          </TopSplitContainer>
        </TopContainer>
      ) : null}

      {userAccess?.role === "Guest" ||
      userAccess?.role === "Admin" ||
      userAccess?.role === "Branch_Admin" ? (
        <UserTopContainer sx={{}}>
          <Boxes>
            <FormControl
              sx={{ width: isMatch2 ? "100px" : "120px" }}
              size="small">
              <Select
                sx={{
                  bgcolor: "white",
                  color: "black",
                  borderRadius: "30px",
                  "& fieldset": { border: "none" },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: isMatch2 ? "12px" : "",
                    padding: isMatch2 ? "2.5px 6px" : "",
                  },
                }}
                displayEmpty
                value={month}
                onChange={handleMonth}>
                {/* <MenuItem value="">All Month</MenuItem> */}

                <MenuItem value="01">January</MenuItem>
                <MenuItem value="02">February</MenuItem>

                <MenuItem value="03">March</MenuItem>

                <MenuItem value="04">April</MenuItem>

                <MenuItem value="05">May</MenuItem>

                <MenuItem value="06">June</MenuItem>

                <MenuItem value="07">July</MenuItem>

                <MenuItem value="08">August</MenuItem>

                <MenuItem value="09">September</MenuItem>

                <MenuItem value="10">October</MenuItem>

                <MenuItem value="11">November</MenuItem>

                <MenuItem value="12">December</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              size="small"
              sx={{ width: isMatch2 ? "70px" : "100px" }}>
              <Select
                sx={{
                  bgcolor: "white",
                  color: "black",
                  borderRadius: "30px",
                  "& fieldset": { border: "none" },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: isMatch2 ? "12px" : "",
                    padding: isMatch2 ? "2.5px 6px" : "",
                  },
                }}
                displayEmpty
                value={year}
                onChange={handleYear}>
                {/* <MenuItem value="">All Year</MenuItem> */}
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2024">2024</MenuItem>
                <MenuItem value="2025">2025</MenuItem>
              </Select>
            </FormControl>

            <Button
              disabled={!selectedDate}
              variant="contained"
              onClick={handleApply}
              // size={isMatch2 ? "small" : "medium"}
              sx={{
                // bgcolor: "#FF731D",
              
                fontSize: isMatch2 ? "12px" : "",
                padding: isMatch2 ? "2.5px 6px" : "",
                borderRadius: "30px",
                textTransform: "none",
              }}>
              Apply
            </Button>
          </Boxes>
          {userAccess?.role !== "Owner" &&
            userAccess?.role !== "Super_Admin" && (
              <BottomContainer sx={{}}>
                <Button
                  sx={{
                    // bgcolor: "#FF731D",
                    fontSize: isMatch2 ? "12px" : "",
                    padding: isMatch2 ? "2.5px 6px" : "",
                    borderRadius: "30px",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={handleAddRow}>
                  Add Row
                </Button>

                <Button
                  disabled={!isDataValid || rows?.length === 0}
                  sx={{
                    // bgcolor: "#FF731D",
                    fontSize: isMatch2 ? "12px" : "",
                    padding: isMatch2 ? "2.5px 6px" : "",
                    borderRadius: "30px",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={handleSubmit}>
                  Submit
                </Button>
              </BottomContainer>
            )}
        </UserTopContainer>
      ) : null}
      <BoxWrapper style={{ overflowX: "auto" }}>
        <Box sx={{ overflow: "auto" }}>
          <TableContainer
            sx={{ width: isMatch2 ? "380px" : "100%" }}
            component={Paper}>
            <Table aria-label="Task List">
              {allTask?.length > 0 && (
                <TableHead sx={{ bgcolor: "white" }}>
                  <MainRow>
                    <TableHeading sx={{ textAlign: "left", width: "150px" }}>
                     Asset Name
                    </TableHeading>
                    <TableHeading sx={{ textAlign: "center", width: "100px" }}>
                      Date/Time
                    </TableHeading>
                    <TableHeading sx={{ textAlign: "start", width: "150px" }}>
                      Task
                    </TableHeading>
                  </MainRow>
                </TableHead>
              )}

              <TableBody>
                {rows?.map((row, index) => (
                  <>
                    {userAccess?.role !== "Owner" &&
                      userAccess?.role !== "Super_Admin" && (
                        <TableRow key={index}>
                          <TableCell>
                            <Autocomplete
                              options={data?.map((option) => ({
                                label: `${option?.name} - ${option?.department} (${option?.assetcode})`,
                                value: option?.assetid,
                              }))}
                              value={row?.task}
                              onChange={(event, value) =>
                                handleTaskChange(value, index)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="e.g. Ecg/EC-109-98-10"
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex" }}>
                              <TextFieldInput
                                value={row.description}
                                multiline
                                rows={2}
                                onChange={(event) =>
                                  handleDescriptionChange(event, index)
                                }
                                label="e.g. Maintain ecg machine"
                              />

                              <IconButton
                                onClick={() => handleRemoveRow(index)}
                                aria-label="delete">
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                  </>
                ))}
                {newData
                  .reverse()
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                  ?.map((item) => (
                    <>
                      <TableRow>
                        <TableCell sx={{ textAlign: "left"  }}>
                          {item?.taskname}
                        </TableCell>
                        <TableCell
                          sx={{
                            
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}>
                          {" "}
                          {item?.taskdate}
                          <br />
                          {item?.tasktime}
                        </TableCell>

                        <TableCell
                          sx={{ textAlign: "start"  }}>
                          {item?.taskdesc}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
            {allTask?.length > rowsPerPage && (
              <TablePagination
                style={{ width: "95%" }}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={allTask?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </Box>
        {userAccess?.role === "Owner" || userAccess?.role === "Super_Admin" ? (
        <>{allTask?.length == 0 && <TaskEmpty />}</>
      ) : null}
      </BoxWrapper>

    

      <TaskModal
        openModal={toggle}
        toggle={toggle}
        handleCloseModal={handleCloseModal}
      />
    </Wrapper>
  );
}

export default TaskListPage;
