import React from "react";
import { Modal, IconButton, Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { jsPDF } from "jspdf";
import dummyImage from "../images/download.png";
import vajraImage from "../images/vajraNewLogo.jpg";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import hospitallogo from "./../images/vajralogo.jpg"; // Default fallback image

const ViewImageModal = ({
  imageUrl,
  handleClose,
  open,
  department,
  assetId,
  assestname,
  // assetLogo
}) => {
  const email = "www.vajra.software";
  const contact = "contact@vajra.software";

  const assetLogo = useSelector((store) => store?.data?.logoUrl?.logo_url);
  // const assetLogo = 'https://images.pexels.com/photos/3845129/pexels-photo-3845129.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'

  // const assetLogo = useSelector((store) => store?.data?.logoUrl?.logo_url);
  // console.log(assetLogo);

  // const handleDownload = () => {
  //   const pdf = new jsPDF("p", "mm", [130, 130]);

  //   pdf.setFontSize(14);
  //   pdf.setFont("helvetica", "bold");

  //   const centerX = pdf.internal.pageSize.width / 2;

  //   pdf.setLineWidth(0.85);
  //   pdf.setDrawColor(0, 0, 0);
  //   // Asset ID section
  //   const assetIdLabel = "Asset Name";
  //   const assetIdLabelWidth = pdf.getTextWidth(assetIdLabel);
  //   pdf.text(assetIdLabel, centerX - assetIdLabelWidth / 70, 45);

  //   pdf.setFont("helvetica", "normal");
  //   const assetIdTextWidth = pdf.getTextWidth(assestname);
  //   pdf.text(assestname, centerX - assetIdTextWidth / 70, 55); // Set Y-position clearly below the label
  //   if (assetLogo !== "NA") {
  //     pdf.addImage(assetLogo, "PNG", 70, 1, 50, 25);
  //   }

  //   pdf.addImage(imageUrl, "JPEG", 2, 20, 60, 70);

  //   // console.log("image========", imageUrl);

  //   pdf.setLineWidth(0.85);
  //   pdf.setDrawColor(0, 0, 0);
  //   pdf.line(65, 68, pdf.internal.pageSize.width - 5, 68);

  //   pdf.setFont("helvetica", "bold");
  //   const departmentLabel = "Department";
  //   const departmentLabelWidth = pdf.getTextWidth(departmentLabel);
  //   pdf.text(departmentLabel, centerX - departmentLabelWidth / 70, 75);

  //   // Set font for department
  //   pdf.setFont("helvetica", "normal");

  //   const departmentText = department;
  //   const departmentLabelWidth1 = pdf.getTextWidth(departmentText);
  //   pdf.text(departmentText, centerX - departmentLabelWidth1 / 70, 85);
  //   const maxWidth = 70; // Custom width for department section
  //   let currentY = 82;

  //   const words = departmentText.split(" ");
  //   let currentLine = "";

  //   pdf.addImage(vajraImage, "PNG", 80, 100, 50, 30);

  //   pdf.setFontSize(12);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text(email, 5, 110);
  //   pdf.text(contact, 5, 115);

  //   pdf.setLineWidth(0.85);
  //   pdf.setDrawColor(0, 0, 0);
  //   pdf.line(1, 100, 60, 100);

  //   pdf.save(`${assetId}.pdf`);
  //   handleClose();
  // };

  const handlePrint = () => {
    // Create a new iframe for printing
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.top = "-10000px";
    document.body.appendChild(iframe);

    // Access iframe document
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    const { head, body } = doc;

    // Add styles (optional, to make the print look nice)
    const style = document.createElement("style");
    style.textContent = `
      body { font-family: Helvetica, Arial, sans-serif; }
      .section { margin: 10px 0; }
      img { max-width: 100%; height: auto; }
      .bold { font-weight: bold; }
    `;
    head.appendChild(style);

    // Create content for printing
    body.innerHTML = `
  <div style="width:auto">
    <div style="text-align: end;">
      ${
        assetLogo !== "NA"
          ? `<img src="${assetLogo}" style="width: 200px; height: 100px;" />`
          : ""
      }
    </div>
<div style=" display: flex; justify-content: space-between; align-items: center;">
      <div>
        <img src="${imageUrl}" style="width: 250px; height: 250px;" />
      </div>
      <div style="width:50%; text-align: center;">
        <div>
          <h2>Asset Name</h2>
          <p>${assestname}</p>
        </div>
        <hr style="border: 1px solid black;">

        <div>
          <h2>Department</h2>
          <p>${department}</p>
        </div>
      </div>
      
    </div>
    <div style="width:40%";>
       <hr style="border: 1px solid black;">

      </div>
<div style=" text-align: center; display: flex; justify-content: space-between; align-items: center;">
        <div style="width:40%"; >
          <h4>Email: ${email}</h4>
          <h4>Contact: ${contact}</h4>
        </div>
        <div style="width:30%; text-align: center;">
          <img src="${vajraImage}" style="width: 150px; height: 100px;" />
        </div>
      </div>
  </div>
`;

    // Trigger the print dialog
    iframe.contentWindow.print();

    // Remove the iframe after printing
    iframe.remove();
  };

  const printImage = (imageSrc) => {
    if (imageSrc) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<img src="${imageSrc}" onload="window.print();window.close()" />`
      );
      printWindow.document.close();
    } else {
      // console.log("Image not downloaded yet.");
    }
  };

  const handleQRCodeDownload = () => {
    if (!imageUrl) {
      console.error("Image URL is not provided.");
      return;
    }

    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = `${assetId}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="400px"
        height="auto"
        sx={{ background: "white" }}
        padding="20px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Box textAlign="center">
            <img
              src={imageUrl}
              alt="QR Code"
              style={{ width: "130px", height: "130px" }}
            />
            <hr />
            <Typography>{email}</Typography>
            <Typography>{contact}</Typography>
          </Box>

          <Box textAlign="center" sx={{ width: "40%" }}>
            {assetLogo === "NA" || assetLogo === undefined ? (
              <PersonIcon sx={{ fontSize: "50px" }} />
            ) : (
              <img
                src={assetLogo}
                width="100px"
                alt="Hospital Logo"
                height="50px"
              />
            )}
            <Typography sx={{ fontWeight: "bold" }}>Asset Name</Typography>
            <Typography>{assestname}</Typography>
            <hr />
            <Typography sx={{ fontWeight: "bold" }}>Department</Typography>
            <Typography>{department}</Typography>
            <img src={vajraImage} width="80px" alt="Vajra Logo" />
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          gap="20px"
          sx={{ marginTop: "15px" }}
        >
          <Button onClick={handlePrint} variant="contained">
            Print
          </Button>
          <Button onClick={handleQRCodeDownload} variant="contained">
            Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewImageModal;
