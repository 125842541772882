


import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Checkbox,
  styled,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { calender } from "../redux/nbmData/action";
import CalibModal from "./CalibModal";
import WarrantyModals from "./WarrantyModals";
import AmccmcModal from "./AmccmcModal";
import ExpiryModalPage from "./ExpiryModalPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalenderTaskComponent from "../nbmComponents/CalenderTaskComponent";
import CalenderExpiryComponent from "../nbmComponents/CalenderExpiryComponent";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap:"40px",
  alignItems: "start",

  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    display:"block",
    flexDirection: "colum-reverse",
  },
  [theme.breakpoints.down("sm")]: {display:"block"},
  [theme.breakpoints.down("xs")]: {display:"block"},
}));

const RightBox = styled(Box)(({ theme }) => ({
  // padding: "10px",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // display: "none",
  }, 
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopMobileSet = styled(Box)(({ theme }) => ({
  // padding: "10px",
  display: "none",
  flexDirection: "column",
  backgroundColor: "white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleTop = styled(Box)(({ theme }) => ({
  height: "70px",
  padding: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleFont = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Calendar = () => {
  const warrantyResponse = useSelector((store) => store.data.warrantyRequest);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const calibrationResponse = useSelector((store) => store.data.calibrationReq);
  const amccmcResponse = useSelector((store) => store.data.amcCmcReqData);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const calenderData = useSelector((store) => store.data?.calenderData);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [calibrationModal, setCalibrationModal] = useState([]);
  const [warrantyModal, setWarrantyModal] = useState([]);
  const [CMCAMCModal, setCMCAMCModal] = useState([]);
  const [oldWarrantyModal, setOldWarrantyModal] = useState([]);
  const [oldCalibrationModal, setOldCalibrationModal] = useState([]);
  const [oldAmcCmcModal, setOldAmcCmcModal] = useState([]);
  const [calibrationModalOpen, setCalibrationModalOpen] = useState(false);
  const [WarrantyModalOpen, setWarrantyModalOpen] = useState(false);
  const [CMCAMCModalOpen, setCMCAMCModalOpen] = useState(false);
  const [oldWarrantyModalOpen, setOldWarrantyModalOpen] = useState(false);
  const [oldCalibrationModalOpen, setOldCalibrationModalOpen] = useState(false);
  const [oldAmcCmcModalOpen, setOldAmcCmcModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [warranties, setWarranties] = useState([]);
  const [calibs, setCalibs] = useState([]);
  const [amcCmcs, setAmcCmcs] = useState([]);
  const [warrantiesOld, setWarrantiesOld] = useState([]);
  const [calibrationOld, setCalibrationOld] = useState([]);
  const [amcCmcOld, setAmcCmcOld] = useState([]);
  const [flag, setFlag] = useState(false);
  const [selectedMonthValue, setSelectedMonthValue] = useState(null);
  const [selectedYearValue, setSelectedYearValue] = useState(
    date.toLocaleString("default", {
      year: "numeric",
    })
  );
  const [isDataOpen, setIsDataOPen] = useState(false);
  const [isExpiryOpen, setIsExpiryOPen] = useState(false);

  useEffect(() => {
    if (calenderData) {
      setWarranties(calenderData.warranties);
      setCalibs(calenderData.calibs);
      setAmcCmcs(calenderData.amc_cmcs);
      setWarrantiesOld(calenderData.old_warranties);
      setAmcCmcOld(calenderData.old_amc_cmcs);
      setCalibrationOld(calenderData.old_calibs);
    }
  }, [calenderData]);

  const [selectedEventTypes, setSelectedEventTypes] = useState({
    Calibration: true,
    Warranty: true,
    AMC_CMC: true,
    Warranty_exp: true,
    Calibration_exp: true,
    AMC_CMC_exp: true,
  });
  const [isMonthYearModalOpen, setMonthYearModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
      setIsTablet(window.innerWidth > 700 && window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const eventButtonStyles = {
    width: isMobile ? "60px" : isTablet ? "80px" : "90px",
    padding: isMobile ? "5px 10px" : isTablet ? "8px 12px" : "10px 20px",
    backgroundColor: "#6100FF",
    color: "white",
    borderRadius: isMobile || isTablet ? "5px" : "10px",
    fontSize: isMobile ? "10px" : isTablet ? "14px" : "16px",
  };

  if (isMobile) {
    eventButtonStyles.width = "60px";
    eventButtonStyles.padding = "5px 10px";
  } else {
    eventButtonStyles.width = "90px";
    eventButtonStyles.padding = "10px 20px";
  }

  const handleCheckboxChange = (event, eventType) => {
    if (eventType === "Calibration") {
      setSelectedEventTypes((prevTypes) => ({
        ...prevTypes,
        Calibration: event.target.checked,
      }));
    } else if (eventType === "Warranty") {
      setSelectedEventTypes((prevTypes) => ({
        ...prevTypes,
        Warranty: event.target.checked,
      }));
    } else if (eventType === "AMC_CMC") {
      setSelectedEventTypes((prevTypes) => ({
        ...prevTypes,
        AMC_CMC: event.target.checked,
      }));
    } else if (eventType === "Warranty_exp") {
      setSelectedEventTypes((prevTypes) => ({
        ...prevTypes,
        Warranty_exp: event.target.checked,
      }));
    } else if (eventType === "Calibration_exp") {
      setSelectedEventTypes((prevTypes) => ({
        ...prevTypes,
        Calibration_exp: event.target.checked,
      }));
    } else if (eventType === "AMC_CMC_exp") {
      setSelectedEventTypes((prevTypes) => ({
        ...prevTypes,
        AMC_CMC_exp: event.target.checked,
      }));
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();

  // const years = Array.from(
  //   { length: 10 },
  //   (_, index) => currentYear + index - 2
  // );

  const startYear = 2010;
  const offset = currentYear - startYear;

  const years = Array.from(
    { length: 20 },
    (_, index) => currentYear + index - offset
  );

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,

      month: selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1,
      depart_ids: userAccess.departmentids,
      branchid: userBranch?.id,
      year: selectedYear,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };
    dispatch(calender(data));
  }, [
    dispatch,
    selectedMonth,
    selectedYear,
    warrantyResponse,
    calibrationResponse,
    amccmcResponse,
  ]);

  const getDaysInMonth = () => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };

  const handleDateClick = (event) => {
    const clickedDate = event.target.textContent;
    const clickedEvents = calibs.filter((event) => {
      const [day, month, year] = event.calib_due_date.split("-").map(Number);
      const eventDate = new Date(year, month - 1, day).getDate();
      return eventDate === parseInt(clickedDate, 10);
    });

    const clickedNewEvents = warranties.filter((event) => {
      const [day, month, year] = event.warranty_exp_date.split("-").map(Number);
      const eventDate = new Date(year, month - 1, day).getDate();
      return eventDate === parseInt(clickedDate, 10);
    });

    if (clickedEvents.length > 0) {
      setCalibrationModal(clickedEvents[0]);
    } else if (clickedNewEvents.length > 0) {
      setWarrantyModal(clickedNewEvents[0]);
    }
  };

  const handlePreviousMonth = () => {
    const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    setSelectedMonth(prevMonth.getMonth());
    setSelectedYear(prevMonth.getFullYear());
    setDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setSelectedMonth(nextMonth.getMonth());
    setSelectedYear(nextMonth.getFullYear());
    setDate(nextMonth);
  };


  const [selectedEventData, setSelectedEventData] = useState([]);
  const [selectedExpiryData, setSelectedExpiryData] = useState([]);

  const [taskTitle, setTaskTitle] = useState("");

  const handleUpdateEventData = (updatedData) => {
    setSelectedEventData(updatedData);
  };

  const handleEventButtonClick = (clickedEvent) => {
    if (!clickedEvent) return;

    const clickedDate = clickedEvent.date;
    let eventsOnClickedDate = [];

    if (clickedEvent.type === "Calibration") {
      eventsOnClickedDate = calibs.filter((event) => {
        const [day, month, year] = event.calib_due_date.split("-").map(Number);
        const eventDate = new Date(year, month - 1, day);
        setTaskTitle("Calibration");
        return eventDate.toDateString() === clickedDate.toDateString();
      });
    } else if (clickedEvent.type === "Warranty") {
      eventsOnClickedDate = warranties.filter((event) => {
        const [day, month, year] = event.warranty_exp_date
          .split("-")
          .map(Number);
        const eventDate = new Date(year, month - 1, day);
        setTaskTitle("Warranty");
        return eventDate.toDateString() === clickedDate.toDateString();
      });
    } else if (clickedEvent.type === "AMC_CMC") {
      eventsOnClickedDate = amcCmcs.filter((event) => {
        const [day, month, year] = event.cmc_amc_due_date
          .split("-")
          .map(Number);
        const eventDate = new Date(year, month - 1, day);
        setTaskTitle("AMC/CMC");
        return eventDate.toDateString() === clickedDate.toDateString();
      });
    } else if (clickedEvent.type === "Warranty_exp") {
      eventsOnClickedDate = warrantiesOld.filter((event) => {
        const [day, month, year] = event.old_expdate.split("-").map(Number);
        const eventDate = new Date(year, month - 1, day);
        setTaskTitle("Warranty Expired");
        return eventDate.toDateString() === clickedDate.toDateString();
      });
    } else if (clickedEvent.type === "Calibration_exp") {
      eventsOnClickedDate = calibrationOld.filter((event) => {
        const [day, month, year] = event.old_expdate.split("-").map(Number);
        const eventDate = new Date(year, month - 1, day);
        setTaskTitle("Calibration Expired");
        return eventDate.toDateString() === clickedDate.toDateString();
      });
    } else if (clickedEvent.type === "AMC_CMC_exp") {
      eventsOnClickedDate = amcCmcOld.filter((event) => {
        const [day, month, year] = event.old_expdate.split("-").map(Number);
        const eventDate = new Date(year, month - 1, day);
        setTaskTitle("AMC/CMC Expired");
        return eventDate.toDateString() === clickedDate.toDateString();
      });
    }

    if (clickedEvent.type.includes("_exp")) {
      setSelectedExpiryData(eventsOnClickedDate);
      setIsExpiryOPen(true); 
      setIsDataOPen(false); 
    } else {
      setSelectedEventData(eventsOnClickedDate);
      setIsDataOPen(true); 
      setIsExpiryOPen(false); 
    }

    if (eventsOnClickedDate.length > 0) {
      // Open the corresponding modal or perform other actions
    }
  };

  // useEffect(() => {
  //   setSelectedEventData(selectedEventData);
  // }, [selectedEventData]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const renderEventCheckboxes = () => {
    return (
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: isMobile ? "flex" : "flex",
            justifyContent: "start",
            alignContent: "start",
            width: isMobile ? "auto" : isTablet ? "100%" : "100%",
           fontSize: isMobile ? "8px" : isTablet ? "14px" : "16px"
          }}
        >
          <Box sx={{ textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ marginRight: "20px", color: "#6100FF" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Calibration}
                onChange={(event) => handleCheckboxChange(event, "Calibration")}
                sx={{
                  color: " #6100FF",
                  "&.Mui-checked": {
                    color: " #6100FF",
                  },
                }}
              />
              Calibration
            </label>
          </Box>
          <Box sx={{ textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ marginRight: "20px", color: "#FF4B4B" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Warranty}
                onChange={(event) => handleCheckboxChange(event, "Warranty")}
                sx={{
                  color: " #FF4B4B",
                  "&.Mui-checked": {
                    color: " #FF4B4B",
                  },
                }}
              />
              Warranty
            </label>
          </Box>
          <Box sx={{ width: "33%", textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ marginRight: "20px", color: "#4BD4FF" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.AMC_CMC}
                onChange={(event) => handleCheckboxChange(event, "AMC_CMC")}
                sx={{
                  color: " #4BD4FF",
                  "&.Mui-checked": {
                    color: " #4BD4FF",
                  },
                }}
              />
              AMC/CMC
            </label>
          </Box>
        </Box>
        <Box
          sx={{
            display: isMobile ? "flex" : "flex",
            justifyContent: "start",
            alignContent: "start",
            width: isMobile ? "320px" : isTablet ? "100%" : "100%",
            fontSize: isMobile ? "8px" : isTablet ? "14px" : "16px"
          }}
        >
          <Box
            sx={{
              width: "33%",
              textAlign: "start",

              whiteSpace: "nowrap",
            }}
          >
            <label style={{ marginRight: "20px", color: "#00A843" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Calibration_exp}
                onChange={(event) =>
                  handleCheckboxChange(event, "Calibration_exp")
                }
                sx={{
                  color: " #00A843",

                  "&.Mui-checked": {
                    color: " #00A843",
                  },
                }}
              />
              Calibration exp
            </label>
          </Box>
          <Box
            sx={{
              width: "33%",
              textAlign: "start",

              whiteSpace: "nowrap",
            }}
          >
            <label style={{ marginRight: "20px", color: "#A005FF" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Warranty_exp}
                onChange={(event) =>
                  handleCheckboxChange(event, "Warranty_exp")
                }
                sx={{
                  color: " #A005FF",
                  "&.Mui-checked": {
                    color: " #A005FF",
                  },
                }}
              />
              Warranty exp
            </label>
          </Box>
          <Box sx={{ width: "33%", textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ color: "#FF974B" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.AMC_CMC_exp}
                onChange={(event) => handleCheckboxChange(event, "AMC_CMC_exp")}
                sx={{
                  color: " #FF974B",
                  "&.Mui-checked": {
                    color: " #FF974B",
                  },
                }}
              />
              AMC/CMC exp
            </label>
          </Box>
        </Box>
      </div>
    );
  };
  const generateCalendar = () => {
    const daysInMonth = getDaysInMonth();
    const firstDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).getDay();
    const calendar = [];

    const cmcamcDates = amcCmcs?.map((event) => {
      const [day, month, year] = event.cmc_amc_due_date.split("-").map(Number);
      return { date: new Date(year, month - 1, day), type: "AMC_CMC", event };
    });

    const cmcamcDatesOld = amcCmcOld?.map((event) => {
      const [day, month, year] = event.old_expdate.split("-").map(Number);
      return {
        date: new Date(year, month - 1, day),
        type: "AMC_CMC_exp",
        event,
      };
    });

    const newEventDates = warranties?.map((event) => {
      const [day, month, year] = event.warranty_exp_date.split("-").map(Number);
      return { date: new Date(year, month - 1, day), type: "Warranty", event };
    });

    const newEventDatesOld = warrantiesOld?.map((event) => {
      const [day, month, year] = event.old_expdate?.split("-").map(Number);
      return {
        date: new Date(year, month - 1, day),
        type: "Warranty_exp",
        event,
      };
    });

    const eventDates = calibs?.map((event) => {
      const [day, month, year] = event.calib_due_date.split("-").map(Number);
      return {
        date: new Date(year, month - 1, day),
        type: "Calibration",
        event,
      };
    });

    const eventDatesOld = calibrationOld?.map((event) => {
      const [day, month, year] = event.old_expdate.split("-").map(Number);
      return {
        date: new Date(year, month - 1, day),
        type: "Calibration_exp",
        event,
      };
    });

    let dayCounter = 1;
    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        const currentDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          dayCounter
        );

        const eventDate = eventDates?.find(
          (item) =>
            item && item.date.toDateString() === currentDate.toDateString()
        );

        const eventDateOld = eventDatesOld?.find(
          (item) =>
            item && item.date.toDateString() === currentDate.toDateString()
        );

        const newEventDate = newEventDates?.find(
          (item) =>
            item && item.date.toDateString() === currentDate.toDateString()
        );

        const newEventDateOld = newEventDatesOld?.find(
          (item) =>
            item && item.date.toDateString() === currentDate.toDateString()
        );

        const cmcamcDate = cmcamcDates?.find(
          (item) =>
            item && item.date.toDateString() === currentDate.toDateString()
        );

        const cmcamcDateOld = cmcamcDatesOld?.find(
          (item) =>
            item && item.date.toDateString() === currentDate.toDateString()
        );

        const eventCount = eventDates?.filter(
          (item) => item.date.toDateString() === currentDate.toDateString()
        ).length;
        const eventCountOld = eventDatesOld?.filter(
          (item) => item.date.toDateString() === currentDate.toDateString()
        ).length;
        const newEventCount = newEventDates?.filter(
          (item) => item.date.toDateString() === currentDate.toDateString()
        ).length;

        const newEventCountOld = newEventDatesOld?.filter(
          (item) => item.date.toDateString() === currentDate.toDateString()
        ).length;
        const cmcAmcCount = cmcamcDates?.filter(
          (item) => item.date.toDateString() === currentDate.toDateString()
        ).length;
        const cmcAmcCountOld = cmcamcDatesOld?.filter(
          (item) => item.date.toDateString() === currentDate.toDateString()
        ).length;
        const isCalibrationEvent =
          eventDate &&
          eventDate.type === "Calibration" &&
          selectedEventTypes.Calibration;

        const isCalibrationEventOld =
          eventDateOld &&
          eventDateOld.type === "Calibration_exp" &&
          selectedEventTypes.Calibration_exp;

        const isWarrantyEvent =
          newEventDate &&
          newEventDate.type === "Warranty" &&
          selectedEventTypes.Warranty;

        const isWarrantyEventOld =
          newEventDateOld &&
          newEventDateOld.type === "Warranty_exp" &&
          selectedEventTypes.Warranty_exp;

        const isAmcCmcEvent =
          cmcamcDate &&
          cmcamcDate.type === "AMC_CMC" &&
          selectedEventTypes.AMC_CMC;

        const isAmcCmcEventOld =
          cmcamcDateOld &&
          cmcamcDateOld.type === "AMC_CMC_exp" &&
          selectedEventTypes.AMC_CMC_exp;

        const showCalibrationButton =
          isCalibrationEvent && selectedEventTypes.Calibration;
        const showCalibrationExpButton =
          isCalibrationEventOld && selectedEventTypes.Calibration_exp;
        const showWarrantyButton =
          isWarrantyEvent && selectedEventTypes.Warranty;

        const showWarrantyExpButton =
          isWarrantyEventOld && selectedEventTypes.Warranty_exp;
        const showAmcCmcButton = isAmcCmcEvent && selectedEventTypes.AMC_CMC;
        const showAmcCmcExpButton =
          isAmcCmcEventOld && selectedEventTypes.AMC_CMC_exp;

        const cellStyle = {
          backgroundColor: "white",
          color: "black",
          width: "110px",
          height: isMobile ? "85px" : "100px",
          cursor: "default",
          fontSize: isMobile ? "24px" : "26px",
          border: "1px solid #BCBCBC",
          position: "relative",
          overflow: "hidden",
          
        };

        if ((i === 0 && j < firstDayOfMonth) || dayCounter > daysInMonth) {
          week.push(<td key={j} style={cellStyle}></td>);
        } else {
          week.push(
            <td
              key={j}
              style={cellStyle}
              onClick={
                isCalibrationEvent ||
                isWarrantyEvent ||
                isAmcCmcEvent ||
                isWarrantyEventOld
                  ? handleDateClick
                  : null
              }
            >
              <Box style={{ height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    sx={{
                      width: "30px",
                      height: "20px",
                      // background: "#00A884",
                      borderRadius: "0px 0px 0px 15px ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      fontSize: isMobile ? "10px" : "14px",
                    }}
                  >
                    {" "}
                    {dayCounter}
                  </Typography>
                </div>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    marginLeft: "2%",

                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",

                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {eventCount > 0 && showCalibrationButton && (
                      <Box
                        size="small"
                        style={{
                          backgroundColor: "#6100FF",
                          color: "white",
                          borderRadius: "10px",

                          textTransform: "none",

                          width: "90%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleEventButtonClick(
                            eventDates.find(
                              (item) =>
                                item.date.toDateString() ===
                                currentDate.toDateString()
                            )
                          )
                        }
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: isMobile ? "5px" : "10px",
                            cursor: "pointer",
                          }}
                        >
                          {isMobile
                            ? `Calibration ${eventCount}`
                            : isTablet
                            ? `Calibration ${eventCount}`
                            : `Calibration - ${eventCount}`}
                        </Typography>
                      </Box>
                    )}

                    {newEventCount > 0 && showWarrantyButton && (
                      <Box
                        size="small"
                        style={{
                          backgroundColor: "#FF4B4B",
                          color: "white",
                          borderRadius: "10px",

                          width: "90%",
                          textTransform: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleEventButtonClick(
                            newEventDates.find(
                              (item) =>
                                item.date.toDateString() ===
                                currentDate.toDateString()
                            )
                          )
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: isMobile ? "5px" : "10px",

                            cursor: "pointer",
                          }}
                          variant="caption"
                        >
                          {isMobile
                            ? `Warranty ${newEventCount}`
                            : isTablet
                            ? `Warranty ${newEventCount}`
                            : `Warranty - ${newEventCount}`}
                        </Typography>
                      </Box>
                    )}

                    {cmcAmcCount > 0 && showAmcCmcButton && (
                      <Box
                        size="small"
                        style={{
                          backgroundColor: "#4BD4FF",
                          color: "white",
                          borderRadius: "10px",

                          width: "90%",

                          textTransform: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleEventButtonClick(
                            cmcamcDates.find(
                              (item) =>
                                item.date.toDateString() ===
                                currentDate.toDateString()
                            )
                          )
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: isMobile ? "5px" : "10px",
                            cursor: "pointer",
                          }}
                          variant="caption"
                        >
                          {isMobile
                            ? `AMC/CMC ${cmcAmcCount}`
                            : isTablet
                            ? `AMC/CMC ${cmcAmcCount}`
                            : `AMC/CMC - ${cmcAmcCount}`}
                        </Typography>
                      </Box>
                    )}

                    {eventCountOld > 0 && showCalibrationExpButton && (
                      <Box
                        size="small"
                        style={{
                          backgroundColor: "#00A843",
                          color: "white",
                          borderRadius: "10px",

                          textTransform: "none",

                          width: "90%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleEventButtonClick(
                            eventDatesOld.find(
                              (item) =>
                                item.date.toDateString() ===
                                currentDate.toDateString()
                            )
                          )
                        }
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: isMobile ? "5px" : "10px",
                            cursor: "pointer",
                            textDecoration: "line-through",
                          }}
                        >
                          {isMobile
                            ? `Calibration ${eventCountOld}`
                            : isTablet
                            ? `Calibration ${eventCountOld}`
                            : `Calibration - ${eventCountOld}`}
                        </Typography>
                      </Box>
                    )}

                    {newEventCountOld > 0 && showWarrantyExpButton && (
                      <Box
                        size="small"
                        style={{
                          backgroundColor: "#A005FF",
                          color: "white",
                          borderRadius: "10px",

                          width: "90%",
                          textTransform: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleEventButtonClick(
                            newEventDatesOld.find(
                              (item) =>
                                item.date.toDateString() ===
                                currentDate.toDateString()
                            )
                          )
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: isMobile ? "5px" : "10px",
                            textDecoration: "line-through",
                            cursor: "pointer",
                          }}
                          variant="caption"
                        >
                          {isMobile
                            ? `Warranty ${newEventCountOld}`
                            : isTablet
                            ? `Warranty ${newEventCountOld}`
                            : `Warranty - ${newEventCountOld}`}
                        </Typography>
                      </Box>
                    )}
                    {cmcAmcCountOld > 0 && showAmcCmcExpButton && (
                      <Box
                        size="small"
                        style={{
                          backgroundColor: "#FF974B",
                          color: "white",
                          borderRadius: "10px",

                          width: "90%",

                          textTransform: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleEventButtonClick(
                            cmcamcDatesOld.find(
                              (item) =>
                                item.date.toDateString() ===
                                currentDate.toDateString()
                            )
                          )
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: isMobile ? "5px" : "10px",
                            cursor: "pointer",
                            textDecoration: "line-through",
                          }}
                          variant="caption"
                        >
                          {isMobile
                            ? `AMC/CMC ${cmcAmcCountOld}`
                            : isTablet
                            ? `AMC/CMC ${cmcAmcCountOld}`
                            : `AMC/CMC - ${cmcAmcCountOld}`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </td>
          );
          dayCounter++;

          
        }
      }
      calendar.push(<tr key={i}>{week}</tr>);
      if (dayCounter > daysInMonth) {
        break;
      }
    }

    return calendar;
  };

  // console.log(calenderData);

  return (
    <>
      <Wrapper
        sx={{
          padding: isMobile ? "0px" : "0px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexDirection: "column", display: "flex", gap: "10px" }}>
            {
              <TopMobileSet>
                <TitleTop>
                  <TitleFont>Task</TitleFont>
                </TitleTop>

                <Divider
                  sx={{
                    backgroundColor: "#dddddd",
                    width: "100%",
                  }}
                />

                {renderEventCheckboxes()}
                {selectedEventData.length > 0 && (
                  <CalenderTaskComponent
                    eventData={selectedEventData}
                    taskTitle={taskTitle}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    selectedEventData={selectedEventData}
                  />
                )}
              </TopMobileSet>
            }
            <Box
              className="header"
              style={{
                display: "flex",
                flexDirection: "row",
                background: "white",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <IconButton
                  onClick={handlePreviousMonth}
                  sx={{ color: "black" }}
                >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton>
                <Typography
                  sx={{
                    color: "black",
                    cursor: "pointer",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => setMonthYearModalOpen(true)}
                >
                  {date.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  })}
                  <ExpandMoreIcon style={{}} />
                </Typography>

                <Modal
                  open={isMonthYearModalOpen}
                  // open={true}
                  onClose={() => {
                    setMonthYearModalOpen(false);
                    setSelectedMonthValue(null);
                    setSelectedYearValue(null);
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",

                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // width: 300,
                      bgcolor: "white",
                      borderRadius: "0px",
                      height: 150,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      color: "white",
                      border: "3px solid red",
                    }}
                  >
                    <Box>
                      <label>
                        <select
                          value={selectedMonthValue}
                          style={{
                            height: "50px",

                            fontSize: "16px",
                            width: "auto",
                            color: "black",

                            outline: "none",
                            border: "none",
                            marginRight: "1%",
                          }}
                          onChange={(e) =>
                            setSelectedMonthValue(parseInt(e.target.value))
                          }
                        >
                          {months.map((month, index) => (
                            <option
                              key={index}
                              value={index}
                              selected={index === selectedMonth}
                            >
                              {month}
                            </option>
                          ))}
                        </select>
                      </label>
                      <label>
                        <select
                          value={selectedYearValue}
                          style={{
                            height: "50px",

                            fontSize: "16px",
                            width: "auto",
                            color: "black",

                            outline: "none",
                            border: "none",

                            marginRight: "1%",
                          }}
                          onChange={(e) =>
                            setSelectedYearValue(parseInt(e.target.value))
                          }
                        >
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </label>
                    </Box>

                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        sx={{
                          textTransform: "none",
                        }}
                        variant="contained"
                        // onClick={() => {
                        //   setMonthYearModalOpen(false);
                        //   setDate(new Date(selectedYear, selectedMonth, 1));
                        // }}

                        onClick={() => {
                          // If selectedMonthValue is provided, use it; otherwise, use the current month
                          const updatedMonth =
                            selectedMonthValue !== null
                              ? selectedMonthValue
                              : new Date().getMonth();
                          setSelectedMonth(updatedMonth);

                          // If selectedYearValue is provided, use it; otherwise, use the current year
                          const updatedYear =
                            selectedYearValue !== null
                              ? selectedYearValue
                              : new Date().getFullYear();
                          setSelectedYear(updatedYear);

                          setDate(new Date(updatedYear, updatedMonth, 1));
                          setMonthYearModalOpen(false);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </Box>
                </Modal>
                <IconButton onClick={handleNextMonth} sx={{ color: "black" }}>
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              </Box>
              <Button>Task</Button>
            </Box>

            <table
              style={{
                width: "100%",
                // borderSpacing: 0,
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      background: "white",
                      width: "100px",
                      height: "50px",
                      border: "1px solid #dddddd",
                    }}
                  >
                    Sun
                  </th>
                  <th
                    style={{
                      background: "white",
                      width: "100px",
                      height: "50px",
                      border: "1px solid #dddddd",
                    }}
                  >
                    Mon
                  </th>
                  <th
                    style={{
                      background: "white",
                      width: "100px",
                      height: "50px",
                      border: "1px solid #dddddd",
                    }}
                  >
                    Tue
                  </th>
                  <th
                    style={{
                      background: "white",
                      width: "100px",
                      height: "50px",
                      border: "1px solid #dddddd",
                    }}
                  >
                    Wed
                  </th>
                  <th
                    style={{
                      background: "white",
                      width: "100px",
                      height: "50px",
                      border: "1px solid #dddddd",
                    }}
                  >
                    Thu
                  </th>
                  <th
                    style={{
                      background: "white",
                      width: "100px",
                      height: "50px",
                      border: "1px solid #dddddd",
                    }}
                  >
                    Fri
                  </th>
                  <th
                    style={{
                      background: "white",
                      width: "100px",
                      height: "50px",
                      border: "1px solid #dddddd",
                    }}
                  >
                    Sat
                  </th>
                </tr>
              </thead>
              <tbody>{generateCalendar()}</tbody>
            </table>
          </Box>
        </Box>

        <RightBox sx={{width:{xs: "auto", 
            sm: "auto", 
            md: "55%",lg: "35%",}}}>
          <TitleTop sx={{ display:{xs:"none",md:"flex"}, alignItems:"start"}}>
            <TitleFont sx={{fontSize:"14px"}}>Task  </TitleFont>
          </TitleTop>
          <Divider
            sx={{
              backgroundColor: "#dddddd",
              width: "100%",
              
            }}
          />
          {/* {renderEventCheckboxes()} */}
          <div
        style={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "start",
          padding:"10px"
        }}
      >
        <Box
          sx={{
            display: {
              xs: "none", 
              sm: "none",
              md: "flex",
            },
            justifyContent: "start",
            alignContent: "start",
            width: isMobile ? "auto" : isTablet ? "100%" : "100%",
           fontSize: isMobile ? "8px" : isTablet ? "14px" : "16px"
          }}
        >
          <Box sx={{ textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ marginRight: "20px", color: "#6100FF" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Calibration}
                onChange={(event) => handleCheckboxChange(event, "Calibration")}
                sx={{
                  color: " #6100FF",
                  "&.Mui-checked": {
                    color: " #6100FF",
                  },
                }}
              />
              Calibration
            </label>
          </Box>
          <Box sx={{ textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ marginRight: "20px", color: "#FF4B4B" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Warranty}
                onChange={(event) => handleCheckboxChange(event, "Warranty")}
                sx={{
                  color: " #FF4B4B",
                  "&.Mui-checked": {
                    color: " #FF4B4B",
                  },
                }}
              />
              Warranty
            </label>
          </Box>
          <Box sx={{ width: "33%", textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ marginRight: "20px", color: "#4BD4FF" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.AMC_CMC}
                onChange={(event) => handleCheckboxChange(event, "AMC_CMC")}
                sx={{
                  color: " #4BD4FF",
                  "&.Mui-checked": {
                    color: " #4BD4FF",
                  },
                }}
              />
              AMC/CMC
            </label>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none", 
              sm: "none",
              md: "flex",
            },
            justifyContent: "start",
            alignContent: "start",
            width: isMobile ? "320px" : isTablet ? "100%" : "100%",
            fontSize: isMobile ? "8px" : isTablet ? "14px" : "14px"
          }}
        >
          <Box
            sx={{
              width: "33%",
              textAlign: "start",

              whiteSpace: "nowrap",
            }}
          >
            <label style={{ marginRight: "20px", color: "#00A843" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Calibration_exp}
                onChange={(event) =>
                  handleCheckboxChange(event, "Calibration_exp")
                }
                sx={{
                  color: " #00A843",

                  "&.Mui-checked": {
                    color: " #00A843",
                  },
                }}
              />
              Calibration exp
            </label>
          </Box>
          <Box
            sx={{
              width: "33%",
              textAlign: "start",

              whiteSpace: "nowrap",
            }}
          >
            <label style={{ marginRight: "20px", color: "#A005FF" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.Warranty_exp}
                onChange={(event) =>
                  handleCheckboxChange(event, "Warranty_exp")
                }
                sx={{
                  color: " #A005FF",
                  "&.Mui-checked": {
                    color: " #A005FF",
                  },
                }}
              />
              Warranty exp
            </label>
          </Box>
          <Box sx={{ width: "33%", textAlign: "start", whiteSpace: "nowrap" }}>
            <label style={{ color: "#FF974B" }}>
              <Checkbox
                {...label}
                defaultChecked
                checked={selectedEventTypes.AMC_CMC_exp}
                onChange={(event) => handleCheckboxChange(event, "AMC_CMC_exp")}
                sx={{
                  color: " #FF974B",
                  "&.Mui-checked": {
                    color: " #FF974B",
                  },
                }}
              />
              AMC/CMC exp
            </label>
          </Box>
        </Box>
      </div>
          <Divider />
         
          {selectedEventData.length > 0 && isDataOpen && (
            <CalenderTaskComponent 
              eventData={selectedEventData}
              taskTitle={taskTitle}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              selectedEventData={selectedEventData}
              onUpdateEventData={handleUpdateEventData}
              isDataOpen={isDataOpen}
              setIsDataOPen={setIsDataOPen}
            />
          )}

          {selectedExpiryData.length > 0 && isExpiryOpen && (
            <CalenderExpiryComponent dataModal={selectedExpiryData} taskTitle={taskTitle}/>
          )}
        </RightBox>
      </Wrapper>
      {calibrationModal.length > 0 && (
        <CalibModal
          calibrationModal={calibrationModal}
          setCalibrationModalOpen={setCalibrationModalOpen}
          calibrationModalOpen={calibrationModalOpen}
          setCalibrationModal={setCalibrationModal}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      )}

      {warrantyModal.length > 0 && (
        <WarrantyModals
          sx={{ outline: 0 }}
          warrantyModal={warrantyModal}
          setWarrantyModalOpen={setWarrantyModalOpen}
          WarrantyModalOpen={WarrantyModalOpen}
          setWarrantyModal={setWarrantyModal}
          flag={flag}
          setFlag={setFlag}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      )}

      {CMCAMCModal.length > 0 && (
        <AmccmcModal
          CMCAMCModal={CMCAMCModal}
          setCMCAMCModalOpen={setCMCAMCModalOpen}
          CMCAMCModalOpen={CMCAMCModalOpen}
          setCMCAMCModal={setCMCAMCModal}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      )}

      {oldWarrantyModal.length > 0 && (
        <ExpiryModalPage
          dataModal={oldWarrantyModal}
          setDataModalOpen={setOldWarrantyModalOpen}
          dataModalOpen={oldWarrantyModalOpen}
          setDataModal={setOldWarrantyModal}
          text="Warranty Expired" 
          color="#A005FF"
        />
      )}

      {oldCalibrationModal.length > 0 && (
        <ExpiryModalPage
          dataModal={oldCalibrationModal}
          setDataModalOpen={setOldCalibrationModalOpen}
          dataModalOpen={oldCalibrationModalOpen}
          setDataModal={setOldCalibrationModal}
          text="Calibration Expired"
          color=" #00A843"
        />
      )}

      {oldAmcCmcModal.length > 0 && (
        <ExpiryModalPage
          dataModal={oldAmcCmcModal}
          setDataModalOpen={setOldAmcCmcModalOpen}
          dataModalOpen={oldAmcCmcModalOpen}
          setDataModal={setOldAmcCmcModal}
          text="AMC/CMC Expired"
          color="#FF974B"
        />
      )}
    </>
  );
};

export default Calendar;

