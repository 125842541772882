import React, { useState } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useRef } from "react";

import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "auto",
  bgcolor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
    bgcolor: "rgba(244, 246, 246, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  "& fieldset": { border: "none" },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const WorkOrderPage = () => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const getAllData = {
    assets: [
      { name: "Asset 1", assetcode: "A001" },
      { name: "Asset 2", assetcode: "A002" },
      { name: "Asset 3", assetcode: "A003" },
    ],
  };

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenBox = () => {
    setOpen(true);
  };

  const handleCloseBox = () => {
    setOpen(false);
  };

  const handleAssetName = (event, value) => {
    setSelectedAsset(value);
    console.log("Selected Asset:", value);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleImageCancel = () => {
    setSelectedImage(null);
  };

  const HospitalLeftDataOpen = {
    "Asset Name": "ASSET",
    "Asset Code": "CODE",
    Department: "DEPART",
    "Serial No.": "SERIAL Num",
    "Modal No.": "Modal Num",
    Branch: "Brnach Nam",
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px"
    >
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Work Order.
            {/* <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span> */}
          </Label>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
          height="auto"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Typography>Total :</Typography>
            <Typography sx={{ color: "#4690FF" }}>
              {/* {` ${data?.length}`} */}
            </Typography>
          </Stack>
          <Box flex="2">
            {/* <SortSearchFilter
              data={""}
              setData={""}
              filteredData={""}
              setFilteredData={""}
              heading={"My Orders"}
              Filter1="dept"
              Filter2="serv_status"
              FilterName1="Department"
              FilterName2="Status"
              sortDate="servdate"
              dueDate=""
              sortPrice=""
              name="assetname"
              csvName="All Assets"
            /> */}
          </Box>
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Button
              onClick={handleOpenBox}
              sx={{
                background: "rgba(70, 144, 255, 1)",
                color: "white",
                "&:hover": {
                  background: "rgba(70, 144, 255, 1)", // Keep the same background on hover
                  color: "white", // Keep the same text color on hover
                },
              }}
            >
              Create Work Order <AddIcon />
            </Button>
          </Stack>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            {/* {filteredData.length > 0 ? ( */}
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    "&:hover": {
                      backgroundColor: "rgba(255, 237, 237, 1)",
                    },
                  }}
                >
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>

                  <TableHeading>Department</TableHeading>

                  <TableHeading>Date & Time</TableHeading>

                  <TableHeading
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Status
                  </TableHeading>
                  <TableHeading>Ack'ment</TableHeading>
                  <TableHeading>User</TableHeading>
                  <TableHeading>Reaction Time</TableHeading>
                </TableRow>
              </TableHead>

              <TableBody style={{ overflowY: "scroll" }}>
                <TableRow
                  sx={{
                    "&:hover": {
                      bgcolor: "#EEF5FF",
                    },
                    textDecoration: "none",
                  }}
                >
                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>name</Tooltip>
                  </TableCells>

                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>code</Tooltip>
                  </TableCells>

                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>dept</Tooltip>
                  </TableCells>

                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>06/01/2025 & 10:37</Tooltip>
                  </TableCells>
                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>Pending</Tooltip>
                  </TableCells>
                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>Acklo</Tooltip>
                  </TableCells>
                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>Raj</Tooltip>
                  </TableCells>
                  <TableCells
                    sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                  >
                    <Tooltip>1h:20min:25sec</Tooltip>
                  </TableCells>
                </TableRow>
                {/* {filteredData.map((item, index) => (
                    <TableRow
                      key={item?.servid}
                      sx={{
                        "&:hover": {
                          bgcolor: "#EEF5FF",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.assetname}>
                          {item.assetname}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.dept}>{item.dept}</Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        {item.servdate === item?.servtime ? (
                          <>NA</>
                        ) : (
                          <>
                            <>{item?.servdate}</>@<>{item?.servtime}</>
                          </>
                        )}
                      </TableCells>

                      <TableCells
                        sx={{
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center",
                          display: "ruby-text",
                          color: "rgba(70, 144, 255, 1)",
                        }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                    
                      >
                        {item?.serv_status === "Open" ? (
                          <>
                            <Box
                              disabled={userAccess?.role === "Guest"}
                              size="small"
                              sx={{
                                borderRadius: " 0px 8px 0px 8px",
                                textTransform: "none",
                                width: "90px",
                                height: "25px",
                                bgcolor: "#74ACFF",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              variant="contained"
                            >
                              Open
                            </Box>
                          </>
                        ) : (
                          <>Cleared</>
                        )}
                      </TableCells>

                    </TableRow>
                  ))} */}
              </TableBody>
            </Table>
            {/* ) : (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                No Work order data found.
              </Typography>
            )} */}
          </TableContainer>
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            bgcolor: "white",
            padding: "20px",
            height: "100%",
            overflow: "hidden",
            overflowY: "scroll",
          }}
          width="40%"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}
          >
            <Label variant="h6" style={{ color: "#4690FF" }}>
              Create Work Order
            </Label>
            <CloseIcon
              size={15}
              style={{
                color: " rgba(255, 58, 68, 1)",
                cursor: "pointer",
              }}
              onClick={handleCloseBox}
            />
          </Box>
          <Divider />

          <Stack
            sx={{
              width: "100%",
              gap: "20px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                width: "100%",
                gap: "20px",
                textAlign: "start",
              }}
            >
              <Label>Asset Details</Label>
            </Box>
            <Stack>
              <Autocomplete
                id="asset-name-dropdown"
                value={selectedAsset}
                onChange={handleAssetName}
                options={
                  getAllData?.assets?.map(
                    (option) => `${option.name} - ${option.assetcode}`
                  ) || []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Asset Name"
                    sx={{
                      width: "300px",
                      background: "rgba(244, 246, 246, 1)",
                      "& fieldset": { border: "none" },
                    }}
                  />
                )}
              />
            </Stack>
            <Box sx={{ width: "100%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(HospitalLeftDataOpen).map(
                      ([key, value]) => (
                        <TableRow key={key}>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                              padding: "2px",
                            }}
                          >
                            {key}
                          </TableCells>

                          <TableCells sx={{ padding: "2px" }}>
                            {value}
                          </TableCells>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "5px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  style={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Contact Details
                </Label>
                <Divider />
              </Stack>
              <Stack sx={{ gap: "5px" }}>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <label style={{ justifyContent: "left", display: "flex" }}>
                    NAME:
                  </label>
                  <FormInput
                    id="filled-basic"
                    label="Name"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {/* Error Message Here */}
                  </FormHelperText>
                </Stack>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <label style={{ justifyContent: "left", display: "flex" }}>
                    Mobile:
                  </label>

                  <FormInput
                    id="filled-basic"
                    label="Mobile "
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {/* Error Message Here */}
                  </FormHelperText>
                </Stack>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <label style={{ justifyContent: "left", display: "flex" }}>
                    Email:
                  </label>

                  <FormInput
                    id="filled-basic"
                    label="Email"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {/* Error Message Here */}
                  </FormHelperText>
                </Stack>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "5px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Description
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>

              <Stack>
                <TextField
                  multiline
                  rows={1}
                  placeholder="Describe the issue here..."
                  variant="outlined"
                  sx={{
                    width: "auto",
                    background: "rgba(244, 246, 246, 1)",
                    "& fieldset": { border: "none" },
                  }}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {/* Error Message Here */}
                </FormHelperText>
              </Stack>

              <Stack
                sx={{
                  marginTop: "5px",
                  paddingLeft: "10px",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Upload Image
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {selectedImage && (
                    <>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <img
                          src={selectedImage}
                          alt="Preview"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            border: "1px solid black",
                          }}
                        />
                        <Box
                          sx={{
                            position: "relative",
                            bottom: "50px",
                            right: "35px",
                          }}
                        >
                          <IconButton
                            aria-label="close"
                            onClick={handleImageCancel}
                            color="error"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Stack>
                    </>
                  )}
                  <Stack sx={{ alignItems: "end" }}>
                    <input
                      accept="image/*"
                      type="file"
                      id="image-upload"
                      style={{ display: "none" }}
                      onChange={(e) => handleImageUpload(e)}
                    />
                    <label htmlFor="image-upload">
                      <IconButton color="primary" component="span">
                        <FileUploadIcon />
                      </IconButton>
                    </label>
                  </Stack>
                </Stack>
                <FormHelperText sx={{ color: "red" }}>
                  {/* Error Message Here */}
                </FormHelperText>
              </Stack>
            </Box>
            <Stack
              sx={{
                position: "relative",
                bottom: "30px",
                justifyContent: "space-between",
              }}
            >
              <Button variant="contained">Submit</Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default WorkOrderPage;
