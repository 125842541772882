import {
  Autocomplete,
  Button,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  departmentAlreadySaved,
  postDepartment,
  postDepartmentName,
} from "../redux/nbmData/action";
import SuccessModal from "../components/SuccessModal";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
  width: "100%",
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const AddDepartmentPage = ({ handleCloseDepart }) => {
  const data = useSelector((store) => store.data.incharge);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const response = useSelector((store) => store.data.departmentName);
  const allDepartment = useSelector((store) => store.data.alreadySaved);
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const dispatch = useDispatch();

  const [inCharge, setInCharge] = useState("");

  const [departName, setDepartName] = useState("");
  const [description, setDescription] = useState("");
  const [inchargeuserId, setInchargeUserId] = useState("");

  const [departNameError, setDepartNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const text = "Department added";

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    setDepartName("");
    setDescription("");
    setInCharge("");
  };

  useEffect(() => {
    let data = {
      hosp_id: ids.hospid,
      userid: ids.userid,
      branchid: userBranch?.id,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };

    dispatch(postDepartment(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userrole:userAccess?.role,
    };
    dispatch(departmentAlreadySaved(data));
  }, [dispatch]);

  const handleDepartment = (e) => {
    let value = e.target.value;
    setDepartName(value);

    const isDepartmentExist = allDepartment?.some(
      (department) =>
        department.departname.toLowerCase() === value.toLowerCase().trim()
    );

    if (!value.trim()) {
      setDepartNameError("Department is required.");
    } else if (isDepartmentExist) {
      setDepartNameError("Department already exist.");
    } else if (value.length < 3) {
      setDepartNameError("Please enter valid department.");
    } else {
      setDepartNameError("");
    }
  };

  const handleIncharge = (e, newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setInCharge(newValue.name);
      setInchargeUserId(newValue.id);
    } else {
      setInCharge("");
      setInchargeUserId("");
    }
  };
  const handleDescription = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);
  
    if (!inputValue) {
      setDescriptionError("Description is required");
    } else if (inputValue.includes("'")) {
      setDescriptionError("Single quote (') are not allowed");
    } else {
      setDescriptionError("");  // No errors if validation passes
    }
  };

  const handleSubmit = () => {
    // Validate departName
    if (departName === "") {
      setDepartNameError("Please fill the field");
    } else {
      setDepartNameError(""); 
    }
  
    // Ensure descriptionError is handled through the handleDescription function
    if (description === "") {
      setDescriptionError("Description is required");
    }
  
    // Prevent submission if any errors are present
    if (departNameError || descriptionError || departName === "" || description === "") {
      return;  // Exit the function early if there's an error
    }
  
    // If no errors, proceed with form submission
    let data = {
      hospid: ids?.hospid,
      username: ids?.username,
      userid: ids?.userid,
      hospname: ids.hospname,
      department: departName.trim(),
      departdesc: description === "" ? "NA" : description.trim(),
      departincharge: inCharge === "" ? "NA" : inCharge,
      inchargeuserid: inchargeuserId === "" ? "NA" : inchargeuserId,
      branchid: userBranch.id,
      branchname: userBranch.branch,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };
  
    setOpenModal(true);
    setToggle(true);
    dispatch(postDepartmentName(data, token));
  };

  useEffect(() => {
    if (response === "Inserted" && departName !== "") {
      setOpenModal(true);
    }
  }, [response]);
  const handelClose = () => {
    setDepartName("");
    setDescription("");
    setInCharge("");
    setInchargeUserId();
  };
  return (
    <>
      <Stack
        sx={{
          width: {
            sm: "100%",
            md: "40%",
            lg: "40%",
          },
          gap: "10px",
          height: "100%",
        }}
      >
        <Stack
          flexDirection={"row"}
          height="50px"
          
          justifyContent={"space-between"}
          alignItems={"center"}
          padding="16px"
          gap={"10px"}
          sx={{
            bgcolor: "white",
            boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            // width: "100%",
            width: {
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
          }}
        >
          <Typography style={{ color: "rgba(70, 144, 255, 1)" }}>
            Add New Department
          </Typography>
          <IconButton color="primary" onClick={handleCloseDepart}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack
          sx={{
            bgcolor: "white",
            alignItems: "start",
            padding: "15px",

            width: {
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
            gap: "10px",
            textAlign: "start",
            justifyContent: "start",

            // height: "100%",
          }}
        >
          <Typography>Details</Typography>
          <Stack
            sx={{
              width: "100%",
              background: "rgba(70, 144, 255, 1)",
              margin: "5px 0",
            }}
          >
            <Divider />
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Field
              value={departName}
              onChange={handleDepartment}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon sx={{ color: "#1746A2" }} />
                </InputAdornment>
              }
              placeholder="Department Name"
            />
            {<HelperText>{departNameError}</HelperText>}
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Autocomplete
              value={{
                name: inCharge,
                id: inchargeuserId,
              }}
              sx={{ bgcolor: "#EFF5FE" }}
              options={data?.map((option) => ({
                name: option.username,
                id: option?.userid,
              }))}
              getOptionLabel={(option) => option.name}
              onChange={handleIncharge}
              renderInput={(params) => (
                <Field placeholder="Select Incharge" {...params} />
              )}
            />
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Field
              sx={{
                height: "100%",
                overflow: "auto",
                resize: "vertical",
                "& fieldset": { border: "none" },
                "& input::placeholder": {
                  verticalAlign: "top",
                },
                "& textarea": {
                  padding: "8px",
                  lineHeight: "1.5",
                },
              }}
              id="standard-basic"
              multiline
              maxRows={10}
              placeholder="Description"
              value={description}
              onChange={handleDescription}
            />
             {descriptionError && (
              <HelperText sx={{ color: "red" }}>{descriptionError}</HelperText>
            )}
          </Stack>
          <Stack
            sx={{
              width: "100%",
              background: "rgba(70, 144, 255, 1)",
              margin: "5px 0",
            }}
          >
            <Divider />
          </Stack>
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "4%",
            }}
          >
            <Button
              onClick={handleSubmit}
              disabled={userAccess?.adddepart !== "Yes"}
              variant="contained"
              style={{ width: "100px", borderRadius: "22px" }}
            >
              {" "}
              Submit
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <SuccessModal
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        response={response}
        text={text}
        toggle={toggle}
      />
    </>
  );
};

export default AddDepartmentPage;
