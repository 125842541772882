import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import EastIcon from "@mui/icons-material/East";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import dummyImage from "../images/download.png";
import vajraImage from "../images/vajraNewLogo.jpg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { jsPDF } from "jspdf";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Pagination } from "@mui/material";
import { useRef } from "react";

import {
  IconButton,
  Stack,
  Typography,
  styled,
  Tooltip,
  Checkbox,
  Button,
  Modal,
  Divider,
} from "@mui/material";
import { useState } from "react";
import {
  bulkAssetDelete,
  getNewAssets,
  retrieveAsset,
  setNavigationSource,
} from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import BulkUploadAssetPopUp from "./BulkUploadAssetPopUp";
import BulkDeleteDeleteModal from "../components/BulkDeleteModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Calculate from "@mui/icons-material/Calculate";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  whiteSpace: "nowrap",
}));

const MiniQRset = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AllAsset = ({ handleDataChange }) => {
  const allAssets = useSelector((store) => store.data.retrieveAsset);
  const dataCount = useSelector((store) => store.data.allAssetsNew);
  const [allCounts, setAllCounts] = useState({});

  const deletedStatus = useSelector((store) => store.data.bulkAssetDelete);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const deleted = useSelector((store) => store.data.deleteAsset);
  const detailData = useSelector((store) => store.data.assetDetails);

  const [filteredData, setFilteredData] = useState([]);

  const [toggle, setToggle] = useState(false);

  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);

  const [filterValue, setFilterValue] = useState(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const assetLogo = useSelector((store) => store?.data?.logoUrl?.logo_url);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleSelectAllClick = () => {
    const allAssetIds = data
      ?.filter((item) => item.check_in_status !== "checked_out") // Exclude checked-out assets
      .map((item) => item.assetid);

    if (selectAll) {
      setSelectedAssetIds([]); // Clear selection
    } else {
      setSelectedAssetIds(allAssetIds); // Select only available assets
    }

    setSelectAll(!selectAll); // Toggle select all state
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedAssetIds, assetId];
    } else {
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
    }

    setSelectedAssetIds(newSelected);
  };

  useEffect(() => {
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  }, [filterValue]);

  const handleOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids || "",
      userrole: userAccess?.role,
    };

    dispatch(retrieveAsset(data, token));
    setToggle(false);
  }, [dispatch, deleted, toggle, deletedStatus]);

  const handleButtonClick = () => {
    navigate("/depreciation");
  };
  const handleBulkDelete = () => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetids: selectedAssetIds,
      branchid: userBranch.id,
      username: ids.username,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    // Dispatch the bulk delete action
    dispatch(bulkAssetDelete(data));

    // Update the filteredData state after deletion
    setFilteredData((prevData) =>
      prevData.filter((item) => !selectedAssetIds.includes(item.assetid))
    );

    // Reset the toggle and close modal
    setToggle(true);
    setOpenBulkDeleteModal(false);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids || "",
      userrole: userAccess?.role,
    };

    dispatch(getNewAssets(data));
  }, []);

  useEffect(() => {
    if (Object.keys(dataCount)?.length > 0) {
      setData(dataCount?.assets);
      let data = {
        department: dataCount?.department_count || 0,
        checkout: dataCount?.checkout_count || 0,
        movements: dataCount?.movement_count || 0,
      };
      setAllCounts(data);
    }
  }, [dataCount]);

  useEffect(() => {
    if (handleDataChange !== undefined) {
      let data2 = {
        department: allCounts?.department,
        asset: filteredData?.length,
        movement: allCounts?.movements,
        checkout: allCounts?.checkout,
      };
      handleDataChange(data2);
    }
  }, [filteredData, allCounts]);

  const ModalBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    background: "white",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
    height: "200px",
    borderRadius: "6px",
  }));

  const handleAssetAddModal = () => {
    if (ids?.subs_plan === "Basic" && data?.length > 50) {
      navigate("/"); // Redirect to homepage
    }
    if (allCounts?.department === 0) {
      handleOpenModal();
    } else {
      navigate("/add-asset");
    }
  };

  const goToDepartment = () => {
    dispatch(setNavigationSource("assets"));
    navigate("/department-list");
  };

  const email = "www.vajra.software";
  const contact = "contact@vajra.software";

  const handleDownloadQrCode = (item) => {
    const qrImage = item?.qr_img;

    const link = document.createElement("a");
    link.href = qrImage;
    link.download = `${item?.name || "asset-details"}.png`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const uniqueDepartments = data
    ? [...new Set(data.map((item) => item.department))]
    : [];
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const handleDepartmentChange = (newValue) => {
    setSelectedDepartment(newValue);

    if (newValue) {
      const filtered = data.filter((item) => item.department === newValue);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const isGuest = userAccess && userAccess.role === "Guest";

  const handleDeleteList = () => {
    navigate("/deleted-assetslist");
  };

  const handleDiscardList = () => {
    navigate("/discard-list");
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        gap="10px"
        height="90vh"
        padding="16px"
        marginTop="-15px"
      >
        <Box display="flex" justifyContent="end">
          <Button
            onClick={handleButtonClick}
            variant="contained"
            sx={{
              borderRadius: "22px",
              background: "rgba(70, 144, 255, 1)",
            }}
          >
            Depreciation <Calculate sx={{ marginLeft: "10px" }} />
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
          sx={{ display: { xs: "none", sm: "none", lg: "flex" } }}
        >
          <Box flex="1">
            <Stack
              bgcolor="lightblue"
              height="100px"
              justifyContent={"start"}
              alignItems={"start"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {allCounts?.department}
              </Label>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Label>Departments</Label>
                <IconButton onClick={goToDepartment}>
                  <EastIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Box>

          <Box flex="1">
            <Stack
              bgcolor="lightblue"
              height="100px"
              justifyContent={"start"}
              alignItems={"start"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {allCounts?.checkout}
              </Label>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Label>Check Outs</Label>
                <IconButton onClick={() => navigate("/check-in")}>
                  <EastIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
          <Box flex="1">
            <Stack
              bgcolor="lightblue"
              height="100px"
              justifyContent={"start"}
              alignItems={"start"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {allCounts?.movements}
              </Label>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Label>Asset movements</Label>
                <IconButton onClick={() => navigate("/asset-history-list")}>
                  <EastIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="10px">
          <Stack
            justifyContent="space-between"
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ display: { xs: "flex", sm: "none", lg: "none" } }}
          >
            <Label variant="h6">
              Assets.
              <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
            </Label>
            {selectedAssetIds?.length > 0 && (
              <IconButton
                onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
                sx={{
                  bgcolor: "#1B2535",
                  width: "27px ",
                  height: "27px",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                }}
              >
                <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
              </IconButton>
            )}
            <Stack sx={{ flexDirection: "row" }}>
              <Button
                onClick={handleAssetAddModal}
                sx={{
                  bgcolor: "#1B2535",
                  width: "auto ",
                  height: "27px",
                  textDecoration: "none",
                  borderRadius: "20px",
                  borderRight: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                }}
              >
                <AddIcon style={{ color: "white", fontSize: "20px" }} />
              </Button>
              <Button
                onClick={handleDiscardList}
                sx={{
                  bgcolor: "#1B2535",
                  width: "auto ",
                  height: "27px",
                  textDecoration: "none",
                  borderRadius: "20px",
                  borderRight: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                }}
              >
                Discard
              </Button>
              <Button
                onClick={handleDeleteList}
                sx={{
                  bgcolor: "#1B2535",
                  height: "27px",
                  textAlign: "center",
                  textDecoration: "none",
                  borderRight: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },

                  borderRadius: "20px",
                  fontSize: {
                    xs: "0.75rem",
                    sm: "8px",
                    md: "0.75rem",
                    lg: "0.75rem",
                    xl: "0.75rem",
                  },
                }}
              >
                <DeleteIcon
                  style={{
                    color: "white",
                    fontSize: {
                      xs: "0.75rem",
                      sm: "8px",
                      md: "0.75rem",
                      lg: "0.75rem",
                      xl: "0.75rem",
                    },
                  }}
                />
                Assets
              </Button>
            </Stack>
          </Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px"
          >
            <Stack
              flexDirection={"row"}
              height="50px"
              width="15%"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="10px"
              gap={"5px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                display: { xs: "none", sm: "none", lg: "flex" },
              }}
            >
              <Autocomplete
                options={uniqueDepartments}
                value={selectedDepartment}
                onChange={(event, newValue) => {
                  handleDepartmentChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Department"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <span
                            style={{ marginLeft: "8px", cursor: "default" }}
                          >
                            <ArrowDropDownIcon />
                          </span>
                        </>
                      ),
                      style: {
                        paddingRight: 0,
                      },
                    }}
                    sx={{
                      bgcolor: "transparent",
                      "& .MuiInputBase-root": {
                        background: "transparent",
                        border: "none",
                      },
                      "&:before, &:after": {
                        border: "none",
                      },
                    }}
                  />
                )}
                freeSolo
                sx={{
                  width: "100%",
                  "& .MuiAutocomplete-clearIndicator": {
                    backgroundColor: "white",
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "lightgray",
                    },
                  },
                }}
              />
            </Stack>
            <Box flex="2" width={"250px"}>
              <SortSearchFilter
                data={data}
                setData={setData}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                heading={"My Orders"}
                Filter1="department"
                Filter2="cmcamc"
                FilterName1="Department"
                FilterName2="AMC/CMC Status"
                sortDate=""
                sortPrice="price"
                dueDate=""
                name="name"
                csvName="All Assets"
              />
            </Box>

            <Box
              sx={{
                flex: 1,
                width: "100%",
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-around"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedAssetIds?.length > 0 && (
                  <IconButton
                    onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
                    sx={{
                      bgcolor: "#1B2535",
                      width: "27px ",
                      height: "27px",
                      "&:hover": {
                        backgroundColor: "#1B2535",
                        color: "white",
                      },
                    }}
                  >
                    <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
                  </IconButton>
                )}

                <Button
                  onClick={handleAssetAddModal}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "100px ",
                    height: "27px",
                    textDecoration: "none",
                    borderRight: "20px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                    borderRadius: "20px",
                    fontSize: {
                      xs: "0.75rem",
                      sm: "8px",
                      md: "0.75rem",
                      lg: "0.75rem",
                      xl: "0.75rem",
                    },
                  }}
                >
                  Add Asset{" "}
                  <AddIcon
                    style={{
                      color: "white",
                      fontSize: {
                        xs: "0.75rem",
                        sm: "8px",
                        md: "0.75rem",
                        lg: "0.75rem",
                        xl: "0.75rem",
                      },
                    }}
                  />
                </Button>

                {ids?.subs_plan !== "Basic" && <BulkUploadAssetPopUp />}

                <Button
                  onClick={handleDeleteList}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "120px ",
                    height: "27px",
                    textAlign: "center",
                    textDecoration: "none",
                    borderRight: "20px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },

                    borderRadius: "20px",
                    fontSize: {
                      xs: "0.75rem",
                      sm: "8px",
                      md: "0.75rem",
                      lg: "0.75rem",
                      xl: "0.75rem",
                    },
                  }}
                >
                  Deleted Asset{" "}
                  <DeleteIcon
                    style={{
                      color: "white",
                      fontSize: {
                        xs: "0.75rem",
                        sm: "8px",
                        md: "0.75rem",
                        lg: "0.75rem",
                        xl: "0.75rem",
                      },
                    }}
                  />
                </Button>

                <Button
                  onClick={handleDiscardList}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "auto ",
                    height: "27px",
                    textDecoration: "none",
                    borderRadius: "20px",
                    borderRight: "20px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                  }}
                >
                  Discard List
                </Button>
              </Stack>
            </Box>
          </Box>

          <Box flex="1" overflow="auto">
            {/* Table */}
            <TableContainer
              component={Paper}
              style={{
                height: "calc(80vh - 200px)",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                maxHeight: "500px",
                overflowY: "auto",
              }}
              ref={tableRef}
            >
              {currentData.length > 0 ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableHeading>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {selectedAssetIds.length > 0 &&
                            selectedAssetIds.length}
                          <Checkbox
                            color="primary"
                            indeterminate={
                              selectedAssetIds.length > 0 &&
                              selectedAssetIds.length <
                                filteredData?.filter(
                                  (item) =>
                                    item.check_in_status !== "checked_out"
                                ).length
                            }
                            checked={
                              selectedAssetIds.length ===
                              filteredData?.filter(
                                (item) => item.check_in_status !== "checked_out"
                              ).length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{
                              "aria-label": "select all assets",
                            }}
                          />
                        </div>
                      </TableHeading>
                      <TableHeading>Asset Name</TableHeading>
                      <TableHeading>Asset Code</TableHeading>
                      <TableHeading>Serial Number</TableHeading>
                      <TableHeading>Brand</TableHeading>
                      <TableHeading>Department</TableHeading>
                      <TableHeading>Status</TableHeading>
                      <TableHeading>Warranty expiry</TableHeading>
                      <TableHeading>Price</TableHeading>

                      <TableHeading>QR Code</TableHeading>
                      <TableHeading></TableHeading>
                    </TableRow>
                  </TableHead>

                  <TableBody style={{ overflowY: "scroll" }}>
                    {currentData.map((item, index) => (
                      <TableRow key={item.assetid}>
                        <TableCells>
                          <Checkbox
                            checked={selectedAssetIds.includes(item.assetid)}
                            disabled={item.check_in_status === "checked_out"}
                            onChange={() => handleSelectRowClick(item.assetid)}
                            onClick={(event) => {
                              if (item.check_in_status === "checked_out") {
                                event.stopPropagation();
                                showModal(
                                  `The asset ${item.assetid} is not available in your hospital.`
                                );
                              }
                            }}
                          />
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          <Tooltip title={item.name}>{item.name}</Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          <Tooltip title={item.assetcode}>
                            {item.assetcode}
                          </Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          <Tooltip title={item.serialno}>
                            {item.serialno}
                          </Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          <Tooltip title={item.brand}>{item.brand}</Tooltip>
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          <Tooltip title={item.department}>
                            {item.department}
                          </Tooltip>
                        </TableCells>

                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                          sx={{
                            // textAlign: "center",
                            color: "rgba(70, 144, 255, 1)",
                          }}
                        >
                          {item?.status !== "Working" ? (
                            <Box
                              size="small"
                              sx={{
                                borderRadius: " 0px 8px 0px 8px",
                                textTransform: "none",
                                width: "90px",
                                height: "25px",
                                bgcolor: "#74ACFF",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              variant="contained"
                            >
                              {item.status}
                            </Box>
                          ) : (
                            <>{item.status}</>
                          )}
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          {item.warranty_expdate === "Invalid date"
                            ? ""
                            : item.warranty_expdate}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          <Tooltip title={item.price}>{item.price}</Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{
                            textOverflow: "clip",
                            whiteSpace: "normal",
                            width: "20px",
                          }}
                        >
                          <MiniQRset
                            onClick={() => handleDownloadQrCode(item)}
                            as="img"
                            src={item?.qr_img}
                          />
                        </TableCells>
                        <TableCells
                          sx={{
                            textOverflow: "clip",
                            whiteSpace: "normal",
                            width: "20px",
                          }}
                        >
                          {item.status !== "Discard" && (
                            <EditIcon
                              disabled={userAccess?.editprod !== "Yes"}
                              onClick={() =>
                                navigate(`/edit-asset/${item.assetid}`)
                              }
                            />
                          )}
                        </TableCells>
                      </TableRow>
                    ))}
                    {/* {console.log(filteredData)} */}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  No data found on the searched text.
                </Typography>
              )}
            </TableContainer>
            <Stack
              sx={{
                padding: "20px",
                bgcolor: "white",
                gap: "20px",
              }}
            >
              <Divider />
              <Stack
                sx={{
                  bgcolor: "white",
                  alignItems: "center",

                  justifyContent: "end",
                  flexDirection: "row",
                }}
              >
                <Pagination
                  count={Math.ceil(filteredData.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                ></Pagination>
                {/* <Button
                  onClick={handleButtonClick}
                  variant="contained"
                  sx={{
                    borderRadius: "22px",
                    background: "rgba(70, 144, 255, 1)",
                  }}
                >
                  Depreciation <Calculate sx={{ marginLeft: "10px" }} />
                </Button> */}
              </Stack>
            </Stack>
          </Box>
        </Box>
        <BulkDeleteDeleteModal
          open={openBulkDeleteModal}
          onClose={handleCloseBulkDeleteModal}
          onDelete={handleBulkDelete}
          text={
            selectedAssetIds?.length > 1
              ? `Are you sure you want to delete the selected assets?`
              : `Are you sure you want to delete the selected asset?`
          }
          count={selectedAssetIds?.length}
        />
      </Box>

      {/* Modal Secttion */}

      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <ModalBox>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}
          >
            <IconButton onClick={handleCloseModal}>
              <CloseIcon sx={{ fill: "#000" }} />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
              position: "absolute",
              top: "50px",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              Please add the department before adding an asset.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
              onClick={() => navigate("/department-list")}
            >
              Add Department
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </>
  );
};

export default AllAsset;
