import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  Divider,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentIcon from "@mui/icons-material/Assignment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CloseIcon from '@mui/icons-material/Close';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LogoutIcon from '@mui/icons-material/Logout';
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import SettingsIcon from "@mui/icons-material/Settings";
import SmsIcon from "@mui/icons-material/Sms";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import ChecklistIcon from '@mui/icons-material/Checklist';
import { useDispatch } from "react-redux";
import { logOut } from "../redux/data/action";
import vajra from "../images/vajra.png";



const BranchSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  background: "white",
}));

const PreviousContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "center",
}));
const SidebarItem = styled(ListItem)(({ $selected }) => ({
  // backgroundColor: $selected ? "black" : "white",
  color: $selected ? "rgba(70, 144, 255, 1)" : "black",
  borderRadius: "22px",
  height: "33px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const IconWrapper = styled(ListItemIcon)(({ $selected }) => ({
  color: $selected ? "rgba(70, 144, 255, 1)" : "black",
  minWidth: "43px",
}));
const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  background: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "330px",
}));

const BottomSet = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  //  justifyContent:'center',
  paddingLeft: '25px',
  alignItems: 'center',

  [theme.breakpoints.down("xl")]: {

  },
  [theme.breakpoints.down("lg")]: {

  },
  [theme.breakpoints.down("md")]: {

    paddingTop: '20px',
    gap: '50px',

  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CustomButton = styled(Button)(({ theme }) => ({
  color: 'black',
  border: '1px solid black',
  borderRadius: '30px',
  [theme.breakpoints.down("xl")]: {

  },
  [theme.breakpoints.down("lg")]: {

  },
  [theme.breakpoints.down("md")]: {



  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PrivateNavbar = ({ handleToggle, toggle }) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("");
  const [dropdownOpen, setDropdownOpen] = React.useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  useEffect(() => {
    const storedItem = localStorage.getItem("selectedItem");
    if (storedItem) {
      setSelectedItem(storedItem);
    }
  }, []);

  const handleListItemClick = (itemName) => {
    setDrawerOpen(false)
    setSelectedItem(itemName);
    localStorage.setItem("selectedItem", itemName);
  };

  const handleDropdown = (listName) => {
    setDropdownOpen((prev) => (prev === listName ? "" : listName));
  };

  const routeNameMap = {
    "/": "Biomedical Dasboard",
    "/home": "Biomedical Dasboard",
    "/profile": "Profile",
    "/edit-profile": "Edit Profile",
    "/report": "Reports",
    "/user": "Users ",
    "/incident-list": "Incidents ",
    "/incident-list/:id": "Incident Detail",
    "/adduser": "Add User",
    "/add-asset": "Add Asset",
    "/asset-detail/:id": "Asset Details Check",
    "/user-manual": "User Manual ",
    "/department": "Main",
    "/calibration": "Callibration",
    "/not-calibrated": "Not Calibrated",
    "/calibration-not-required": "Calibrate Not Required",
    "/add-dep": "Add Department",
    "/editDepart": "Edit Department",
    "/all-assets": "Assets",
    "/service-request/:id": "Service Request",
    "/service-others/:id": "Service Other",
    "/closed-service": "Close Service",
    "/closed-incident": "Closed Incident",
    "/open-incident": "Open Incident",
    "/open-service": "Open Services",
    "/service-list": "Services",
    "/clear-service/:id": "Clear Service",
    "/edit-asset/:id": "Edit Asset",
    "/contact": "Contact",
    "/pm-checklist": "Pm Checklist",
    "/pm-checklist/:id": "Pm Check List",
    "/depreciation": "Depreciation Calculator",
    "/clear-incident/:id": "Clear Incident",
    "/record-incident/:id": "Recorder Incident",
    "/department-asset-details/:id": "Department Asset Details",
    "/department-asset-delete-details/:id": "Department Asset Delete",
    "/warranty-status/:id": "Warranty Status",
    "/bulk-upload-asset": "Bulk Upload Asset",
    "/bulk-upload-department": "Bulk Upload Department",
    "/amc-cmc": "Amc Cmc",
    "/amc": "Amc",
    "/cmc": "Cmc",
    "/plans": "Plans",
    "/asset-history": "Asset History",
    "/asset-history-list": "Asset History ",
    "/task": "Task",
    "/vendor-relations/:id": "Vendor Relations",
    "/checkin-checkout/:id": "Checkin Checkout",
    "/checkin-checkout-details/:id": "Checkin Checkout Detail",
    "/calendar": "Calender",
    "/notification": "Notification Tab",
    "/manual-details/:id": "User Manual Details",
    "/scan": "Qr Code Scanner",
    "/department-list": "Department ",
    "/vendor": "Vendor ",
    "/service-company": "Service Company ",
    "/check-in": "Check In",
    "/request": "Requests",
    "/check-out": "Check Out",
  };

  //---------------------------------------------------000000-----------------------------------------------------------------//
  const location = useLocation();
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [selectedBranch, setSelectedBranch] = useState(userBranch);
  const [nbmChecker, setNbmChecker] = useState([]);
  const truncatedUsername = userIds.username
    ? userIds.username.slice(0, 2).toUpperCase()
    : "";
  const [heading, setHeading] = useState("");

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const token = localStorage.getItem("userToken");

  const path = location?.pathname;
  const dispatch = useDispatch();

  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const branchesAndIds = userIds?.bm_branches?.map((branchName, index) => ({
    branch: branchName,
    id: userIds?.bm_branchids[index],
  }));

  const handleChange = () => {
    setSelectedBranch({
      branch: userIds?.nbm_branches[0],
      id: userIds?.nbm_branchids[0],
    });

    let branchData = {
      branch: userIds?.nbm_branches[0],
      id: userIds?.nbm_branchids[0],
    };

    if (nbmChecker?.length > 0) {
      let branchData = {
        branch: nbmChecker[0]?.nbm_branch,
        id: nbmChecker[0]?.nbm_branchid,
      };

      localStorage.setItem("zzzz", JSON.stringify(branchData));
    } else {
      let branchData = {
        branch: userIds?.nbm_branches[0],
        id: userIds?.nbm_branchids[0],
      };
      localStorage.setItem("zzzz", JSON.stringify(branchData));
    }

    localStorage.setItem("userBranch", JSON.stringify(branchData));
    sessionStorage.setItem("userBranch", JSON.stringify(branchData));

    sessionStorage.setItem("SbmOrNbm", "NBM");
    localStorage.setItem("bmOrNbm", "NBM");
    window.location.reload();
    window.location.replace("/home");
  };

  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));
    localStorage.clear();
    sessionStorage.clear();
    navigate("/signin");
    localStorage.setItem("logoutEvent", Date.now().toString());
    window.location.reload();
  };


  const handleBranch = (branch, id) => {
    setSelectedBranch({ branch: branch, id: id });

    let branchData = {
      branch: branch,
      id: id,
    };

    localStorage.setItem("userBranch", JSON.stringify(branchData));
    sessionStorage.setItem("userBranch", JSON.stringify(branchData));
    window.location.reload();
    window.location.replace("/home");
  };

  useEffect(() => {
    let check = userIds?.nbm_branchids.filter((item) => {
      return item === selectedBranch.id;
    });

    let filteredBranches = check.map((branchId) => {
      let index = userIds.nbm_branchids.indexOf(branchId);
      return {
        nbm_branch: userIds.nbm_branches[index],
        nbm_branchid: userIds.nbm_branchids[index],
      };
    });

    setNbmChecker(filteredBranches);
  }, [selectedBranch]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    const storedItem = localStorage.getItem("selectedItem");
    if (storedItem) {
      setSelectedItem(storedItem);
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    let routeName = routeNameMap[pathname];

    if (!routeName && pathname.includes("/:id")) {
      const pathWithoutId = pathname.split("/:id")[0];
      routeName = routeNameMap[pathWithoutId];
    }

    if (routeName) {
      setHeading(routeName);
    }
  }, [location.pathname]);


  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };


  return (


    <>

      <AppBar
        position="sticky"
        sx={{
          top: 0,
          zIndex: 9999,
          bgcolor: "rgba(27, 37, 53, 1)",
          height: "60px",
        }}>
        <Toolbar>
          <Stack
            sx={{
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
            }}>
            <Box
              sx={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
              onClick={() => navigate("/")}>
              <Toolbar sx={{ height: "80px" }}>
                <Box
                  // onClick={() => navigate("/home")}
                  as="img"
                  margin={"auto"}
                  src={vajra}
                  alt="logo"
                  sx={{ height: "35px", width: "80px", cursor: "pointer" }}
                />
              </Toolbar>
            </Box>

            <Box
              onClick={() => navigate("/")}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                // padding: '8px',
                borderRadius: "8px",
              }}>


<IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer(!drawerOpen)(null)} // Pass `null` to ignore the event
              >
                {drawerOpen ? <CancelIcon /> : <MenuIcon />}
              </IconButton>



            </Box>
          </Stack>








        </Toolbar>


      </AppBar>



      <Drawer

        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}


        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
            top: '60px',
            position: 'relative'
          },
          marginBottom: '50px',
        }}


      >
        <Box
          sx={{
            background: "white",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            width: '100%',
            gap: '20px',
            // height: '800px',
            justifyContent: 'center',


          }}>
          <Box
            sx={{
              width: "90%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}>
            <Box sx={{ display: "flex", width: '100%', alignItems: "center", paddingTop: '10px' }}>
              <Tooltip title="Profile">
                <IconButton
                  color="black"
                  onClick={() => {
                    handleListItemClick("Profile");
                    navigate("/profile");
                  }}>
                  <Avatar
                    sx={{
                      bgcolor: "#4690FF",
                      color: "white",
                      boxShadow: "0px 0px 15px 0px #DBE7E5",
                      width: "35px ",
                      height: "35px",
                    }}>
                    {truncatedUsername}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Typography sx={{ color: "black" }}>{userIds.username}</Typography>
            </Box>

          </Box>

          <Box
            sx={{ width: "90%", paddingLeft: "10px" }}>
            <BranchSelect
              value={selectedBranch?.branch}
              displayEmpty
              sx={{
                borderRadius: "36px",
                width: "100%",
                height: "36px",
                boxShadow: "0px 0px 15px 0px #DBE7E5",
                "& fieldset": {
                  border: "none",
                  borderWidth: 0,
                },
                textAlign: "left",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <LocationOnIcon sx={{ color: "black" }} />
                </InputAdornment>
              }
              inputProps={{ "aria-label": "Without label" }}>
              {branchesAndIds?.map((item) => (
                <MenuItem
                  value={item?.branch}
                  sx={{ textAlign: "left" }}
                  key={item?.id}
                  onClick={() => handleBranch(item?.branch, item?.id)}>
                  {item?.branch}
                </MenuItem>
              ))}
            </BranchSelect>
          </Box>

          <div
            style={{

              padding: "20px",

              width: "90%",

              overflow: "auto",
            }}>
            <List>
              <SidebarItem
                button
                component={Link}
                to="/dashboard-mobile"
                $selected={selectedItem === "Dashboard"}
                onClick={() => handleListItemClick("Dashboard")}>
                <IconWrapper $selected={selectedItem === "Dashboard"}>
                  <DashboardIcon />
                </IconWrapper>
                <ListItemText primary="Dashboard" />
              </SidebarItem>

           
              <SidebarItem
                button
                component={Link}
                to="/scan"
                $selected={selectedItem === "Scan"}
                onClick={() => handleListItemClick("Scan")}>
                <IconWrapper $selected={selectedItem === "Scan"}>
                  <QrCodeScannerIcon />
                </IconWrapper>
                <ListItemText primary="QR Scanner" />
              </SidebarItem>
       


              <ListItem disablePadding>
                <ListItemButton onClick={() => handleDropdown("asset")}>
                  <IconWrapper $selected={selectedItem === "Asset"}>
                    <DataThresholdingIcon />
                  </IconWrapper>
                  <ListItemText sx={{ color: "black" }} primary="Asset" />
                  {dropdownOpen === "asset" ? (
                    <ExpandLessIcon sx={{ color: "black" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "black" }} />
                  )}
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: dropdownOpen === "asset" ? "block" : "none" }}>
                <List sx={{ pl: 4 }}>
                  <SidebarItem
                    button
                    component={Link}
                    to="/asset-mobile"
                    $selected={selectedItem === "All Assets"}
                    onClick={() => handleListItemClick("All Assets")}>
                    <ListItemText primary="All Assets" />
                  </SidebarItem>
                  <SidebarItem
                    button
                    component={Link}
                    to="/department-list"
                    $selected={selectedItem === "Department"}
                    onClick={() => handleListItemClick("Department")}>
                    <ListItemText primary="Department" />
                  </SidebarItem>
                  <SidebarItem
                    button
                    component={Link}
                    to="/asset-history-list"
                    $selected={selectedItem === "Movements"}
                    onClick={() => handleListItemClick("Movements")}>
                    <ListItemText primary="Movements" />
                  </SidebarItem>
                  <SidebarItem
                    button
                    component={Link}
                    to="/depreciation"
                    $selected={selectedItem === "Depreciation"}
                    onClick={() => handleListItemClick("Depreciation")}>
                    <ListItemText primary="Depreciation" />
                  </SidebarItem>

                  <SidebarItem
                    button
                    component={Link}
                    to="/check-in"
                    $selected={selectedItem === "Check In"}
                    onClick={() => handleListItemClick("Check In")}>
                    <ListItemText primary="Check In" />
                  </SidebarItem>
                  <SidebarItem
                    button
                    component={Link}
                    to="/check-out"
                    $selected={selectedItem === "Check Out"}
                    onClick={() => handleListItemClick("Check Out")}>
                    <ListItemText primary="Check Out" />
                  </SidebarItem>
                  {userIds?.subs_plan !== "Basic" && (
                  <>
                  <SidebarItem
                    button
                    component={Link}
                    to="/sell-online"
                    $selected={selectedItem === "Sell Online"}
                    onClick={() => handleListItemClick("Sell Online")}>
                    <ListItemText primary="Sell Online" />
                  </SidebarItem>
                  </>
                  )}
                </List>
              </ListItem>

              <SidebarItem
                button
                component={Link}
                to="/incident-mobile"
                $selected={selectedItem === "Incident"}
                onClick={() => handleListItemClick("Incident")}>
                <IconWrapper $selected={selectedItem === "Incident"}>
                  <DesignServicesIcon />
                </IconWrapper>
                <ListItemText primary="Incident" />
              </SidebarItem>

              <SidebarItem
                button
                component={Link}
                to="/service-list"
                $selected={selectedItem === "Service"}
                onClick={() => handleListItemClick("Service")}>
                <IconWrapper $selected={selectedItem === "Service"}>
                  <SettingsIcon />
                </IconWrapper>
                <ListItemText primary="Service" />
              </SidebarItem>


              <SidebarItem
                button
                component={Link}
                to="/Work-order"
                $selected={selectedItem === "Work order"}
                onClick={() => handleListItemClick("Work order")}>
                <IconWrapper $selected={selectedItem === "Work order"}>
                  <ChecklistIcon />
                </IconWrapper>
                <ListItemText primary="Work order" />
              </SidebarItem>

              <SidebarItem
                button
                component={Link}
                to="/request-mobile"
                $selected={selectedItem === "Request"}
                onClick={() => handleListItemClick("Request")}>
                <IconWrapper $selected={selectedItem === "Request"}>
                  <SmsIcon />
                </IconWrapper>
                <ListItemText primary="Request" />
              </SidebarItem>

              <ListItem disablePadding>
                <ListItemButton onClick={() => handleDropdown("user")}>
                  <IconWrapper $selected={selectedItem === "User"}>
                    <GroupAddIcon />
                  </IconWrapper>
                  <ListItemText sx={{ color: "black" }} primary="User" />
                  {dropdownOpen === "user" ? (
                    <ExpandLessIcon sx={{ color: "black" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "black" }} />
                  )}
                </ListItemButton>
              </ListItem>
              {/* User List and Add User */}
              <ListItem
                disablePadding
                sx={{ display: dropdownOpen === "user" ? "block" : "none" }}>
                <List sx={{ pl: 4 }}>
                  <SidebarItem
                    button
                    component={Link}
                    to="/user-mobile"
                    $selected={selectedItem === "User List"}
                    onClick={() => handleListItemClick("User List")}>
                    <ListItemText primary="User List" />
                  </SidebarItem>

                  {userIds?.subs_plan !== "Basic" && (
                  <>
                  <SidebarItem
                    button
                    component={Link}
                    to="/user-manual"
                    $selected={selectedItem === "Usermanual"}
                    onClick={() => handleListItemClick("Usermanual")}>
                    <ListItemText primary="Usermanual" />
                  </SidebarItem>
                  </>
                  )}

                  <SidebarItem
                    button
                    component={Link}
                    to="/calendar"
                    $selected={selectedItem === "Calendar"}
                    onClick={() => handleListItemClick("Calendar")}>
                    <ListItemText primary="Calendar" />
                  </SidebarItem>
                </List>
              </ListItem>
              {/* Request */}
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleDropdown("reports")}>
                  <IconWrapper $selected={selectedItem === "Reports"}>
                    <AssignmentIcon />
                  </IconWrapper>
                  <ListItemText sx={{ color: "black" }} primary="Reports" />
                  {dropdownOpen === "Reports" ? (
                    <ExpandLessIcon sx={{ color: "black" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "black" }} />
                  )}
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: dropdownOpen === "reports" ? "block" : "none" }}>
                <List sx={{ pl: 4 }}>
                  <SidebarItem
                    button
                    component={Link}
                    to="/report"
                    $selected={selectedItem === "All Reports"}
                    onClick={() => handleListItemClick("All Reports")}>
                    <ListItemText primary="All Reports" />
                  </SidebarItem>

                  <SidebarItem
                    button
                    component={Link}
                    to="/pm-checklist"
                    $selected={selectedItem === "PM Checklist"}
                    onClick={() => handleListItemClick("PM Checklist")}>
                    <ListItemText primary="PM Checklist" />
                  </SidebarItem>
                </List>
              </ListItem>

              <SidebarItem
                button
                component={Link}
                to="/notification"
                $selected={selectedItem === "Notification"}
                onClick={() => handleListItemClick("Notification")}>
                <IconWrapper $selected={selectedItem === "Notification"}>
                  <NotificationsIcon />
                </IconWrapper>
                <ListItemText primary="Notification" />
              </SidebarItem>





            </List>
          </div>

          <Box
            sx={{
              width: "90%",
              display: "flex",
              gap: "10px",
              justifyContent: "left",
              alignItems: "center",
              height: "35px",
            }}>
            <Tooltip title="Go to non-biomedical section">
              <Fab
                onClick={handleChange}
                disabled={
                  nbmChecker?.length === 0 && userIds?.role === "Sub_User"
                }
                variant="extended"
                size="small"
                sx={{
                  boxShadow: "0px 0px 15px 0px #DBE7E5",
                  borderRadius: "36px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",

                  color: "black",
                  bgcolor: "#4690FF",
                  cursor: "pointer",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  width: "100%",
                  gap: "5px",
                }}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: "#1B2535" }}>
                  <Typography variant="caption">NB</Typography>
                </Avatar>
                Non-Biomedical
              </Fab>
            </Tooltip>
          </Box>



        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '10px', marginBottom: '10px' }} >
          <CustomButton variant="outlined" startIcon={<HeadsetMicIcon />}><a href="/Contact"style={{textDecoration:"none",color:"black"}}>Contact</a></CustomButton>
          <CustomButton variant="outlined" onClick={handleOpenModal} startIcon={<LogoutIcon />}>Logout</CustomButton>
        </Box>


        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description">
          <ModalBox>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",

                textAlign: "center",
                alignItems: "center",
              }}>
              <IconButton>
                <LogoutIcon size={60} />
              </IconButton>

              <Typography variant="h6" style={{ fontWeight: 700 }}>
                Log out!
              </Typography>
              <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                Are you sure you want to <br /> Log out this Session?
              </Typography>
              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>
              <Button
                size="small"
                variant="contained"
                onClick={handleLogout}
                sx={{
                  borderRadius: "35px",
                  width: "100px",
                  textTransform: "none",
                  bgcolor: "rgba(70, 144, 255, 1)",
                }}>
                Log Out
              </Button>
            </Stack>
          </ModalBox>
        </Modal>




      </Drawer>

    </>
  );
};

export default PrivateNavbar;


