import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  OutlinedInput,
  Badge,
  FormHelperText,
  Button,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "jspdf-autotable";
import { useEffect } from "react";
import {
  assetDetailsData,
  createIncident,
  getIncidentHistory,
  recordIncidentImageDelete,
  recordIncidentImageUpload,
} from "../redux/data/action";

import { useState } from "react";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import SuccessPopUp from "../components/SuccessPopUp";
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",

  alignItems: "center",
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerSet = styled(Box)(({ theme }) => ({
  width: "750px",

  borderRadius: "8px",

  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextBox = styled(Stack)(({ theme }) => ({
  flexDirection: "row",

  gap: "20px",
  width: "100%",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox = styled(Box)(({ theme }) => ({
  width: "50%",
  textAlign: "start",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightBox = styled(Box)(({ theme }) => ({
  width: "50%",
  textAlign: "start",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CustomText = styled(TextField)(({ theme }) => ({
  width: "342px",
  textAlign: "start",

  marginTop: "15px",
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomSet = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "20px",
  width: "100%",
  justifyContent: "space-between",
  marginTop: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const UploadImage = styled(Stack)(({ theme }) => ({
  height: "150px",
  width: "342px",
  flexDirection: "row",
  overflowX: "auto",

  overflowY: "hidden",
  gap: "20px",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const RecorderIncidentPage = () => {
  let params = useParams();
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const detailData = useSelector((store) => store.data.assetDetails);
  // console.log(detailData);
  const token = localStorage.getItem("userToken");
  const historyDetails = useSelector((store) => store.data.incidentHistory);
  const response = useSelector((store) => store.data.createIncident);

  const [fileUrl, setFileUrl] = useState([]);

  const [issue, setIssue] = useState("");
  const [isIssueError, setIsIssueError] = useState("");

  const [isPhotoError, setIsPhotoError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    navigate(-1);
  };

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        // compressImage(file);
        setIsPhotoError(`Can't upload image more than 4MB.`)
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${"assetId_"}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
            userrole:userAccess?.role,
            depart_ids:userAccess.departmentids,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(recordIncidentImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${"assetId_"}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${"assetId_"}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
                userrole:userAccess?.role,
                depart_ids:userAccess.departmentids,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(recordIncidentImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };

    dispatch(recordIncidentImageDelete(data));
    setFileUrl(fileUrl?.filter((item) => item?.imageid !== id));
  };

  const handleIssue = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:"", -]+$/; 
    if (value === "" || regex.test(value)) {
      setIssue(value);
      setIsIssueError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsIssueError("Invalid character");
    }
    if (value === "") {
      setIsIssueError("Please fill the field");
    }
  };
  
  

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };
    dispatch(getIncidentHistory(data, token));
  }, [dispatch]);

  const handleSubmit = () => {
    issue === "" && setIsIssueError("Please fill the field");
    if (issue !== "" && isIssueError === "") {
      let data = {
        hospid: userIds?.hospid,
        hospname: userIds?.hospname,
        username: userIds?.username,
        userid: userIds?.userid,
        userrole: userAccess?.role,
        assetname: detailData?.name,
        assetcode: detailData?.code,
        serialno: detailData?.serialno,
        assetid: params?.id,
        departid: detailData?.departid,
        departname: detailData?.dept,
        incident_description: issue,
        branchid: userBranch?.id,
        branchname: userBranch.branch,
        departid: detailData?.departid,
        incident_images:
          fileUrl?.length > 0
            ? fileUrl?.map((item) => {
              return item?.imageid;
            })
            : "NA",
        brand: detailData?.brand,
        modelno: detailData?.modelno,
        userrole:userAccess?.role,
        depart_ids:userAccess.departmentids,
      };
      setOpenModal(true);
      setToggle(true);
      dispatch(createIncident(data));
    }
  };

  useEffect(() => {
    if (response === "Inserted" && issue !== "") {
      setOpenModal(true);
    }
  }, [response]);

  const AssetDetals = {
    " Asset Name": detailData.name,
    "   Brand": detailData.brand,
    Department: detailData.dept,

    "    Serial Number": detailData.serialno,
    "     Model Number": detailData.modelno,
    " Asset code": detailData.code,
  };
  return (
    <Wrapper>
      <InnerSet>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "10px 20px 10px 20px",
          }}
        >
          <Label variant="subtitle1">Record Incident</Label>
          <Label variant="subtitle1">New</Label>
        </Stack>

        <Stack sx={{ bgcolor: "white", padding: "20px" }}>
          {/* <Stack
            sx={{
              textAlign: "start",

              marginTop: "25px",
              paddingLeft: "10px",
              marginBottom: "20px",
              width: "100%",
              gap: "10px",
            }}>
            <Label
              variant="subtitle1"
              style={{ color: "rgba(70, 144, 255, 1)" }}>
              Record Incident{" "}
            </Label>
            <Divider style={{ background: "rgba(70, 144, 255, 1)" }} />
          </Stack> */}

          <TextBox>
            <LeftBox>
              <Label variant="subtitle1" sx={{ paddingLeft: "10px" }}>
                Asset Details
              </Label>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(AssetDetals).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}
                        >
                          {key}
                        </TableCells>

                        <TableCells sx={{}}>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </LeftBox>
            <RightBox>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Issue
              </Label>

              <CustomText
                onChange={handleIssue}
                multiline
                rows={8}
                placeholder="Enter issue here..."
                variant="outlined"
                
              />
              <Box
              sx={{
                width: "100%",
                textAlign: "start",
                display: { xs: "flex", md: "none" },
                flexDirection:"column"
              }}
            >
              <Stack
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: "row",

                  alignItems: "center",
                  marginTop:"20px",marginBottom:"10px"
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)", }}
                >
                  Upload Images
                </Label>

                <label htmlFor="img" style={{ display: fileUrl?.length < 3 ? "block" : "none" }}>
                  <FileUploadIcon
                    size={20}
                    style={{
                      cursor: "pointer",
                      color: "rgba(70, 144, 255, 1)",
                    }}
                  />
                </label>

                <input
                  type="file"
                  name="uploadfile"
                  id="img"
                  style={{
                    display: "none",
                    width: "42px",
                    height: "42px",
                  }}
                  onChange={handlePhotoUpload}
                />
              </Stack>

              <UploadImage>
                {fileUrl?.map((item, index) => (
                  <Badge
                    key={index}
                    badgeContent={
                      <CloseIcon
                        size={15}
                        style={{
                          color: "rgba(255, 58, 68, 1)",
                        }}
                        onClick={() => removePhoto(item?.imageid)}
                      />
                    }
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      as="img"
                      src={item?.image}
                      alt=""
                      sx={{ width: "75px", height: "80px" }}
                    />
                  </Badge>
                ))}
              </UploadImage>
              {isPhotoError && (
                <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
              )}
            </Box>

              <HelperText>{isIssueError}</HelperText>
              

            </RightBox>
            
          </TextBox>
          <BottomSet>
            <Box sx={{ width: "100%", textAlign: "start" }}>
              {historyDetails?.length !== 0 ? (
                <>
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",

                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Label variant="subtitle1" sx={{ paddingLeft: "10px" }}>
                      Incident History - {historyDetails?.length < 10
                        ? `0${historyDetails?.length}`
                        : historyDetails?.length}
                    </Label>
                
                  </Stack>

                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {historyDetails !== 0 &&
                          historyDetails?.map((item, key) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{ color: "rgba(27, 37, 53, 1)" }}
                              >
                                {item?.incidcleardate}
                              </TableCells>

                              <TableCells sx={{}}>
                                {item?.resolvetime}
                              </TableCells>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",

                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Label variant="subtitle1" sx={{ paddingLeft: "10px" }}>
                      Incident History
                    </Label>
                  </Stack>
                  <Typography sx={{ paddingLeft: "10px", marginTop: "20px" }}>
                    No Incident history to display
                  </Typography>
                </Stack>
              )}
            </Box>

            <Box
              sx={{
                width: "50%",
                textAlign: "start",
                display: { xs: "none", sm: "block" },
              }}
            >
              <Stack
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: "row",

                  alignItems: "center",
                  display:{}
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Upload Images
                </Label>

                <label htmlFor="img" style={{ display: fileUrl?.length < 3 ? "block" : "none" }}>
                  <FileUploadIcon
                    size={20}
                    style={{
                      cursor: "pointer",
                      color: "rgba(70, 144, 255, 1)",
                    }}
                  />
                </label>

                <input
                  type="file"
                  name="uploadfile"
                  id="img"
                  style={{
                    display: "none",
                    width: "42px",
                    height: "42px",
                  }}
                  onChange={handlePhotoUpload}
                />
              </Stack>

              <UploadImage>
                {fileUrl?.map((item, index) => (
                  <Badge
                    key={index}
                    badgeContent={
                      <CloseIcon
                        size={15}
                        style={{
                          color: "rgba(255, 58, 68, 1)",
                        }}
                        onClick={() => removePhoto(item?.imageid)}
                      />
                    }
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      as="img"
                      src={item?.image}
                      alt=""
                      sx={{ width: "90px", height: "100px" }}
                    />
                  </Badge>
                ))}
              </UploadImage>
              {isPhotoError && (
                <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
              )}
            </Box>
          </BottomSet>

          <Stack sx={{ width: "100%" }}>
            <Divider />
          </Stack>
          <Stack
            sx={{
              width: "100%",
              alignItems: "flex-end",

              marginTop: "20px",
            }}
          >
            <Button
              disabled={userAccess?.role === "Guest"}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "186px", borderRadius: "22px" }}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </InnerSet>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"Incident has been successfully recorded.  "}
        subText2={"   "}
        toggle={toggle}
      />
    </Wrapper>
  );
};

export default RecorderIncidentPage;
