import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Badge,
  Button,
  Grid,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import { useEffect } from "react";
import { getIncidentDetail, profile } from "../redux/nbmData/action";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import pdfImage from "../../src/images/pdfImage.png";

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",
  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerSet = styled(Stack)(({ theme }) => ({
  width: "750px",

  borderRadius: "8px",

  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomSet = styled(Stack)(({ theme }) => ({
  width: "750px",
  height: "70px",
  padding: "20px",
  flexDirection: "column",
  alignItems: "flex-end",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "0px",
    paddingTop: "20px",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextSet = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: "white",

  padding: "10px 20px 10px 20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "100%",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerComponent = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftComponent = styled(Box)(({ theme }) => ({
  width: "60%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightComponent = styled(Box)(({ theme }) => ({
  width: "40%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentDetailPage = () => {
  const userAccess =
  JSON.parse(sessionStorage.getItem("user_access")) ||
  JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let incidentData = useSelector((store) => store.data.incidentDetails);
  let profileData = useSelector((store) => store.data.profile);
  // console.log("datas are", profileData.logo_url);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch.id,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getIncidentDetail(data, token));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.download = "downloaded_image.jpg";
    link.click();
  };

  const handlePDFDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.click();
  };

  const navigate = useNavigate();

  const [fileType, setFileType] = useState(null);
  // const fileLink = incidentData.receipt_id[0];

  const fileLink =
    Array.isArray(incidentData?.receipt_id) &&
    incidentData.receipt_id.length > 0
      ? incidentData.receipt_id[0]
      : null;

  useEffect(() => {
    if (fileLink?.endsWith(".pdf") || fileLink?.includes(".pdf")) {
      setFileType("pdf");
    } else if (
      fileLink?.endsWith(".jpg") ||
      fileLink?.endsWith(".jpeg") ||
      fileLink?.endsWith(".png") ||
      fileLink?.endsWith(".gif") ||
      fileLink?.includes("image/") ||
      fileLink?.includes(".image")
    ) {
      setFileType("image");
    } else {
      setFileType("unknown");
    }
  }, [fileLink]);



  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(profile(data));
  }, [dispatch]);


   const [incidentDowntime, setIncidentDowntime] = useState("");
  
    useEffect(() => {
      if (!incidentData.start_date || !incidentData.start_time) {
        setIncidentDowntime();
        // setIncidentDowntime("Start date or time is not available.");
        return;
      }
  
      const [day, month, year] = incidentData.start_date.split("-").map(Number);
      const [startHours, startMinutes, startSeconds] = incidentData.start_time.split(":").map(Number);
  
      const startDateTime = new Date(year, month - 1, day, startHours, startMinutes, startSeconds);
  
      const updateDowntime = () => {
        const currentDateTime = new Date();
        const diff = currentDateTime - startDateTime;
  
        if (diff < 0) {
          setIncidentDowntime("The start time is in the future.");
          return;
        }
  
        const totalSeconds = Math.floor(diff / 1000);
  
        const days = Math.floor(totalSeconds / (24 * 3600));
        const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
  
        setIncidentDowntime(
          `${days > 0 ? `${days}d ` : ""}${hours > 0 ? `${hours}hr ` : ""}${minutes > 0 ? `${minutes}min ` : ""}${seconds > 0 ? `${seconds}sec ` : ""}`
        );
      };
  
      const interval = setInterval(updateDowntime, 1000);
  
      return () => clearInterval(interval);
    }, [incidentData.start_date, incidentData.start_time]);

  const HospitalLeftData = {
    "Asset Name": incidentData.assetname,
    "Asset Code": incidentData.assetcode,
    Department: incidentData.deptname,
  };
  const HospitalLeftDataOpen = {
    "Asset Name": incidentData.assetname,
    "Asset Code": incidentData.assetcode,
    Department: incidentData.deptname,
    "Serial No.": incidentData.serialno,
  };
  const HospitalRightData = {
    "Issue Date": incidentData.start_date,
    "Clear Date": incidentData.clear_date,
    "Total Downtime": incidentData.tot_downtime,
  };

  const HospitalRightDataOpen = {
    "Issue Date": incidentData.start_date,
    "Issue Time": incidentData.start_time,
    "Down Time": <span style={{ color: "#FF6B00" }}>{incidentDowntime}</span>,
  };
  const UserLeftData = {
    Name: incidentData.person_name,
    "Contact No.": incidentData.person_phone,
    Email: incidentData.person_email,
  };

  const ExpenditureData = {
    Reason: incidentData.expenditure,
    Cost: incidentData.amt_spent,
  };

  const IssueData = {
    "Problem Identified ": incidentData?.incdt_issue,
  };
  const IssueData1 = {
    "Action Taken": incidentData?.actiontaken,
  };
  const IssueData2 = {
    ...(incidentData.feedback !== "NA" && {
      Remark: incidentData.feedback,
    }),
  };

  const isPDF = (url) => {
    const lowerCaseUrl = url.toLowerCase();
    // console.log("Checking URL:", lowerCaseUrl);
    const urlWithoutParams = lowerCaseUrl.split("?")[0];
    return urlWithoutParams.endsWith(".pdf");
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    const labelX = 20;
    const labelY = 65;
    const dataX = 73;
    let yPosition = 30;
    const pageHeight = doc.internal.pageSize.height;

    const hasEnoughSpace = (heightNeeded) => {
      return yPosition + heightNeeded < pageHeight - 10; // Keep a margin at the bottom
    };
    

    // Add logo on top-right
    const addHeader = () => {
      if (profileData.logo_url) {
        doc.addImage(
          profileData.logo_url,
          "JPEG",
          doc.internal.pageSize.width - 200,
          10,
          50,
          25
        );
      }
      doc.setFontSize(16);
      doc.setTextColor("#1F86CB");
      doc.setFont("Helvetica", "bold");
      doc.text(userIds.hospname || "Default Hospital", labelX + 72, 20);


      doc.setFont("Helvetica", "bold");
      doc.text(profileData.city + ", " + (profileData.state || "Main Branch"), labelX + 64, 28);
    };

    addHeader();


  yPosition = 40;


  
    const lineStartX = 10; // Move the line closer to the left
    const lineEndX = doc.internal.pageSize.width - 10; // Extend the line closer to the right edge
    doc.setDrawColor(0, 0, 0); // Set line color to black
    doc.setLineWidth(0.5); // Set line width
    doc.line(lineStartX, yPosition, lineEndX, yPosition); // Draw the line
    yPosition += 15;

    // Title: "Incident Report" should be bold
    doc.setFontSize(18);
    doc.setTextColor("black");
    doc.setFont("helvetica", "bold");
    doc.text(
      "Incident Report",
      doc.internal.pageSize.width / 2,
      yPosition,
      null,
      null,
      "center"
    );

    yPosition += 18;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    const addText = (label, value, heightNeeded) => {
      if (!hasEnoughSpace(heightNeeded)) {
        doc.addPage();
        yPosition = 20; 
      }

      doc.setTextColor(0, 0, 0); 
      doc.text(label, labelX, yPosition);
      doc.setTextColor(0, 0, 0); // Set colon color to black
      doc.text(":", labelY, yPosition);
      doc.text(value || "-", dataX, yPosition);
      yPosition += heightNeeded;
    };

    const addUnderlinedText = (text, yPosition, isBold = false) => {
      doc.setFont("Helvetica", isBold ? "normal" : "normal"); // Set to bold or normal font
      doc.setTextColor(0, 0, 0); // Set text color to black
      doc.text(text, labelX, yPosition);
      const textWidth = doc.getTextWidth(text); // Get the width of the text
      doc.line(labelX, yPosition + 1, labelX + textWidth, yPosition + 1); // Draw a line under the text
      return yPosition + 10; // Return the new yPosition after adding the line
    };

    // Add "Product details" section with underline
    yPosition = addUnderlinedText("Product details", yPosition, true);
    addText("Asset Name", incidentData.assetname, 10);
    addText("Asset Code", incidentData.assetcode, 10);
    addText("Department", incidentData.deptname, 20);

    // Add "Incident Details" section with underline
    yPosition = addUnderlinedText("Incident Details", yPosition, true);
    addText("Issue Date", incidentData.start_date, 10);
    addText("Clear Date", incidentData.clear_date, 10);
    addText("Total Downtime", incidentData.tot_downtime, 20);

    // Add "Incident Clear Details" section with underline
    yPosition = addUnderlinedText("Incident Clear Details", yPosition, true);
    addText("Servicer Name", incidentData.person_name, 10);
    addText("Contact No", incidentData.person_phone, 10);
    addText("Email", incidentData.person_email, 10);
    addText("Problem Identified", incidentData?.incdt_issue, 10);

    if (Array.isArray(incidentData?.incdt_imgs) && incidentData.incdt_imgs.length > 0) {
          const imageWidth = 50;
          const imageHeight = 50;
          const margin = 10;
          let rowXPosition = 20;
          let rowYPosition = yPosition;
        
          incidentData.incdt_imgs.forEach((image, index) => {
            if (rowXPosition + imageWidth + margin > doc.internal.pageSize.width) {
              rowXPosition = 20;
              rowYPosition += imageHeight + margin;
            }
        
            doc.addImage(
              image,
              "JPEG",
              rowXPosition,
              rowYPosition,
              imageWidth,
              imageHeight
            );
            rowXPosition += imageWidth + margin;
          });
        
          yPosition += imageHeight + margin;
        }
        
    
        addText("Action Taken", incidentData?.actiontaken, 10);
    
        if (Array.isArray(incidentData?.clear_imgs) && incidentData.clear_imgs.length > 0) {
          const imageWidth = 50;
          const imageHeight = 50;
          const margin = 10;
          let rowXPosition = 20;
          const rowYPosition = yPosition;
    
          incidentData.clear_imgs.forEach((image, index) => {
            if (rowXPosition + imageWidth + margin > doc.internal.pageSize.width) {
              rowXPosition = 20;
              yPosition += imageHeight + margin;
            }
    
            doc.addImage(
              image,
              "JPEG",
              rowXPosition,
              rowYPosition,
              imageWidth,
              imageHeight
            );
            rowXPosition += imageWidth + margin;
          });
    
          yPosition += imageHeight + margin;
        }

    addText("Remark", incidentData.feedback, 10);

    incidentData.expenditure.forEach((expenditure, index) => {
          addText("Reason", expenditure, 10);
          addText("Cost", `${incidentData.amt_spent[index]}`, 10);
    
          if (incidentData.receipt_id[index]) {
            const receiptUrl = incidentData.receipt_id[index];
            const imageHeight = 60;
    
            if (!hasEnoughSpace(imageHeight)) {
              doc.addPage();
              yPosition = 20;
            }
    
            if (isPDF(receiptUrl)) {
              doc.addImage(pdfImage, "JPEG", 20, yPosition, 50, 50);
              yPosition += 60;
            } else {
              doc.addImage(receiptUrl, "JPEG", 20, yPosition, 50, 50);
              yPosition += 60;
            }
          } else {
            doc.text("No image available", 20, yPosition);
            yPosition += 10;
          }
    
          if (!hasEnoughSpace(15)) {
            doc.addPage();
            yPosition = 20;
          }
        });
    doc.save("Closed_Incident_Details.pdf");
  };

  
  return (
    <Box sx={{ padding: "10px" }}>
      {incidentData?.incid_status === "Cleared" ? (
        <Wrapper>
          <InnerSet>
            <TextSet>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Cleared Incident
              </Label>

              <Label>{incidentData?.clear_date}</Label>
            </TextSet>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <InnerComponent>
                <LeftComponent>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LeftComponent>
                <RightComponent>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </RightComponent>
              </InnerComponent>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Contact Person</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack sx={{ width: { xs: "100%", md: "60%" } }}>
                  <TableContainer
                    style={{ overflowX: "auto", width: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(UserLeftData).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells sx={{}}>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Issue Details</Label>
                <Divider />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  {Object.entries(IssueData).map(([key, value]) => (
                    <Stack
                      sx={{
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        sx={{
                          width: { xs: "100%", sm: "100%", md: "60%" },

                          textAlign: "start",
                          minHeight: "130px",
                          background: "rgba(244, 246, 246, 1)",
                          padding: "6px",
                        }}
                      >
                        <Label sx={{ margin: "10px" }}>{key}</Label>

                        <Typography
                          sx={{ marginLeft: "10px", wordWrap: "break-word" }}
                        >
                          {value}
                        </Typography>
                        <Stack
                          sx={{
                            width: "20%",
                            gap: "10px",
                            display: { xs: "flex", sm: "none" },
                            flexDirection: "row",
                          }}
                        >
                          <>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            >
                              {incidentData?.incdt_imgs !== "NA" &&
                                incidentData?.incdt_imgs !== "" && (
                                  <>
                                    <>
                                      {incidentData?.incdt_imgs?.map(
                                        (images) => (
                                          <Badge
                                            sx={{
                                              height: "85%",
                                              width: "30%",
                                            }}
                                            badgeContent={
                                              <DownloadIcon
                                                sx={{
                                                  color: "#757575",
                                                }}
                                                size={15}
                                                style={{}}
                                                onClick={() =>
                                                  handleDownload(images)
                                                }
                                              />
                                            }
                                            color="secondary"
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "right",
                                            }}
                                          >
                                            <Stack
                                              sx={{
                                                width: "65px",

                                                height: "80px",
                                                margin: "10px",
                                              }}
                                            >
                                              <Box
                                                as="img"
                                                src={images}
                                                alt="incImage"
                                                style={{ height: "100%" }}
                                              />
                                            </Stack>
                                          </Badge>
                                        )
                                      )}
                                    </>
                                  </>
                                )}
                            </Stack>
                          </>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          width: { md: "40%" },
                          gap: "10px",
                          display: { xs: "none", sm: "flex" },
                          flexDirection: "row",
                        }}
                      >
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          {incidentData?.incdt_imgs !== "NA" &&
                            incidentData?.incdt_imgs !== "" && (
                              <>
                                <>
                                  {incidentData?.incdt_imgs?.map((images) => (
                                    <Badge
                                      sx={{
                                        height: "70%",
                                        width: "30%",
                                      }}
                                      badgeContent={
                                        <DownloadIcon
                                          sx={{
                                            color: "#757575",
                                          }}
                                          size={15}
                                          style={{}}
                                          onClick={() => handleDownload(images)}
                                        />
                                      }
                                      color="secondary"
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                    >
                                      <Stack
                                        sx={{
                                          width: "80px",

                                          height: "80px",
                                          margin: "10px",
                                        }}
                                      >
                                        <Box
                                          as="img"
                                          src={images}
                                          alt="incImage"
                                          style={{ height: "100%" }}
                                        />
                                      </Stack>
                                    </Badge>
                                  ))}
                                </>
                              </>
                            )}
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                  {Object.entries(IssueData1).map(([key, value]) => (
                    <Stack
                      sx={{
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        sx={{
                          width: { xs: "100%", sm: "100%", md: "60%" },

                          textAlign: "start",
                          minHeight: "130px",
                          background: "rgba(244, 246, 246, 1)",
                          padding: "6px",
                        }}
                      >
                        <Label sx={{ margin: "10px" }}>{key}</Label>

                        <Typography
                          sx={{ marginLeft: "10px", wordWrap: "break-word" }}
                        >
                          {value}
                        </Typography>
                        <Stack
                          sx={{
                            width: "20%",
                            gap: "10px",
                            display: { xs: "flex", sm: "none" },
                            flexDirection: "row",
                          }}
                        >
                          <>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            >
                              {incidentData?.clear_imgs !== "NA" &&
                                incidentData?.clear_imgs !== "" && (
                                  <>
                                    {incidentData?.clear_imgs?.map((images) => (
                                      <Badge
                                        sx={{
                                          height: "85%",
                                          width: "30%",
                                        }}
                                        badgeContent={
                                          <DownloadIcon
                                            sx={{
                                              color: "#757575",
                                            }}
                                            size={15}
                                            style={{}}
                                            onClick={() =>
                                              handleDownload(images)
                                            }
                                          />
                                        }
                                        color="secondary"
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                      >
                                        <Stack
                                          sx={{
                                            width: "65px",

                                            height: "80px",
                                            margin: "10px",
                                          }}
                                        >
                                          <Box
                                            as="img"
                                            src={images}
                                            alt="incImage"
                                            style={{ height: "100%" }}
                                          />
                                        </Stack>
                                      </Badge>
                                    ))}
                                  </>
                                )}
                            </Stack>
                          </>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          width: { md: "40%" },
                          gap: "10px",
                          display: { xs: "none", sm: "flex" },
                          flexDirection: "row",
                        }}
                      >
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          {incidentData?.clear_imgs !== "NA" &&
                            incidentData?.clear_imgs !== "" && (
                              <>
                                {incidentData?.clear_imgs?.map((images) => (
                                  <Badge
                                    sx={{
                                      height: "63%",
                                      width: "30%",
                                    }}
                                    badgeContent={
                                      <DownloadIcon
                                        sx={{
                                          color: "#757575",
                                        }}
                                        size={15}
                                        style={{}}
                                        onClick={() => handleDownload(images)}
                                      />
                                    }
                                    color="secondary"
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        width: "80px",

                                        height: "80px",
                                        margin: "10px",
                                      }}
                                    >
                                      <Box
                                        as="img"
                                        src={images}
                                        alt="incImage"
                                        style={{ height: "100%" }}
                                      />
                                    </Stack>
                                  </Badge>
                                ))}
                              </>
                            )}
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                  {Object.entries(IssueData2).map(([key, value]) => (
                    <Stack
                      sx={{
                        width: { sm: "100%", md: "60%" },

                        textAlign: "start",
                        minHeight: "130px",
                        background: "rgba(244, 246, 246, 1)",
                        padding: "20px",
                      }}
                    >
                      <Label>{key}</Label>

                      <Typography
                        sx={{ marginTop: "10px", wordWrap: "break-word" }}
                      >
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              {ExpenditureData?.Cost.length > 0 && (
                <Box>
                  <Stack
                    sx={{
                      textAlign: "start",

                      marginTop: "25px",
                      paddingLeft: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <Label variant="subtitle1"></Label>
                    <Divider />
                  </Stack>

                  <Stack
                    flexDirection={"row"}
                    sx={{
                      gap: "20px",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ width: "100%" }}>
                      {incidentData.expenditure.length > 0 ? (
                        incidentData.expenditure.map((expenditure, index) => (
                          <Box
                            key={index}
                            elevation={2}
                            sx={{
                              display: { sm: "grid", md: "flex,", lg: "flex" },
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Grid
                                container
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-around",
                                }}
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                    variant="body1"
                                  >
                                    Reason
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Cost
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      margin: "5px",
                                      width: "100%",
                                      fontSize: "0.75rem",
                                      wordWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {expenditure}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ width: "100%", fontSize: "0.75rem" }}
                                  >
                                    ₹ {incidentData.amt_spent[index]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>

                           
                            <Box sx={{ width: "100%" }}>
                              {incidentData.receipt_id[index] ? (
                                <>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                    variant="body1"
                                  >
                                    Receipt
                                  </Typography>
                                  <Stack
                                    sx={{
                                      width: "100%",
                                      textAlign: "start",
                                      minHeight: "130px",
                                      background: "rgba(244, 246, 246, 1)",
                                      margin: "10px",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        position: "relative",
                                        width: "fit-content",
                                        margin: "10px",
                                      }}
                                    >
                                      {(() => {
                                        const receiptUrl =
                                          incidentData.receipt_id[index];
                                        // console.log("Receipt URL:", receiptUrl);
                                        if (isPDF(receiptUrl)) {
                                          // console.log("This is a PDF file.");
                                          return (
                                            <IconButton>
                                              {/* <PictureAsPdfIcon />{" "} */}
                                              <img
                                                src={pdfImage}
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              ></img>
                                            </IconButton>
                                          );
                                        } else {
                                          // console.log("This is an image file.");
                                          return (
                                            <img
                                              src={receiptUrl}
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              onClick={() => {
                                                const link =
                                                  document.createElement("a");
                                                link.href = receiptUrl;
                                                link.download = `receipt_${index}.jpg`;
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                              }}
                                            />
                                          );
                                        }
                                      })()}
                                      <IconButton
                                        onClick={() => {
                                          const link =
                                            document.createElement("a");
                                          link.href =
                                            incidentData.receipt_id[index];
                                          link.download = `receipt_${index}.jpg`;
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        }}
                                        aria-label="download"
                                        sx={{
                                          position: "absolute",
                                          bottom: -6,
                                          right: -3,
                                          zIndex: 1,
                                          color: "black",
                                          padding: "0px",
                                          backgroundColor: "white",
                                          color: "#757575",
                                          padding: "5px",
                                        }}
                                      >
                                        <DownloadIcon />
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                </>
                              ) : (
                                <Typography variant="body1">
                                  No image available
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <div>No expenditures found.</div>
                      )}
                    </Stack>
                  </Stack>
                  
                </Box>
              )}
              <Stack
                    sx={{
                      justifyContent: "end",
                      display: "flex",
                      alignItems: "end",
                    }}
                  >
                    <Button
                      onClick={handleDownloadPDF}
                      sx={{
                        border: "2px solid #4690ff",
                        borderRadius: "20px",
                        fontWeight: "bold",
                        color: "#4690ff",
                        width: "120px",
                      }}
                    >
                      Download PDF
                    </Button>
                  </Stack>
            </Stack>
          </InnerSet>
        </Wrapper>
      ) : (
        <Wrapper>
          <InnerSet>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "white",
                padding: "10px 20px 10px 20px",
              }}
            >
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Incident Summary
              </Label>

              <Label sx={{ color: "#FF6B00" }}>
                {incidentData.incid_status}
              </Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>

              <InnerComponent>
                <LeftComponent sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LeftComponent>
                <RightComponent sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </RightComponent>
              </InnerComponent>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Issue
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Stack
                    sx={{
                      width: "auto",

                      textAlign: "start",
                      minHeight: "170px",
                      background: "rgba(244, 246, 246, 1)",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography
                      sx={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      {incidentData?.incdt_issue}
                    </Typography>
                    {incidentData?.incdt_imgs !== "NA" &&
                      incidentData?.incdt_imgs !== "" && (
                        <Stack
                          flexDirection={"row"}
                          sx={{
                            width: "100%",
                            height: "100px",
                            // overflowY: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                            // overflowX: "auto",
                            display: { xs: "flex", sm: "none" },
                          }}
                        >
                          {incidentData?.incdt_imgs?.map((images, index) => (
                            <Box
                              sx={{ height: "80%" }}
                              key={index}
                              position="relative"
                              display="inline-block"
                              mx={1}
                            >
                              <Box
                                as="img"
                                src={images}
                                alt="incImage"
                                sx={{ width: "65px", height: "80px" }}
                              />
                              <Box
                                position="absolute"
                                bottom={-18}
                                right={-5}
                                borderRadius={0}
                                sx={{ backgroundColor: "white" }}
                              >
                                <IconButton sx={{ padding: "0px" }}>
                                  <DownloadIcon
                                    onClick={() => handleDownload(images)}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                        </Stack>
                      )}
                  </Stack>
                </Stack>
                {incidentData?.incdt_imgs !== "NA" &&
                  incidentData?.incdt_imgs !== "" && (
                    <Stack
                      flexDirection={"row"}
                      sx={{
                        width: "50%",
                        height: "100px",
                        // overflowY: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                        // overflowX: "auto",
                        display: { xs: "none", sm: "flex" },
                      }}
                    >
                      {incidentData?.incdt_imgs?.map((images, index) => (
                        <Box
                          sx={{ height: "65%" }}
                          key={index}
                          position="relative"
                          display="inline-block"
                          mx={1} // Add some margin to separate the images
                        >
                          <Box
                            as="img"
                            src={images}
                            alt="incImage"
                            sx={{ width: "80px", height: "80px" }} // Adjusted width and height
                          />
                          <Box
                            position="absolute"
                            bottom={-5}
                            right={0}
                            borderRadius={25}
                            sx={{ backgroundColor: "white" }}
                          >
                            <IconButton
                              sx={{ padding: "0px", cursor: "pointer" }}
                            >
                              <DownloadIcon
                                onClick={() => handleDownload(images)}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </InnerSet>

          <BottomSet sx={{}}>
            <Button
              onClick={() => navigate(`/clear-incident/${params.id}`)}
              variant="contained"
              sx={{ width: "150px", borderRadius: "22px" }}
            >
              Clear Incident
            </Button>
          </BottomSet>
        </Wrapper>
      )}
    </Box>
  );
};

export default IncidentDetailPage;
