import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Nodata from "../images/Nodata.png";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import emp from "../images/emp.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteDepart, departmentAlreadySaved } from "../redux/nbmData/action";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import EditDepart from "./EditDepartment";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import DeleteAssetModal from "../components/DeleteAssetModal";
import DialogBox from "../components/DialogBox";
import DeleteModal from "../components/DeleteModal";
import Pagination from "../components/Pagination";
import TableSort from "../components/TableSort";
import BulkDeleteDepartment from "../components/BulkDeleteDepartment";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  // height: "1200px",
  // border: "1px solid blue",
  display: "flex",
  minHeight: "400px",

  padding: "2%",
  paddingTop: 0,
  flexDirection: "column",
  alignItems: "center",
  background: "#F4F6F6",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  // border: "1px solid green",
  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease", // Add a smooth transition for hover effect
    "&:hover": {
      maxWidth: "none", // Remove the max-width on hover to show full text
      whiteSpace: "normal", // Allow text to wrap and expand
      overflow: "visible", // Show the full text
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap", // Set white-space back to nowrap
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130, // Remove the max-width on hover to show full text
        whiteSpace: "wrap", // Allow text to wrap and expand
        overflow: "hidden", // Show the full text
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",

  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {},

  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  margin: "10px",
  paddingTop: "30px",
  paddingBottom: "20px",
  bgcolor: "white",
  justifyItems: "center",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",
  width: "90%",
  overflow: "scroll",
  height: "858px",
  gridTemplateColumns: "repeat(2,1fr)",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "30px",

  [theme.breakpoints.down("xl")]: {
    gridTemplateColumns: "repeat(2,1fr)",
    height: "600px",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",

  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "80%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const CountText = styled(Typography)(({ theme }) => ({
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const SavedDepart = () => {
  const allDepartment =
    useSelector((store) => store.nbmData.alreadySaved) || [];
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElSort2, setAnchorElSort2] = React.useState(null);
  const openSort = Boolean(anchorElSort);
  const openSort2 = Boolean(anchorElSort2);

  //search
  const [department, setDepartment] = useState("");
  const [toggle, setToggle] = useState(false);
  const [getId, setGetId] = useState("");
  let deleteId = localStorage.getItem("deleteDepartmentId");
  const [delOpen, setDelOpen] = React.useState(false);
  const handleDelOpen = () => {
    setDelOpen(true);
  };
  const handleDelClose = () => {
    setDelOpen(false);
  };

  // the search result

  const response = useSelector((store) => store?.nbmData?.deleteDepart);

  const [showEdit, setShowEdit] = useState(false);
  const [specificData, setSpecificData] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const text = "department";
  const [filterChanged, setFilterChanged] = useState(false);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = [...data];
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };
  const handleSorts = (order) => {
    const sortedDepartments = [...data].sort((a, b) => {
      const assetA = parseInt(a.totalassets);
      const assetB = parseInt(b.totalassets);

      if (order === "asc") {
        return assetA - assetB;
      } else if (order === "desc") {
        return assetB - assetA;
      }

      return 0;
    });

    setData(sortedDepartments);
    setPage(0);
    setFilterChanged(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setGetId(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setShowEdit(true);
    setAnchorEl(null);
    let data = {
      hospid: userData?.hospid,
      username: userData?.username,
      userid: userData?.userid,
      department: getId?.departname,
      departdesc: getId?.departdesc,
      departid: getId?.departid,
      departincharge: getId?.departincharge,
      inchargeuserid: getId?.inchargeuserid,
    };
    setSpecificData(data);
  };

  const handleDelete = (item) => {
   

    localStorage.setItem("bulkChange", getId.departname);
    navigate(`/department-asset-delete-details/${getId?.departid}`);
  };

  const handleDeleteSingle = () => {
    let data = {
      userid: userData?.userid,
      departid: getId.departid,
      hospid: userData?.hospid,
      branchid: userBranch?.id,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(deleteDepart(data, token));
    setToggle(true);
    handleCloseDelete();
  };

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = allDepartment?.filter((item) => {
        return item?.departname
          ?.toLowerCase()
          ?.includes(keyword?.toLowerCase());
      });
      setData(results);
    } else {
      setData(allDepartment);
    }

    setDepartment(keyword);
    setPage(0);
    setFilterChanged(true);
  };

  const handleButtonClick = () => {
    if (userData.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      navigate("/bulk-upload-department");
    }
  };
  const handleButtonClick2 = () => {
    if (userData.subs_plan === "Basic") {
      setOpenModal(true);
      handleCloseSort2();
    } else {
      downloadCsvFile();
      handleCloseSort2();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleSort2 = (event) => {
    setAnchorElSort2(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };

  const sortHighToLow = () => {
    let sorted = data?.sort((a, b) => {
      const dateA = new Date(a?.createdate?.split("-")?.reverse()?.join("-"));
      const dateB = new Date(b?.createdate?.split("-")?.reverse()?.join("-"));
      return dateA - dateB;
    });

    setData(sorted);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    let sorted = data?.sort((a, b) => {
      const dateA = new Date(a?.createdate?.split("-")?.reverse()?.join("-"));
      const dateB = new Date(b?.createdate?.split("-")?.reverse()?.join("-"));
      return dateB - dateA;
    });

    setData(sorted);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  useEffect(() => {
    if (allDepartment?.length !== undefined && allDepartment?.length > 0) {
      setData(allDepartment);
    }
  }, [allDepartment, response]);

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userrole:userAccess?.role,
    };
    dispatch(departmentAlreadySaved(data, token));
    setToggle(false);
  }, [dispatch, response, toggle, showEdit]);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleDeleteById = () => {
    handleClose();

    setToggle(true);

    if (getId?.totalassets == 0) {
      handleOpenDelete();
    }
    if (getId?.totalassets != 0) {
      handleOpenDeleteModal();
    }

    // localStorage.setItem("bulkChange",);
  };
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    data.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(allDepartment);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };
  const theme = useTheme();
  const isMatch2 = useMediaQuery(theme.breakpoints.down("md"));
  const handleNameClick = (data) => {
    localStorage.setItem("selectedDepartname", data.departname);
  };

  const handleShowEdit = () => {
    setShowEdit(false);
  };

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.departname;
      case "date":
        return item.createdate;
      case "assets":
        return item.totalassets;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  return (
    <>
      {showEdit ? (
        <EditDepart data={specificData} showEdit={handleShowEdit} />
      ) : (
        <Wrapper>
          {!isMatch2 ? (
            <>
              <TopContainer>
                <TitleBox>
                  <TitleHead>Departments</TitleHead>
                  <CountText>{`Total : ${data?.length}`}</CountText>
                </TitleBox>
                {allDepartment?.length > 0 && (
                  <SortFilterContainer>
                    {/* <Input type="date" onChange={(e)=>setDate(e.target.value)} /> */}
                    <Container>
                      <Button
                        variant="contained"
                        onClick={handleButtonClick}
                        disabled={
                          userAccess?.role !== "Admin" &&
                          userAccess?.role !== "Super_Admin" &&
                          userAccess?.role !== "Owner" &&
                          userAccess?.role !== "Branch_Admin"
                        }
                        sx={{
                          bgcolor:
                            userData.subs_plan === "Basic"
                              ? "#FF731D"
                              : "#1746A2",
                          borderRadius: "30px",
                          "&:hover": {
                            background:
                              userData.subs_plan === "Basic"
                                ? "#FF731D"
                                : "#1746A2",
                          },
                          width: "150px",
                          textTransform: "none",
                        }}>
                        Bulk Upload
                      </Button>
                    </Container>

                    <Container>
                      <Search
                        type="search"
                        value={department}
                        placeholder="Search"
                        onChange={filter}
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              placeholder="Search"
                              aria-label="toggle password visibility"
                              edge="start">
                              <SearchOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Container>
                    <div>
                      <Tooltip title="Sort">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort ? "true" : undefined}
                          onClick={handleSort}>
                          <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort}
                        open={openSort}
                        onClose={handleCloseSort}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem sx={{ color: "#FF731D" }}>Name Sort</MenuItem>
                        <MenuItem
                          onClick={() => sortDataByKey("departname", true)}>
                          A-Z
                        </MenuItem>
                        <MenuItem
                          onClick={() => sortDataByKey("departname", false)}>
                          Z-A
                        </MenuItem>
                        <MenuItem sx={{ color: "#FF731D" }}>Date Sort</MenuItem>
                        <MenuItem onClick={sortLowToHigh}>
                          Newest to oldest
                        </MenuItem>
                        <MenuItem onClick={sortHighToLow}>
                          Oldest to newest
                        </MenuItem>

                        <MenuItem sx={{ color: "#FF731D" }}>
                          Total asset Sort
                        </MenuItem>
                        <MenuItem onClick={() => handleSorts("asc")}>
                          Asc (0 to 100)
                        </MenuItem>
                        <MenuItem onClick={() => handleSorts("desc")}>
                          Desc(100 to 0)
                        </MenuItem>
                      </Menu>
                    </div>
                    <div>
                      <Tooltip title="Export to csv">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort2 ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort2 ? "true" : undefined}
                          onClick={handleSort2}>
                          <BrowserUpdatedIcon
                            sx={{
                              color: "#FF731D",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort2}
                        open={openSort2}
                        onClose={handleCloseSort2}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem
                          sx={{
                            color:
                              userData.subs_plan === "Basic"
                                ? "#FF731D"
                                : "#1746A2",
                          }}
                          onClick={handleButtonClick2}>
                          Export to csv
                        </MenuItem>
                      </Menu>
                    </div>
                  </SortFilterContainer>
                )}
              </TopContainer>
            </>
          ) : (
            <>
              <TopContainer sx={{ display: "flex", flexDirection: "column" }}>
                <TitleBox sx={{ marginBottom: "2%" }}>
                  <TitleHead>Departments</TitleHead>
                  <CountText>{`Total : ${data?.length}`}</CountText>
                </TitleBox>
                {allDepartment?.length > 0 && (
                  <SortFilterContainer>
                    {/* <Input type="date" onChange={(e)=>setDate(e.target.value)} /> */}

                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleButtonClick}
                      disabled={userAccess?.role === "Guest"}
                      sx={{
                        bgcolor:
                          userData.subs_plan === "Basic"
                            ? "#FF731D"
                            : "#1746A2",
                        borderRadius: "30px",
                        "&:hover": {
                          background:
                            userData.subs_plan === "Basic"
                              ? "#FF731D"
                              : "#1746A2",
                        },
                        width: "120px",
                        textTransform: "none",
                      }}>
                      Bulk Upload
                    </Button>

                    <Search
                      type="search"
                      value={department}
                      placeholder="Search"
                      onChange={filter}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            placeholder="Search"
                            aria-label="toggle password visibility"
                            edge="start">
                            <SearchOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <div>
                      <Tooltip title="Sort">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort ? "true" : undefined}
                          onClick={handleSort}>
                          <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort}
                        open={openSort}
                        onClose={handleCloseSort}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem sx={{ color: "#FF731D" }}>Name Sort</MenuItem>
                        <MenuItem
                          onClick={() => sortDataByKey("departname", true)}>
                          A-Z
                        </MenuItem>
                        <MenuItem
                          onClick={() => sortDataByKey("departname", false)}>
                          Z-A
                        </MenuItem>
                        <MenuItem sx={{ color: "#FF731D" }}>Date Sort</MenuItem>
                        <MenuItem onClick={sortLowToHigh}>
                          Newest to oldest
                        </MenuItem>
                        <MenuItem onClick={sortHighToLow}>
                          Oldest to newest
                        </MenuItem>
                        <MenuItem sx={{ color: "#FF731D" }}>
                          Total asset Sort
                        </MenuItem>
                        <MenuItem onClick={() => handleSorts("asc")}>
                          Asc (0 to 100)
                        </MenuItem>
                        <MenuItem onClick={() => handleSorts("desc")}>
                          Desc(100 to 0)
                        </MenuItem>
                      </Menu>
                    </div>
                    <div>
                      <Tooltip title="Export to csv">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort2 ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort2 ? "true" : undefined}
                          onClick={handleSort2}>
                          <BrowserUpdatedIcon
                            sx={{
                              color:
                                userData.subs_plan === "Basic"
                                  ? "#FF731D"
                                  : "#1746A2",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort2}
                        open={openSort2}
                        onClose={handleCloseSort2}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem
                          sx={{
                            color:
                              userData.subs_plan === "Basic"
                                ? "#FF731D"
                                : "#1746A2",
                          }}
                          onClick={handleButtonClick2}>
                          Export to csv
                        </MenuItem>
                      </Menu>
                    </div>
                  </SortFilterContainer>
                )}
              </TopContainer>
            </>
          )}

          {allDepartment?.length === 0 ? (
            <EmptyWrapper
              // style={{ overflowX: "auto" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: " 15px ",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "600px",
              }}>
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={emp}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  sx={{ color: " #212427" }}
                  color="text.secondary"
                  gutterBottom>
                  No department in your account, please add department.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => navigate("/add-department")}
                  sx={{
                    borderRadius: "35px",
                    textTransform: "none",
                    bgcolor: "#1746A2",
                  }}
                  disabled={userAccess?.adddepart !== "Yes"}
                  variant="contained">
                  Add Department
                </Button>
              </CardActions>
            </EmptyWrapper>
          ) : data?.length === 0 ? (
            <>
              {" "}
              <EmptyWrapper
                // style={{ overflowX: "auto" }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: " 15px ",
                  justifyContent: "center",
                  alignItems: "center",
                  // : "400px",
                }}>
                <CardContent>
                  <Typography
                    sx={{ color: " #1746A2", fontWeight: 700 }}
                    color="text.secondary"
                    gutterBottom>
                    Sorry, no matching department found on this page.
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  sx={{ width: "40%", height: "40%" }}
                  image={Nodata}
                  alt="green iguana"
                />

                <Box sx={{ display: "flex", gap: "40px", padding: "20px" }}>
                  <Button
                    onClick={() => navigate("/home")}
                    sx={{
                      borderRadius: "35px",
                      textTransform: "none",
                      bgcolor: "#1746A2",
                    }}
                    variant="contained">
                    Go to Home page
                  </Button>

                  <Button
                    onClick={() => navigate("/add-department")}
                    sx={{
                      borderRadius: "35px",
                      textTransform: "none",
                      bgcolor: "#1746A2",
                    }}
                    disabled={userAccess?.adddepart !== "Yes"}
                    variant="contained">
                    Add department
                  </Button>
                </Box>
              </EmptyWrapper>
            </>
          ) : (
            <>
              <BoxWrapper style={{ overflowX: "auto" }}>
                <Box sx={{ overflow: "auto" }}>
                  <TableContainer
                    sx={{
                      width: "100%",
                      maxHeight: 540,
                    }}>
                    <Table
                      stickyHeader
                      sx={{
                        borderRadius: " 15px",
                        bgcolor: "white",
                        // border: "6px solid green",
                        whiteSpace: "nowrap",
                      }}>
                      <TableHead
                        sx={{
                          borderRadius: " 15px 15px 0px 0px",
                        }}>
                        <TableRow
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            bgcolor: "rgba(40, 98, 212, 0.1)",
                          }}>
                          <TableHeading
                            sx={{
                              borderRadius: "15px 0px 0px 0px",
                            }}>
                            <TableSortLabel
                              style={{ color: "#1746A2" }}
                              active={true}
                              direction={sortDirection.name}
                              onClick={() => handleSortTable("name")}>
                              Department
                            </TableSortLabel>
                          </TableHeading>
                          <TableHeading>
                            {" "}
                            <TableSortLabel
                              style={{ color: "#1746A2" }}
                              active={true}
                              direction={sortDirection.assets}
                              onClick={() => handleSortTable("assets")}>
                              Total Assets
                            </TableSortLabel>
                          </TableHeading>
                          <TableHeading>
                            <TableSortLabel
                              style={{ color: "#1746A2" }}
                              active={true}
                              direction={sortDirection.date}
                              onClick={() => handleSortTable("date")}>
                              Created Date
                            </TableSortLabel>
                          </TableHeading>
                          <TableHeading>Incharge</TableHeading>

                          <TableHeading
                            sx={{
                              borderRadius: "0px 15px 0px 0px",
                            }}></TableHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, index) => (
                            <TableRow
                              key={item.departid}
                              onClick={() => handleNameClick(item)}
                              sx={{
                                borderRadius: " 15px 15px 0px 0px",
                                "&:hover": {
                                  backgroundColor: "#E8ECF6",
                                },
                                textDecoration: "none",
                              }}>
                              <TableRows
                                component={Link}
                                sx={{ textDecoration: "none" }}
                                to={`/department-asset-details/${item?.departid}`}>
                                {item.departname}
                              </TableRows>
                              <TableRows
                                sx={{ textDecoration: "none" }}
                                component={Link}
                                to={`/department-asset-details/${item?.departid}`}>
                                {item.totalassets}
                              </TableRows>
                              <TableRows
                                sx={{ textDecoration: "none" }}
                                component={Link}
                                to={`/department-asset-details/${item?.departid}`}>
                                {item.createdate}
                              </TableRows>
                              <TableRows
                                sx={{ textDecoration: "none" }}
                                component={Link}
                                to={`/department-asset-details/${item?.departid}`}>
                                {item.departincharge}
                              </TableRows>

                              <TableRows>
                                <Box>
                                  <IconButton
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={(e) => handleClick(e, item)}>
                                    <MoreVertIcon color="black" />
                                  </IconButton>
                                </Box>
                              </TableRows>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    filteredProducts={data}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </BoxWrapper>
            </>
          )}
          <Menu
            elevation={0}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <MenuItem
              disabled={userAccess?.editdepart !== "Yes"}
              onClick={handleEdit}
              value="edit">
              <ModeOutlinedIcon sx={{ marginRight: "7%" }} />
              Edit
            </MenuItem>
            <MenuItem
              disabled={userAccess?.deldepart !== "Yes"}
              value="delete"
              // onClick={"handleDelete"}
              onClick={handleDeleteById}>
              <DeleteOutlineOutlinedIcon
                sx={{
                  marginRight: "7%",
                }}
              />
              Delete
            </MenuItem>
            <Link
              style={{
                textDecoration: "none",
                color: "black",
              }}
              to={`/department-asset-details/${getId?.departid}`}>
              <MenuItem value="view">
                <RemoveRedEyeOutlinedIcon
                  sx={{
                    marginRight: "7%",
                  }}
                />
                View
              </MenuItem>
            </Link>
          </Menu>
          <DeleteModal
            open={openDelete}
            onClose={handleCloseDelete}
            onDelete={handleDeleteSingle}
            text={text}
            id={getId}
          />

          <BulkDeleteDepartment
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            onDelete={handleDelete}
            text={text}
            id={getId}
          />

          <DialogBox
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        </Wrapper>
      )}
    </>
  );
};

export default SavedDepart;
