import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Routes } from "react-router-dom";

// import AddUser from "../nbmComponents/AddUser";
import EditPage from "../nbmComponents/EditPage";
import IncidentList from "../nbmPages/IncidentList";
import IncidentDetails from "../nbmPages/IncidentDetails";
import Depreciation from "../nbmPages/Depreciation";
import AssetDetails from "../nbmPages/AssetDetails";
import AddAsset from "../nbmPages/AddAsset";
import Main from "../nbmPages/Main";
import Callibration from "../nbmPages/Callibration";
import AllAsset from "../nbmPages/AllAsset";
import ServiceMedpick from "../nbmPages/ServiceMedpick";
import ServiceOther from "../nbmPages/ServiceOther";
import OpenServices from "../nbmPages/OpenServices";
import ServiceList from "../nbmPages/ServiceList";
import ServiceDetailPage from "../nbmPages/ServiceDetailPage";
import ClearServicePage from "../nbmPages/ClearServicePage";
import EditAssetPage from "../nbmPages/EditAssetPage";

import Profile from "../nbmPages/Profile";

import Report from "../nbmPages/Report";
import PmCheckList from "../nbmPages/PmCheckList";

import ClearIncident from "../nbmPages/ClearIncident";
import RecordIncident from "../nbmPages/RecordIncident";
import CalibrateNotRequired from "../nbmPages/CalibrateNotRequired";
import NotCalibrated from "../nbmPages/NotCalibrated";
import DepartmentAssetDetails from "../nbmPages/DepartmentAssetDetails";
import CloseService from "../nbmPages/CloseService";
import ClosedIncident from "../nbmPages/ClosedIncident";
import OpenIncident from "../nbmPages/OpenIncident";
import WarrantyStatus from "../nbmPages/WarrantyStatus";
import AddDep from "../nbmPages/AddDepartmentPage";
import { logOut } from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";
import ReloginModal from "../nbmComponents/ReloginModal";
import Department from "../nbmPages/DepartmentList";
import { allUserList, retrieveAllAssets } from "../redux/nbmData/action";
import { BulkUploadAsset } from "../nbmPages/BulkUploadAsset";
import { BulkUploadDepartment } from "../nbmPages/BulkUploadDepartment";
import AmcCmc from "../nbmPages/AmcCmc";
import Amc from "../nbmPages/Amc";
import Cmc from "../nbmPages/Cmc";
import TaskPage from "../nbmPages/TaskPage";
import VendorRelations from "../nbmPages/VendorRelations";
import Calendar from "../nbmPages/Calendar";
import AssetHistory from "../nbmPages/AssetHistory";
import AllAssetHistory from "../nbmPages/AllAssetHistory";
import CheckinOut from "../nbmPages/CheckinOut";
import CheckinOutDetailPage from "../nbmPages/CheckinOutDetailPage";
import NotificationTab from "../nbmPages/NotificationTab";
import Scan from "../nbmPages/Scan";

import AccessCheckModal from "../components/AccessCheckModal";
import DepartmentAssetDelete from "../nbmPages/DepartmentAssetDelete";
import CheckInPage from "../nbmPages/CheckInPage";
import AddCheckOut from "../nbmPages/AddCheckOut";
import ServiceRequest from "../nbmPages/ServiceRequest";
import RequestPage from "../nbmPages/RequestPage";
import UserListPage from "../nbmPages/UserListPage";

import UserManualList from "../pages/UserManualList";
import PmChoose from "../nbmPages/PmChoose";
import DashboardPage from "../nbmPages/DashboardPage";
import ContactPage from "../pages/ContactPage";
import ProfileDetail from "../nbmPages/ProfileDetail";
import DepartmentList from "../nbmPages/DepartmentList";
import IncidentDetailPage from "../nbmPages/IncidentDetailPage";
import ServiceDetail from "../nbmPages/ServiceDetail";
import VendorList from "../nbmPages/Vendor";
import ServiceCompanyList from "../nbmPages/ServiceCompanyList";
import AddUserPage from "../nbmPages/AddUserPage";
import EditUserPage from "../nbmPages/EditUserPage";
import AssetsMobile from "../nbmPages/AssetsMobile";
import RequestMobile from "../nbmPages/RequestMobile";
import UserMobile from "../nbmPages/UserMobile";
import DashboardMobile from "../nbmPages/DashboardMobile";
import IncidentMobile from "../nbmPages/IncidentMobile";
import MReport from "./../nbmPages/MReport";
import SellOnlinePage from "./../nbmPages/SellOnlinePage";
import NotWorkingAssetPage from "../nbmPages/NotWorkingAssetPage";
import Discarded from "../nbmPages/Discarded";
import EditDepartment from "../nbmPages/EditDepartment";
import DeletedAssetList from "../nbmPages/DeletedAssetList";
import IncidentDeletedList from "../nbmPages/IncidentDeletedList";
import DepartmentDeleteList from "../nbmPages/DepartmentDeleteList";

function NbmPrivateRoutes() {
  const [dataLendth, setDataLength] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const invalidToken = useSelector((store) => store?.data?.invalidToken);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let checkAccess = userAccess === null ? false : userAccess.role === "NA";

  const authenticationError = useSelector(
    (store) => store?.data?.authenticationError
  );
  const allAssets = useSelector((store) => store.data.allAssets);
  const userData = useSelector((store) => store.data.usersList);

  useEffect(() => {
    if (authenticationError === "AUTHENTICATION_ERROR" || !userBranch) {
      let data = {
        userid: userIds.userid,
      };
      dispatch(logOut(data, token));
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  }, [authenticationError, userBranch]);

  React.useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);
  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(allUserList(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(userData)?.length > 0) {
      setDataLength(userData?.users);
    }
  }, [userData]);

  return (
    <>
      {invalidToken === "INVALID_TOKEN" ? (
        <ReloginModal status={invalidToken} />
      ) : (
        authenticationError !== "AUTHENTICATION_ERROR" &&
        (checkAccess ? (
          <AccessCheckModal status={checkAccess} text="Non-biomedical" />
        ) : (
          <Routes>
            <Route path="*" element={<DashboardPage />} />
            <Route path="/" element={<DashboardPage />} />
            <Route path="/home" element={<DashboardPage />} />
            <Route path="/profile" element={<ProfileDetail />} />
            {((userAccess && userAccess.role === "Super_Admin") ||
              (userAccess && userAccess.role === "Owner")) && (
              <Route path="/edit-profile" element={<Profile />} />
            )}
            <Route path="/report" element={<Report />} />
            <Route path="/user" element={<UserListPage />} />
            <Route path="/user/:id" element={<EditUserPage />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard" ||
              (userIds.subs_plan === "Basic" && dataLendth?.length < 1)) && (
              <Route path="/adduser" element={<AddUserPage />} />
            )}
            <Route path="/incident-list" element={<IncidentList />} />
            <Route path="/incident-list/:id" element={<IncidentDetailPage />} />
            <Route path="/add-asset" element={<AddAsset />} />
            <Route path="/asset-detail/:id" element={<AssetDetails />} />
            <Route path="/department-list" element={<DepartmentList />} />
            <Route path="/department" element={<Main />} />
            <Route path="/calibration" element={<Callibration />} />
            <Route path="/not-calibrated" element={<NotCalibrated />} />
            <Route
              path="/calibration-not-required"
              element={<CalibrateNotRequired />}
            />
            <Route path="/add-dep" element={<AddDep />} />
            <Route path="/add-department" element={<Department />} />
            <Route path="/editDepart/:id" element={<EditDepartment />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard" ||
              (userIds.subs_plan === "Basic" && allAssets?.length <= 49)) && (
              <Route path="/add-asset" element={<AddAsset />} />
            )}
            <Route path="/all-assets" element={<AllAsset />} />
            <Route path="/service-medpick/:id" element={<ServiceMedpick />} />
            <Route path="/service-others/:id" element={<ServiceOther />} />
            <Route path="/closed-service" element={<CloseService />} />
            <Route path="/closed-incident" element={<ClosedIncident />} />
            <Route path="/open-incident" element={<OpenIncident />} />
            <Route path="/depreciation" element={<Depreciation />} />
            <Route path="/open-service" element={<OpenServices />} />
            <Route path="/service-list" element={<ServiceList />} />
            <Route
              path="/service-details/:id"
              element={<ServiceDetailPage />}
            />
            <Route path="/service-list/:id" element={<ServiceDetail />} />
            <Route
              path="/clear-service/:id/:id2"
              element={<ClearServicePage />}
            />
            <Route path="/edit-asset/:id" element={<EditAssetPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/pm-checklist" element={<PmChoose />} />
            <Route path="/pm-checklist/:id" element={<PmCheckList />} />
            <Route path="/clear-incident/:id" element={<ClearIncident />} />
            <Route path="/record-incident/:id" element={<RecordIncident />} />
            <Route path="/m-report" element={<MReport />} />
            <Route
              path="/department-asset-details/:id"
              element={<DepartmentAssetDetails />}
            />
            <Route path="/warranty-status/:id" element={<WarrantyStatus />} />
            <Route path="/warranty-status/:id" element={<WarrantyStatus />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard") && (
              <Route path="/bulk-upload-asset" element={<BulkUploadAsset />} />
            )}
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard") && (
              <Route
                path="/bulk-upload-department"
                element={<BulkUploadDepartment />}
              />
            )}
            <Route path="/amc-cmc" element={<AmcCmc />} />
            <Route path="/task" element={<TaskPage />} />
            <Route path="/amc" element={<Amc />} />
            <Route path="/cmc" element={<Cmc />} />
            {/* <Route path="/vendor-relations/:id" element={<VendorRelations />} /> */}
            <Route path="/vendor" element={<VendorList />} />
            <Route path="/service-company" element={<ServiceCompanyList />} />
            <Route path="/calendar" element={<Calendar />} />
            {userAccess && userAccess.role !== "Guest" && (
              <Route path="/asset-history" element={<AssetHistory />} />
            )}
            {userAccess &&
              (userAccess.role === "Owner" ||
                userAccess.role === "Super_Admin") && (
                <Route
                  path="/asset-history-list"
                  element={<AllAssetHistory />}
                />
              )}

            <Route path="/checkin-checkout/:id" element={<CheckinOut />} />
            <Route
              path="/checkin-checkout-details/:id"
              element={<CheckinOutDetailPage />}
            />
            <Route path="/notification" element={<NotificationTab />} />
            <Route path="/scan" element={<Scan />} />

            <Route
              path="/department-asset-delete-details/:id"
              element={<DepartmentAssetDelete />}
            />

            <Route path="/check-in" element={<CheckInPage />} />

            <Route path="/check-out" element={<AddCheckOut />} />
            <Route path="/service-request/:id" element={<ServiceRequest />} />
            <Route path="/request" element={<RequestPage />} />
            <Route path="/user-manual" element={<UserManualList />} />
            <Route path="/deleted-incident" element={<IncidentDeletedList/>}/>
            <Route path="/asset-mobile" element={<AssetsMobile />} />
            <Route path="/sell-online" element={<SellOnlinePage />} />
            <Route path="/request-mobile" element={<RequestMobile />} />
            <Route path="/user-mobile" element={<UserMobile />} />
            <Route path="/dashboard-mobile" element={<DashboardMobile />} />
            <Route path="/incident-mobile" element={<IncidentMobile />} />
            <Route
              path="/not-working-asset"
              element={<NotWorkingAssetPage />}
            />
            <Route
              path="/DepartmentDeletedList"
              element={<DepartmentDeleteList />}
            />
            <Route path="/Discarded" element={<Discarded />} />
            <Route path="/deleted-assetlist" element={<DeletedAssetList />} />
          </Routes>
        ))
      )}
    </>
  );
}

export default NbmPrivateRoutes;
