import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Stack, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/data/action";
import authImage from "../images/authImage.png";
import FooterMain from "./FooterMain";
import NavbarMain from "./NavbarMain";
import Nbm from "../images/Nbm.jpg";
import Bm from "../images/BMimg.png";
import NotificationModal from "./NotificationModal";

const ChooseBmOrNbm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const token = localStorage.getItem("userToken");

  const handleBiomedical = () => {
    sessionStorage.setItem("SbmOrNbm", "BM");
    localStorage.setItem("bmOrNbm", "BM");
    let branch = {
      branch: userIds?.bm_branches[0],
      id: userIds.bm_branchids[0],
    };
    localStorage.setItem("userBranch", JSON.stringify(branch));
    sessionStorage.setItem("userBranch", JSON.stringify(branch));
    navigate("/home");
    window.location.reload();
  };

  const handleNoneBiomedical = () => {
    sessionStorage.setItem("SbmOrNbm", "NBM");
    localStorage.setItem("bmOrNbm", "NBM");
    let branch = {
      branch: userIds?.nbm_branches[0],
      id: userIds?.nbm_branchids[0],
    };
    localStorage.setItem("userBranch", JSON.stringify(branch));
    sessionStorage.setItem("userBranch", JSON.stringify(branch));
    navigate("/home");
    window.location.reload();
  };

  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.getItem("userToken");
    navigate("/signin");
    localStorage.setItem("logoutEvent", Date.now().toString());
    window.location.reload();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "space-between",
          bgcolor: "white",
        }}
      >
        <NavbarMain />
        <NotificationModal/>

        <Stack
          sx={{
            flex: 1,

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              p: { sm: 0, lg: 5 },
              height: "calc(100vh - 150px)",
              justifyContent: "center",
              width: { sm: "100%", lg: "60%" },
              flexDirection: "row",
              gap: "30px",
            }}
          >
            <Stack
              sx={{
                width: "40%",
                display: { xs: "none", sm: "none", lg: "flex" },
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  backgroundColor: "#1B2535",
                }}
              >
                <img
                  src={authImage}
                  alt="authImage"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            </Stack>

            <Stack
              sx={{
                width: { xs: "90%", sm: "90%", md: "100%", lg: "60%" },

                boxShadow: "0px 0px 10px 0px rgba(219, 231, 229, 1)",
              }}
            >
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  padding: "20px ",
                  alignItems: "center",
                }}
              >
                <form
                  style={{
                    width: "80%",

                    height: "100%",
                  }}
                >
                  <Stack
                    sx={{
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",

                      height: "100%",
                      gap: "20px",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      Equipment category
                    </Typography>
                    <Typography>
                      Welcome, Select to continue to respective dashboard.
                    </Typography>
                    <Stack sx={{ gap: "20px", flexDirection: "row" }}>
                      <Stack gap={"10px"}>
                        <Stack
                          sx={{
                            height: "150px",

                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={Bm}
                            alt=""
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </Stack>
                        <Button
                          variant="outlined"
                          onClick={handleBiomedical}
                          disabled={userIds?.bm_branches?.length === 0}
                          sx={{
                            width: "150px",
                            borderRadius: "22px",
                            border: "1.5px solid",
                            fontWeight: 700,
                            background:
                              userIds?.bm_branches?.length === 0
                                ? "grey"
                                : "white",
                            color:
                              userIds?.bm_branches?.length === 0
                                ? "white"
                                : "#4690FF",
                          }}
                        >
                          Bio Medical
                        </Button>
                      </Stack>

                      <Stack gap={"10px"}>
                        <Stack
                          sx={{
                            height: "150px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={Nbm}
                            alt=""
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </Stack>

                        <Button
                          onClick={handleNoneBiomedical}
                          disabled={userIds?.nbm_branches?.length === 0}
                          variant="outlined"
                          sx={{
                            width: "150px",
                            borderRadius: "22px",
                            border: "1.5px solid",
                            fontWeight: 700,
                            background:
                              userIds?.nbm_branches?.length === 0
                                ? "grey"
                                : "white",
                            color:
                              userIds?.nbm_branches?.length === 0
                                ? "white"
                                : "#4690FF",
                          }}
                        >
                          Non Bio Medical
                        </Button>
                      </Stack>
                    </Stack>

                    <Stack
                      sx={{
                        width: "100%",
                      }}
                    ></Stack>
                  </Stack>
                </form>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <FooterMain />
      </Box>
    </>
  );
};

export default ChooseBmOrNbm;
