import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import { Pagination } from "@mui/material";
import { useRef } from "react";

import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  Modal,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  DownloadPDF,
  getPmAsset,
  retrieveAsset,
  retrieveServiceAssets,
  serviceList,
  profile,
} from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "300px",
}));
const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const ServiceListPage = () => {
  const serviceData = useSelector((store) => store?.data?.serviceList);
  const allAssets = useSelector((store) => store.data.retrieveAsset);
  const [isAssetSelected, setIsAssetSelected] = useState(false);

  const serviceAssets = useSelector(
    (store) => store?.data?.retrieveServiceAsset
  );
  // console.log(serviceAssets)

  let profileData = useSelector((store) => store.data.profile);

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);

  const [getAllData, setGetAllData] = useState([]);
  const [getId, setGetId] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      userrole: userAccess?.role,
    };

    dispatch(profile(data));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };

    dispatch(serviceList(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (serviceData && serviceData?.length > 0) {
      setData(serviceData);
    }
  }, [serviceData]);

  const handleAssetName = (event, value) => {
    if (value) {
      const selectedAsset = getAllData?.assets?.find((asset) => {
        const fullName = `${asset.name} - ${asset.assetcode}`;
        return fullName === value;
      });

      setGetId(selectedAsset?.assetid);
      setIsAssetSelected(!!selectedAsset);
      // console.log("selectedAsset", selectedAsset);
    } else {
      setIsAssetSelected(false);
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      userrole: userAccess?.role,
    };

    dispatch(getPmAsset(data, token));
  }, [dispatch]);
  useEffect(() => {
    if (serviceAssets && serviceAssets?.length !== 0) {
      setGetAllData(serviceAssets);
    }
  }, [serviceAssets]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };

    dispatch(retrieveAsset(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: "all",
      userrole: userAccess?.role,
    };

    dispatch(retrieveServiceAssets(data, token));
  }, [dispatch]);

  const handleNavigate = (requested) => {
    navigate(`/service-request/${getId}?requested=${requested}`);
  };

  // const handleDownload = (pdf, title) => {
  //   dispatch(DownloadPDF(pdf, title));
  // };

  // const handleDownloadPDF = (item) => {
  //   const doc = new jsPDF();

  //   const labelX = 20;
  //   const labelY = 65;
  //   const dataX = 73;
  //   let yPosition = 30;
  //   const pageHeight = doc.internal.pageSize.height;

  //   const hasEnoughSpace = (heightNeeded) => {
  //     return yPosition + heightNeeded < pageHeight;
  //   };

  //   // Add logo on top-right
  //   const addHeader = () => {
  //     if (profileData.logo_url) {
  //       doc.addImage(
  //         profileData.logo_url,
  //         "JPEG",
  //         doc.internal.pageSize.width - 200,
  //         10,
  //         50,
  //         25
  //       );
  //     }
  //     doc.setFontSize(16);
  //     doc.setTextColor("#004F95");
  //     doc.setFont("Helvetica", "bold");
  //     doc.text(userIds.hospname || "Default Hospital", labelX + 72, 20);

  //     doc.setFont("Helvetica", "bold");
  //     doc.text(profileData.city + ", " + (profileData.state || "Main Branch"), labelX + 64, 28);
  //   };

  //   addHeader();

  // yPosition = 40;

  //   const lineStartX = 10; // Move the line closer to the left
  //   const lineEndX = doc.internal.pageSize.width - 10; // Extend the line closer to the right edge
  //   doc.setDrawColor(0, 0, 0); // Set line color to black
  //   doc.setLineWidth(0.5); // Set line width
  //   doc.line(lineStartX, yPosition, lineEndX, yPosition); // Draw the line
  //   yPosition += 15;

  //   // Title: "Incident Report" should be bold
  //   doc.setFontSize(18);
  //   doc.setTextColor("black");
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     "Service Report",
  //     doc.internal.pageSize.width / 2,
  //     yPosition,
  //     null,
  //     null,
  //     "center"
  //   );

  //   yPosition += 18;

  //   doc.setFontSize(12);
  //   doc.setFont("helvetica", "normal");

  //   const addText = (label, value, heightNeeded) => {
  //     if (!hasEnoughSpace(heightNeeded)) {
  //       doc.addPage();
  //       yPosition = 20;
  //     }

  //     doc.setTextColor(0, 0, 0);
  //     doc.text(label, labelX, yPosition);
  //     doc.setTextColor(0, 0, 0); // Set colon color to black
  //     doc.text(":", labelY, yPosition);
  //     doc.text(value || "N/A", dataX, yPosition);
  //     yPosition += heightNeeded;
  //   };

  //   const addUnderlinedText = (text, yPosition, isBold = false) => {
  //     doc.setFont("Helvetica", isBold ? "normal" : "normal"); // Set to bold or normal font
  //     doc.setTextColor(0, 0, 0); // Set text color to black
  //     doc.text(text, labelX, yPosition);
  //     const textWidth = doc.getTextWidth(text); // Get the width of the text
  //     doc.line(labelX, yPosition + 1, labelX + textWidth, yPosition + 1); // Draw a line under the text
  //     return yPosition + 10; // Return the new yPosition after adding the line
  //   };

  //   // Add "Product details" section with underline
  //   yPosition = addUnderlinedText("Product details", yPosition, true);
  //   addText("Asset Name", item.assetname, 10);
  //   addText("Asset Code", item.assetcode, 10);
  //   addText("Department", item.dept, 20);

  //   // Add "Incident Details" section with underline
  //   yPosition = addUnderlinedText("Incident Details", yPosition, true);
  //   addText("Issue Date", item.incidstartdate, 10);
  //   addText("Clear Date", item.incidcleardate, 10);
  //   addText("Total Downtime", item.downtime, 20);

  //   // Add "Incident Clear Details" section with underline
  //   yPosition = addUnderlinedText("Incident Clear Details", yPosition, true);
  //   addText("Servicer Name", item.person_name, 10);
  //   addText("Contact No", item.person_phone, 10);
  //   addText("Email", item.person_email, 10);
  //   addText("Problem Identified", item?.incdt_issue, 10);

  //   if (Array.isArray(item?.pdfurl) && item.pdfurl.length > 0) {
  //     const imageWidth = 50;
  //     const imageHeight = 50;
  //     const margin = 10;
  //     let rowXPosition = 20;
  //     let rowYPosition = yPosition;

  //     item.pdfurl.forEach((image, index) => {
  //       if (rowXPosition + imageWidth + margin > doc.internal.pageSize.width) {
  //         rowXPosition = 20;
  //         rowYPosition += imageHeight + margin;
  //       }

  //       doc.addImage(
  //         image,
  //         "JPEG",
  //         rowXPosition,
  //         rowYPosition,
  //         imageWidth,
  //         imageHeight
  //       );
  //       rowXPosition += imageWidth + margin;
  //     });

  //     yPosition += imageHeight + margin;
  //   }

  //   addText("Remark", item.feedback, 10);
  //   doc.save("Closed_Incident_Details.pdf");
  // };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };
  return (
    <Box
      // overflow={"auto"}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap="10px"
      height="85vh"
      padding="16px"
      marginTop="-20px"
    >
      <Box display="flex" justifyContent="end">
        <Button
          onClick={handleOpenModal}
          variant="contained"
          sx={{
            borderRadius: "22px",
            background: "rgba(70, 144, 255, 1)",
            marginRight: "20px",
            width: "150px",
          }}
        >
          Request <AddIcon />
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Service.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}
            >{` ${data?.length}`}</Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="dept"
              Filter2="serv_status"
              FilterName1="Department"
              FilterName2="Status"
              sortDate="servdate"
              dueDate=""
              sortPrice=""
              name="assetname"
              csvName="All Assets"
            />
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
            ref={tableRef}
          >
            {currentData.length > 0 ? (
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    style={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 237, 237, 1)",
                      },
                    }}
                  >
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>

                    <TableHeading>Department</TableHeading>

                    <TableHeading>Date</TableHeading>

                    <TableHeading
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      Status
                    </TableHeading>
                    {/* <TableHeading
                    sx={{
                      textAlign: "center",
                    }}>
                    Report
                  </TableHeading> */}
                  </TableRow>
                </TableHead>

                <TableBody style={{ overflowY: "scroll" }}>
                  {currentData.map((item, index) => (
                    <TableRow
                      key={item?.servid}
                      sx={{
                        "&:hover": {
                          bgcolor: "#EEF5FF",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.assetname}>
                          {item.assetname}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.dept}>{item.dept}</Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        {item.servdate === item?.servtime ? (
                          <>NA</>
                        ) : (
                          <>
                            <>{item?.servdate}</>@<>{item?.servtime}</>
                          </>
                        )}
                      </TableCells>

                      <TableCells
                        sx={{
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center",
                          display: "ruby-text",
                          color: "rgba(70, 144, 255, 1)",
                        }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        {item?.serv_status === "Open" ? (
                          <>
                            <Box
                              disabled={userAccess?.role === "Guest"}
                              size="small"
                              sx={{
                                borderRadius: " 0px 8px 0px 8px",
                                textTransform: "none",
                                width: "90px",
                                height: "25px",
                                bgcolor: "#74ACFF",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              variant="contained"
                            >
                              Open
                            </Box>
                          </>
                        ) : (
                          <>Cleared</>
                        )}
                      </TableCells>

                      {/* <TableCells sx={{textOverflow:"clip",whiteSpace:'normal',textAlign: "center",}}
                      // sx={{
                        
                      // }}
                      >
                      {" "}
                      {item?.serv_status === "Open" ? (
                        <></>
                      ) : (
                        <IconButton
                          // onClick={() =>
                          //   handleDownload(
                          //     item?.pdfurl,
                          //     `Service_report_${item?.assetname}_${item?.servdate}`
                          //   )
                          // }
                          onClick={() => handleDownloadPDF(item)}
                          >
                          <DownloadIcon sx={{ color: "black" }} />
                        </IconButton>
                      )}
                    </TableCells> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                No service data found.
              </Typography>
            )}
          </TableContainer>
          <Stack
            sx={{
              padding: "10px",
              bgcolor: "white",
              gap: "20px",
            }}
          >
            <Divider />
            <Stack
              sx={{
                bgcolor: "white",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate("/vendor")}
                sx={{
                  borderRadius: "22px",
                  background: "rgba(70, 144, 255, 1)",
                  marginRight: "20px",
                  width: "150px",
                }}
              >
                Vendors
              </Button>

              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
      <div style={{}}>
        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <ModalBox sx={{ bgcolor: "background.paper" }}>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}
            >
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",

                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Label variant="h6">Request services</Label>

              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>

              <Stack gap={"10px"}>
                <Label textAlign={"start"}>Choose Equipment</Label>
                <Stack flexDirection={"row"} gap={"10px"} textAlign={"start"}>
                  <Autocomplete
                    id="free-solo-demo"
                    onChange={handleAssetName}
                    placeholder="Asset Name"
                    options={getAllData?.assets?.map(
                      (option) => `${option.name} - ${option.assetcode}`
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Asset Name"
                        sx={{
                          width: "300px",
                          background: "rgba(244, 246, 246, 1)",
                          "& fieldset": { border: "none" },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack gap={"10px"} sx={{ width: "90%" }}>
                <Label textAlign={"start"}>Choose Service</Label>
                <Stack
                  flexDirection={"row"}
                  gap={"10px"}
                  textAlign={"start"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Vajra")}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                    disabled={!isAssetSelected}
                  >
                    Vajra
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Others")}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                    disabled={!isAssetSelected}
                  >
                    Others
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </ModalBox>
        </Modal>
      </div>
    </Box>
  );
};

export default ServiceListPage;
