import React, { useRef } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { getVendor } from "../redux/data/action";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";

import AddVendor from "./AddVendor";
const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const VendorList = () => {
  const navigate = useNavigate();
  const vendorData = useSelector((store) => store.data.getVendor);
  const userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const status = useSelector((store) => store.data.postVendor);

  const [filteredData, setFilteredData] = useState([]);

  const [showMovementBox, setShowMovementBox] = useState(false);

  const handleAddMovementClick = () => {
    setShowMovementBox(true);
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getVendor(data, token));
  }, [dispatch, status]);

  useEffect(() => {
    if (vendorData && vendorData?.length > 0) {
      setData(vendorData);
    }
  }, [vendorData]);

  const handleCloseDepart = () => {
    setShowMovementBox(false);
  };

  const buttonRef = useRef(null);
  const handleAddCircleIconClick = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [selectedOption, setSelectedOption] = useState("Vendor");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "Vendor") {
      navigate("/vendor");
    } else if (event.target.value === "Service Company") {
      navigate("/service-company");
    }
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <Stack>
      <Stack
        sx={{
          alignItems: "flex-end",

          justifyContent: "column",
          marginBottom: "10px",
        }}
      >
        <Button
          onClick={handleAddMovementClick}
          variant="contained"
          sx={{
            borderRadius: "22px",
            background: "rgba(70, 144, 255, 1)",
            marginRight: "20px",
          }}
        >
          Add Vendor <AddIcon />
        </Button>
      </Stack>
      <Stack
        justifyContent="space-between"
        flexDirection={"row"}
        alignItems={"center"}
        sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        marginLeft={"10px"}
      >
        <Label variant="h6">
          Vendor.
          <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
        </Label>
        <IconButton>
          <AddCircleIcon
            style={{ color: "#4690FF" }}
            onClick={handleAddCircleIconClick}
          />
        </IconButton>
      </Stack>
      <Stack
        flexDirection={"row"}
        height="50px"
        justifyContent={"space-between"}
        alignItems={"center"}
        padding="16px"
        gap={"10px"}
        sx={{
          bgcolor: "white",
          boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
          display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
          my: "10px",
        }}
      >
        {/* <Typography>{`Total : ${data?.length}`}</Typography> */}

        <FormControl sx={{ width: "100%" }}>
          <Select
            value={selectedOption}
            onChange={handleChange}
            sx={{
              "& fieldset": {
                border: "none",
              },
              textAlign: "left",
              color: "rgba(70, 144, 255, 1)",
            }}
            MenuProps={MenuProps}
          >
            <MenuItem value="Vendor" onClick={() => navigate("/vendor")}>
              Vendor
            </MenuItem>
            <MenuItem
              value="Service Company"
              onClick={() => navigate("/service-company")}
            >
              Service Company
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Box
        sx={{ display: { xs: "block", sm: "block", md: "flex", lg: "flex" } }}
        flexDirection="row"
        alignItems="stretch"
        gap="10px"
      >
        <Stack
          flexDirection={"row"}
          height="50px"
          justifyContent={"space-between"}
          alignItems={"center"}
          padding="16px"
          gap={"10px"}
          sx={{
            bgcolor: "white",
            boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            display: { xs: "none", sm: "none", md: "none", lg: "none" },
          }}
        >
          {/* <Typography>{`Total : ${data?.length}`}</Typography> */}
        </Stack>
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
          }}
          display="flex"
          flexDirection="column"
          gap="10px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px"
          >
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                display: { xs: "none", sm: "none", lg: "flex" },
              }}
            >
              {/* <Typography>{`Total : ${data?.length}`}</Typography> */}

              <FormControl sx={{ width: "100%" }}>
                <Select
                  value={selectedOption}
                  onChange={handleChange}
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    textAlign: "left",
                    color: "rgba(70, 144, 255, 1)",
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="Vendor" onClick={() => navigate("/vendor")}>
                    Vendor
                  </MenuItem>
                  <MenuItem
                    value="Service Company"
                    onClick={() => navigate("/service-company")}
                  >
                    Service Company
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Box flex="2">
              <SortSearchFilter
                data={data}
                setData={setData}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                heading={"My Orders"}
                Filter1="company"
                Filter2=""
                FilterName1="Company Names"
                sortPrice=""
                sortDate=""
                dueDate=""
                name="company"
                csvName="Assets vendor"
              />
            </Box>
          </Box>
          <Stack
            sx={{
              height: {
                xs: "auto",
                sm: "auto",
                md: "80vh",
                lg: "80vh",
              },
            }}
            style={{
              width: "auto",
              marginBottom: "15px",

              bgcolor: "white",
            }}
            overflow="auto"
            flexDirection={"column"}
          >
            <TableContainer
              component={Paper}
              style={{
                height: "calc(-200px + 90vh)",

                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}
            >
              {currentData.length > 0 ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableHeading> Company Name</TableHeading>
                      <TableHeading> Phone</TableHeading>
                      <TableHeading>Email</TableHeading>
                      <TableHeading> Asset Name</TableHeading>

                      <TableHeading> Asset Code</TableHeading>
                    </TableRow>
                  </TableHead>
                  {/* Table Body with Scrollbar */}
                  <TableBody style={{ overflowY: "scroll" }}>
                    {currentData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCells
                          sx={{
                            textOverflow: "clip",
                            whiteSpace: "normal",
                            textDecoration: "none",
                          }}
                        >
                          <Tooltip title={item.company}>{item.company}</Tooltip>
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        >
                          {item?.mobile}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        >
                          <Tooltip title={item.email}>{item.email}</Tooltip>
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        >
                          {Array.isArray(item.assetname) ? (
                            <Tooltip title={item.assetname.join(", ")}>
                              {item.assetname.join(", ")}
                            </Tooltip>
                          ) : (
                            item.assetname
                          )}
                        </TableCells>
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        >
                          {Array.isArray(item.assetcode) ? (
                            <Tooltip title={item.assetcode.join(", ")}>
                              {item.assetcode.join(", ")}
                            </Tooltip>
                          ) : (
                            item.assetcode // Ensure this is a string
                          )}
                        </TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70vh",
                  }}
                >
                  No vendor found.
                </Typography>
              )}
            </TableContainer>

            {!showMovementBox && (
              <Stack
                ref={buttonRef}
                sx={{
                  width: "auto",
                  padding: "20px",
                  bgcolor: "white",
                  gap: "20px",
                }}
              >
                <Divider />

                <Stack
                  sx={{
                    bgcolor: "white",
                    alignItems: "flex-end",

                    justifyContent: "column",
                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredData.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></Pagination>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        {showMovementBox && <AddVendor handleCloseDepart={handleCloseDepart} />}
      </Box>
    </Stack>
  );
};

export default VendorList;
