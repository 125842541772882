import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { logOut } from "../redux/data/action";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Sessionexpired = ({}) => {
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let refresh = JSON.parse(localStorage.getItem("userTokenId"));
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const userData =
      JSON.parse(sessionStorage.getItem("userData")) ||
      JSON.parse(localStorage.getItem("userData"));

    if (userData && userData.subs_expdate) {
      const today = new Date().toISOString().split("T")[0];
      const subscriptionExpiryDate = userData.subs_expdate;

      if (new Date(today) >= new Date(subscriptionExpiryDate)) {
        setShowModal(true);
      }
    }
  }, []);

  const handleCloseModal = () => {
    {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  };

  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="subscription-modal-title"
        aria-describedby="subscription-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="subscription-modal-title" variant="h6" component="h2">
            Session Expired
          </Typography>
          <Typography id="subscription-modal-description" sx={{ mt: 2 }}>
            Your subscription has expired. Please subscribe from the plan.
          </Typography>
          <Button variant="contained" sx={{ mt: 3 }} onClick={handleCloseModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Sessionexpired;
